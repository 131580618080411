import { createReducer } from '@reduxjs/toolkit';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { IAudienceComparisonState } from './typings';
import * as actions from './actions';
import { AudienceParameters } from '../../apollo/graphql-types';

const initialState: IAudienceComparisonState = {
  stats: {
    only:      [],
    exclusive: [],
    overlap:   [],
  },
  isLoading:          false,
  isAborting:         false,
  audiencesToCompare: {
    [AUDIENCE_ID.BENCHMARK]:         { benchmark: true },
    [AUDIENCE_ID.COMPARISON]:        { benchmark: false },
    [AUDIENCE_ID.SECOND_COMPARISON]: { benchmark: false },
  },
  appliedAudiencesToCompare: {
    [AUDIENCE_ID.BENCHMARK]:         { benchmark: true },
    [AUDIENCE_ID.COMPARISON]:        { benchmark: false },
    [AUDIENCE_ID.SECOND_COMPARISON]: { benchmark: false },
  },
  chosenAudience: null,
  hoveredId:      null,
};

export const audienceComparison = createReducer<IAudienceComparisonState>(
  initialState,
  ( builder ) =>
	  builder
      .addCase( actions.setPreBuiltAudienceIds, ( state, { payload } ) => {
        const [audienceId, preBuildIds] = payload;

        if ( !state.audiencesToCompare[audienceId] ) {
          state.audiencesToCompare[audienceId] = { benchmark: false };
        }

        state.audiencesToCompare[audienceId].preBuiltAudienceIds = preBuildIds;

        if ( state.audiencesToCompare[audienceId].audienceParameters !== undefined ) {
          delete state.audiencesToCompare[audienceId].audienceParameters;
        }
      } )
      .addCase( actions.setAudienceParameters, ( state, { payload } ) => {
        const [audienceId, parameters] = payload;

        if ( !state.audiencesToCompare[audienceId] ) {
          state.audiencesToCompare[audienceId] = { benchmark: false, audienceParameters: {} as AudienceParameters };
        }

        if ( parameters === undefined ) {
          state.audiencesToCompare[audienceId].audienceParameters = undefined;
        } else {
          state.audiencesToCompare[audienceId].audienceParameters
					= { ...state.audiencesToCompare[audienceId].audienceParameters, ...parameters } as AudienceParameters;
        }

        if ( state.audiencesToCompare[audienceId].preBuiltAudienceIds !== undefined ) {
          delete state.audiencesToCompare[audienceId].preBuiltAudienceIds;
        }
      } )
      .addCase( actions.setAudienceComparisonStats, ( state, { payload } ) => {
        state.stats = payload;
      } )
      .addCase( actions.setChosenAudience, ( state, { payload } ) => {
        state.chosenAudience = payload;
      } )
      .addCase( actions.setAudienceComparisonIsLoading, ( state, { payload } ) => {
        state.isLoading = payload;
      } )
      .addCase( actions.setAudienceComparisonIsAborting, ( state, { payload } ) => {
        state.isAborting = payload;
      } )
      .addCase( actions.resetAudienceComparison, ( state ) => {
        state = { ...initialState, isAborting: state.isAborting };

        return state;
      } )
      .addCase( actions.setHoveredId, ( state, { payload } ) => {
        state.hoveredId = payload;
      } )
      .addCase( actions.setAudiencesToCompare, ( state, { payload } ) => {
        state.audiencesToCompare = payload;
      } )
      .addCase( actions.setAppliedAudiencesToCompare, ( state, { payload } ) => {
        state.appliedAudiencesToCompare = payload;
      } )
);
