import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Flex, Row, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { flatten, sortBy } from 'lodash';

import { Filter } from './Filter/Filter';
import TitleWithCaption from '../../../custom/TitleWithCaption/TitleWithCaption';

import { AUDIENCE_TO_AUDIENCE_ID, options } from '../../Blade/collapse/const';

import { getDefaultExpandedAndCheckedKeys } from './Filter/utils';
import { AttributeContent } from './AttributeContent/AttributeContent';
import { useAudienceShortName } from '../AudienceShortName';
import { selectAudienceComparisonIsLoading } from '../../../store/audienceComparison/selectors';
import {
  SegmentCalculationAnnotation,
  useBenchmarkAttributesLazyQuery,
  useDivergentAttributesLazyQuery,
  useFilteringAttributesQuery,
} from '../../../apollo/graphql-types';
import { ErrorRetryCircle } from '../../Error/ErrorRetryCircle';

import './AttributesComparison.scss';

interface IAttributesComparisonProps {
  requestId: string;
  segmentId: string;
  attributeSegments: SegmentCalculationAnnotation[];
  initiateAttributesLoading: boolean;
}

export const AttributesComparison = ( {
  requestId,
  segmentId,
  attributeSegments,
  initiateAttributesLoading,
}: IAttributesComparisonProps ) => {
  const benchmarkQueryId = attributeSegments.find( ( item ) => item.benchmark )?.queryId || '';
  const audienceNames = useAudienceShortName();

  const comparisonSegments = sortBy( attributeSegments.filter( ( attr ) => !attr.benchmark ), 'position' );

  const [initialLoading, setInitialLoading] = useState( true );
  const [selectedComparison, setSelectedComparison] = useState( '' );
  const [selectedComparisonSegments, setSelectedComparisonSegments] = useState( comparisonSegments );

  const audienceComparisonIsLoading = useSelector( selectAudienceComparisonIsLoading );

  const { data: attributesData } = useFilteringAttributesQuery( options() );

  const defaultExpandedAndCheckedKeys = useMemo(
    () => getDefaultExpandedAndCheckedKeys( attributesData?.filteringAttributes ), [attributesData]
  );

  const [keysToLoad, setKeysToLoad] = useState<string[]>( [] );
  const [error, setError] = useState( false );
  const [loading, setLoading] = useState( true );

  const [loadBenchmarkData, { data: benchmarkData, loading: loadingBenchmarkData }] = useBenchmarkAttributesLazyQuery();
  const [loadComparisonData, { data: comparisonData, loading: loadingComparisonData }] = useDivergentAttributesLazyQuery();

  const loadDiagramsData = async ( attributes: string[] ) => {
    if( !!requestId && !!segmentId && !!benchmarkQueryId ) {
      try {
        setSelectedComparison( '' );
        setSelectedComparisonSegments( comparisonSegments );

        const benchmarkResponse = await loadBenchmarkData(
          {
            ...options(),
            variables: {
              benchmarkAttributes: {
                queryId: benchmarkQueryId,
                requestId,
                attributes,
                segmentId,
              },
            },
          }
        );

        const comparisonResponse = await loadComparisonData(
          {
            ...options(),
            variables: {
              requestedDivergentAttributes: {
                requestId,
                attributes,
                segments:  attributeSegments.map( ( attr ) => ( {
                  benchmark:   attr.benchmark,
                  queryId:     attr.queryId,
                  segmentId:   attr.segmentId,
                } ) ),
              },
            },
          }
        );

        if( !!benchmarkResponse.error || !!comparisonResponse.error ) {
          console.error( benchmarkResponse.error ?? comparisonResponse.error );// eslint-disable-line
          setError( true );
        } else {
          setError( false );
        }

        setLoading( false );
      } catch ( error ) {
        setError( true );
        console.error( error ); // eslint-disable-line
      }
    }
  };

  useEffect( () => {
    if ( !!flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ).length ) {
      setKeysToLoad( flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ) );
    }
  }, [defaultExpandedAndCheckedKeys] );

  useEffect( () => {
    if (
      !!requestId
      && !!segmentId
      && !!benchmarkQueryId
      && !!flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ).length
    ) {
      loadDiagramsData( flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ) );
    }
  }, [requestId, segmentId, benchmarkQueryId, defaultExpandedAndCheckedKeys] );

  useEffect( () => {
    setLoading( true );
    setError( false );
  }, [keysToLoad] );

  useEffect( () => {
    if ( loading ) {
      loadDiagramsData( keysToLoad );
    }
  }, [loading] );

  useEffect( () => {
    if ( initialLoading
      && !loadingBenchmarkData
      && !loadingComparisonData
      && !!benchmarkData?.benchmarkAttributes.length
      && !!comparisonData?.divergentAttributes.length
    ) {
      setInitialLoading( false );
    }

    if ( initiateAttributesLoading ) {
      setInitialLoading( true );
    }
  }, [loadingBenchmarkData, initiateAttributesLoading, benchmarkData, loadingComparisonData] );


  if ( Object.values( audienceNames ).includes( 'loading' ) || audienceComparisonIsLoading ) {
    return null;
  }

  return <>
    <Row>
      <Col span={24}>
        <Flex className='section-title' justify='space-between'>
          <TitleWithCaption headingLevel={2} heading='Benchmark Comparison' />

          {
            !audienceComparisonIsLoading && !initiateAttributesLoading
          && <div className={cn( 'comparison-selector', { hidden: !error && loading } )}>
            {
              comparisonSegments.length > 1 && <Select
                defaultValue={selectedComparison}
                className='select-comparison-to-show'
                style={{ width: 320 }}
                value={selectedComparison}
                onChange={( value ) => {
                  setSelectedComparison( value );

                  if ( value === '' ) {
                    setSelectedComparisonSegments( comparisonSegments );
                  } else {
                    setSelectedComparisonSegments( comparisonSegments.filter( ( segment ) => segment.segmentId === value ) );
                  }
                }}
                options={
                  [
                    {
                      value: '',
                      label: 'All',
                    },
                    {
                      value: comparisonSegments[0].segmentId,
                      label: audienceNames[AUDIENCE_TO_AUDIENCE_ID[comparisonSegments[0].position[0]]],
                    },
                    {
                      value: comparisonSegments[1].segmentId,
                      label: audienceNames[AUDIENCE_TO_AUDIENCE_ID[comparisonSegments[1].position[0]]],
                    },
                  ]
                }
              />
            }

            <Filter setKeysToLoad={setKeysToLoad} />
          </div>
          }
        </Flex>
      </Col>
    </Row>

    {
      error
        ? (
          <ErrorRetryCircle
            title='Error'
            description={'Something went wrong. Couldn\'t load the data. Please try again.'}
            onClick={() => {
              setLoading( true );
              setError( false );
            }}
          />
        )
        : loading
          ? (
            <Flex vertical align='center' justify='center' className={cn( 'attributes-comparison-spinner', { initial: initialLoading } )}>
              <Spin />
              <span>The request might take a little longer...</span>
            </Flex>
          )
          : (
            <div className='attributes-comparison-content'>
              { benchmarkData?.benchmarkAttributes.map( ( category ) => {
                const comparisonCategoryData = comparisonData?.divergentAttributes
                  .find( ( dataItem ) => dataItem.categoryName === category.name );

                return <React.Fragment key={category.name}>
                  <Row>
                    <Col span={24} className='category-title'>
                      <TitleWithCaption headingLevel={4} heading={category.name} />
                    </Col>
                  </Row>
                  {category.attributes?.map( ( attribute ) => <AttributeContent
                    comparisonAttribute={comparisonCategoryData?.attributes?.find( ( group ) => group.attributeName === attribute.name )}
                    comparisonSegments={selectedComparisonSegments}
                    benchmarkAttribute={attribute}
                    key={attribute.name}
                  />
                  )}
                  {category.attributeGroups?.map( ( attribute ) => <AttributeContent
                    comparisonAttribute={comparisonCategoryData?.attributeGroups?.find( ( group ) => group.groupName === attribute.name )}
                    comparisonSegments={selectedComparisonSegments}
                    benchmarkAttribute={attribute}
                    key={attribute.name}
                  />
                  )}
                </React.Fragment>;
              } )}
            </div>
          )
    }
  </>;
};
