import isEmpty from 'lodash/isEmpty';

import { CheckOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Popover, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import {
  IBrandInterface,
  ICategorizedBrandInterface,
  IManufacturerInterface,
  selectCategorizedCompetitorsBrands,
  selectCompetitorBrandNames,
  selectCompetitorManufacturerNames,
  selectCompetitorsBrands,
  selectCompetitorsManufacturersByCategoryId,
  setCompetitorsBrands,
} from '../../../../store/brandSlice';
import { setSpecificTab } from '../../../../store/tabSlice';
import { collapseAllWizards, setCompetitorsWizard } from '../../../../store/wizardSlice';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { TAG_VALUES, WIZARD_NAME } from '../../types';
import { buyerTypeToTagName } from '../AdditionalParametersCollapse/const';
import CollapseHeader from '../CollapseHeader';
import {
  BUTTON_BORDER_COLOR,
  getSortedBrandList,
  ALL_BUYER_TYPES,
  tooltipContent,
  BUYER_TYPE_DESCRIPTION,
  AUDIENCE_ID,
  options,
  stepErrorDescription,
} from '../const';
import { ICollapseProps } from '../types';
import { selectWizardCompetitorsByTabName } from '../../WizardTabs/selectors';
import {
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectBuyerTypesByAudienceId,
  selectCategoryByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
  selectManufacturerByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { BuyerType } from '../../../../typings';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import { useGetBrandsLazyQuery } from '../../../../apollo/graphql-types';
import { handleScrollIntoView } from '../utils';

interface ICompetitorsCollapseProps extends ICollapseProps {
  audience: AUDIENCE_ID;
}

const CompetitorsCollapse = ( { handleCollapseChange, expandIconStyles, audience }: ICompetitorsCollapseProps ) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();

  const activeTab = audience;
  const [retry, setRetry] = useState( false );

  const competitorsWizard = useSelector( selectWizardCompetitorsByTabName( activeTab ) );
  const selectedCategorySelector = useSelector( selectCategoryByAudienceId( activeTab ) );
  const selectedCategory = selectedCategorySelector || 0;
  const selectedManufacturer = useSelector( selectManufacturerByAudienceId( activeTab ) );
  const selectedBuyerTypesSelector = useSelector( selectBuyerTypesByAudienceId( activeTab ) );
  const selectedBuyerTypes = selectedBuyerTypesSelector || [];
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const manufacturersListSelector = useSelector( selectCompetitorsManufacturersByCategoryId( selectedCategory ) );
  const manufacturersList = manufacturersListSelector || [];

  const competitorsListSelector = useSelector( selectCategorizedCompetitorsBrands );
  const competitorsList = competitorsListSelector || [];
  const manufacturerNamesSelector = useSelector( selectCompetitorManufacturerNames );
  const manufacturerNames = manufacturerNamesSelector || [];
  const competitorsBrandsNamesSelector = useSelector( selectCompetitorBrandNames );
  const competitorsBrandsNames = competitorsBrandsNamesSelector || [];
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );

  const [showAllMap, setShowAllMap] = useState<{ [key: string]: boolean }>( {} );
  const [buyerTypeTagValue, setBuyerTypeTagValue] = useState<string[] | string>();
  const [manufacturersForReduction, setManufacturersForReduction] = useState<IManufacturerInterface[]>( [] );
  const [brandsForReduction, setBrandsForReduction] = useState<IBrandInterface[]>( [] );
  const competitorsBrands = useSelector( selectCompetitorsBrands );

  const isLoading = competitorsWizard.isLoading || ( competitorsList || [] ).length < 1;
  const availableBrands = manufacturersList.find( ( manufacturer: IManufacturerInterface ) =>
    manufacturer.manufacturerId === selectedManufacturer )?.brands || [];
  const isAllTypesSelected = selectedBuyerTypes.length === 5;
  const isMultipleBrands = availableBrands.length > 1;
  const isAllBrandsSelected = availableBrands.length === selectedBrands.length;
  const ALL_BRANDS_CAPTION = `${manufacturerNames[selectedManufacturer]} All Brands`;
  const SOME_BRANDS_CAPTION = `${manufacturerNames[selectedManufacturer]} ${getSortedBrandList( selectedBrands, availableBrands )
    .map( ( brand ) => competitorsBrandsNames[brand] ).join( ', ' )}`;
  const brandTagValue = isAllBrandsSelected && isMultipleBrands ? [ALL_BRANDS_CAPTION] : SOME_BRANDS_CAPTION;

  const scrollRef = useRef<HTMLDivElement | null>( null );

  const [loadCompetitors, { loading: isCompetitorsLoading }] = useGetBrandsLazyQuery( {
    ...options(),
    variables: { isCompetitor: true },
  } );

  const loadCompetitorsInitiator = () => {
    loadCompetitors()
      .then( ( { data, error } ) => {
        if ( !!data ) {
          dispatch( setCompetitorsBrands( data?.categorizedBrands ) );
        }

        if ( !!error ) {
          dispatch( setCompetitorsWizard( { error: true, activeTab } ) );
        }
      } )
      .catch( ( reject ) => {
        dispatch( setCompetitorsWizard( { error: true, activeTab } ) );
        console.error(`Loading data error: ${reject}`); //eslint-disable-line
      } );
  };

  const handleCategoryClick = ( id: number ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    setShowAllMap( ( prevState ) => ( {
      ...prevState,
      [selectedCategory]: false,
    } ) );
    if ( selectedCategory !== id ) {
      dispatch( setAudienceParameters( [activeTab, { brands: [], buyerTypes: Object.values( BuyerType ) }] ) );
    }
    dispatch( setAudienceParameters( [activeTab, { category: id, manufacturer: undefined }] ) );
  };

  const handleManufacturerClick = ( id: number ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    setShowAllMap( ( prevState ) => ( {
      ...prevState,
      [selectedManufacturer]: false,
    } ) );
    if ( selectedManufacturer !== id ) {
      dispatch( setAudienceParameters( [activeTab, { manufacturer: id, brands: [] }] ) );
    }

    dispatch( setAudienceParameters( [activeTab, { manufacturer: id }] ) );
  };

  const handleBrandClick = ( name: number ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedBrands?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { brands: selectedBrands.filter( ( brand ) => brand !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { brands: [...selectedBrands, name] }] ) );
  };

  const handleClickAllBrands = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( availableBrands.length === selectedBrands.length ) {
      dispatch( setAudienceParameters( [activeTab, { brands: [] }] ) );
      return;
    }

    dispatch( setAudienceParameters( [activeTab, { brands:  availableBrands.map( ( brand: any ) => brand.brandId ) }] ) );
  };

  const handleBuyerTypeClick = ( name: BuyerType ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedBuyerTypes?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: selectedBuyerTypes.filter( ( buyerType ) => buyerType !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { buyerTypes: [...selectedBuyerTypes, name] }] ) );
  };

  const handleClickAllBuyerTypes = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, { buyerTypes: isAllTypesSelected ? [] : Object.values( BuyerType ) }] ) );
  };

  const handleShowAllManufacturersClick = ( category: number ) => {
    setShowAllMap( ( prevState ) => ( {
      ...prevState,
      [category]: true,
    } ) );
  };

  const handleFinishWizardClick = () => {
    dispatch(
      setCompetitorsWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
        tag:        [
          { name: TAG_VALUES.BRAND, value: brandTagValue },
          { name: TAG_VALUES.BUYER_TYPE, value: buyerTypeTagValue },
        ],
      } ),
    );
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true, isCompetitors: true } ) );
  };

  useEffect( () => {
    if ( !isLoading || !!selectedCategory || !!selectedManufacturer || !!selectedBrands || !!selectedBuyerTypes ) {
      handleScrollIntoView( scrollRef );
    }
  }, [isLoading, selectedCategory, selectedManufacturer, selectedBrands, selectedBuyerTypes] );

  useEffect( () => {
    if ( competitorsWizard.isLoading === false && competitorsList.length > 0 && !selectedCategory ) {
      const defaultCategory = competitorsList[0]?.categoryId;
      dispatch( setAudienceParameters( [activeTab, { category: defaultCategory }] ) );
    }
  }, [competitorsWizard.isLoading, competitorsList.length] );

  useEffect( () => {
    if ( selectedBuyerTypes?.length === 0 ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: Object.values( BuyerType ) }] ) );
    }
  }, [] );

  useEffect( () => {
    const shouldShowAll = showAllMap[selectedCategory] || false;
    setManufacturersForReduction( manufacturersList.slice( 0, shouldShowAll ? manufacturersList.length : 10 ) );
  }, [showAllMap, selectedCategory] );

  useEffect( () => {
    const shouldShowAll = showAllMap[selectedManufacturer] || false;
    setBrandsForReduction(
      availableBrands.slice( 0, shouldShowAll ? availableBrands.length : 10 ),
    );
  }, [showAllMap, selectedManufacturer] );

  useEffect( () => {
    setBuyerTypeTagValue(
      isAllTypesSelected
        ? ALL_BUYER_TYPES
			 	: Object.values( BuyerType ).filter( ( bt ) => selectedBuyerTypes.includes( bt ) ).map( ( bt ) => buyerTypeToTagName[bt] ),
    );
  }, [selectedBuyerTypes] );

  useEffect( () => {
    if ( !competitorsWizard.isFinished && brandTagValue ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [competitorsWizard.isFinished] );

  useEffect( () => {
    dispatch( setCompetitorsWizard( { isLoading: isCompetitorsLoading, activeTab } ) );
  }, [isCompetitorsLoading] );

  useEffect( () => {
    if ( isEmpty( competitorsBrands ) ) {
      loadCompetitorsInitiator();
    }
  }, [] );

  useEffect( () => {
    if ( retry ) {
      dispatch( setCompetitorsWizard( { error: false, activeTab } ) );
      setRetry( false );
      loadCompetitorsInitiator();
    }
  }, [retry] );

  useEffect( () => {
    if ( !competitorsWizard.error ) {
      loadCompetitorsInitiator();
    }
  }, [competitorsWizard.error] );

  useEffect( () => {
    if ( hasAudiencesToCompare && isEqualComparisons ) {
      Object.entries( audiencesToCompare ).filter( ( [key, audience] ) => !!audience ).map( ( [key, audience] ) => {
        const isCompetitorBrandsSource = ![18, 32].includes( audience.audienceParameters?.manufacturer || 0 );

        if ( isCompetitorBrandsSource ) {
          dispatch(
            setCompetitorsWizard( {
              activeTab,
              isFinished: true,
              tag:        [
                { name: TAG_VALUES.BRAND, value: brandTagValue },
                {
									 name:  TAG_VALUES.BUYER_TYPE,
									 value: isAllTypesSelected
									 ? ALL_BUYER_TYPES
									 : Object.values( BuyerType ).filter( ( bt ) => selectedBuyerTypes.includes( bt ) )
									 		.map( ( bt ) => buyerTypeToTagName[bt] ) },
              ],
            } ),
          );
          dispatch( setSpecificTab( { activeTab, isFinished: true, isCompetitors: true } ) );
        }
      } );
    }
  }, [] );

  return competitorsWizard.error
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          setRetry( true );
        }}
      />
    )
    : isLoading
      ? <BladeLoader />
      : <Collapse
        defaultActiveKey={WIZARD_NAME.COMPETITORS}
        activeKey={competitorsWizard.isExpand ? WIZARD_NAME.COMPETITORS : ''}
        collapsible='icon'
        expandIconPosition='end'
        onChange={() => handleCollapseChange( setCompetitorsWizard, competitorsWizard )}
        expandIcon={() =>
          competitorsWizard.isExpand
            ? (
              <MinusOutlined style={expandIconStyles} />
            )
            : (
              <PlusOutlined style={expandIconStyles} />
            )
        }
      >
        <Panel
          key={WIZARD_NAME.COMPETITORS}
          header={
            <CollapseHeader
              step={WIZARD_NAME.COMPETITORS}
              heading='Step 02: Brand'
              caption='Select a brand to proceed with'
            />
          }
          style={{ padding: '0.75rem 0.5rem 0.75rem', gap: 8 }}
        >
          <Space className='blade-buttons' wrap>
            {/* Categories */}
            {( competitorsList || [] ).map( ( brand: ICategorizedBrandInterface ) => (
              <Button
                key={brand.categoryName}
                type={selectedCategory === brand.categoryId ? 'primary' : 'default'}
                style={{
                  overflow:      'hidden',
                  whiteSpace:    'nowrap',
                  textOverflow:  'ellipsis',
                  textTransform: 'capitalize',
                  borderColor:   brand.categoryId === selectedCategory ? BUTTON_BORDER_COLOR : '',
                }}
                onClick={() => handleCategoryClick( brand.categoryId )}
              >
                {brand.categoryName}
              </Button>
            ) )}
          </Space>
          {/* Manufacturers */}
          {selectedCategory && (
            <>
              <div style={{ paddingTop: 16 }}>
                <TitleWithCaption caption='Select Manufacturer' captionFontSize={16} />
              </div>
              <Space className='blade-buttons' wrap style={{ paddingTop: 16 }}>
                {manufacturersForReduction?.map( ( manufacturer: IManufacturerInterface ) => {
                  const { manufacturerName, manufacturerId } = manufacturer;
                  return (
                    <Button
                      key={manufacturerName}
                      style={{
                        textTransform: 'capitalize',
                        color:         selectedManufacturer === manufacturerId ? BUTTON_BORDER_COLOR : '',
                        borderColor:   selectedManufacturer === manufacturerId ? BUTTON_BORDER_COLOR : '',
                      }}
                      onClick={() => handleManufacturerClick( manufacturerId )}
                    >
                      {manufacturerName}
                    </Button>
                  );
                } )}
                {!showAllMap[selectedCategory]
								&& manufacturersForReduction.length >= 10
								&& manufacturersList.length !== 10 && (
                  <div className='link-button'>
                    <Button type='link' onClick={() => handleShowAllManufacturersClick( selectedCategory )}>
											Show All
                    </Button>
                  </div>
                )}
              </Space>
            </>
          )}

          {/* All Brands */}
          <div ref={scrollRef}>

            {!!selectedManufacturer && (
              <>
                <Divider style={{ margin: '1rem 0 1rem' }} />
                <div>
                  <TitleWithCaption caption='Select Brand' captionFontSize={16} />
                </div>
                {isMultipleBrands && (
                  <Space className='blade-buttons' style={{ display: 'block' }}>
                    <Button
                      key='All Brands'
                      type={isAllBrandsSelected ? 'primary' : 'default'}
                      style={{ marginTop: 16, width: '100%' }}
                      onClick={handleClickAllBrands}
                    >
									All Brands
                    </Button>
                  </Space>
                )}
                {/* Brands */}
                <Space className='blade-buttons' wrap style={{ paddingTop: 16 }}>
                  {brandsForReduction?.map( ( brand: IBrandInterface ) => {
                    const { brandName, brandId } = brand;
                    return (
                      <Button
                        key={brandName}
                        style={{
                          textTransform: 'capitalize',
                          color:         selectedBrands.includes( brandId ) ? BUTTON_BORDER_COLOR : '',
                          borderColor:   selectedBrands.includes( brandId ) ? BUTTON_BORDER_COLOR : '',
                        }}
                        icon={selectedBrands.includes( brandId ) ? <CheckOutlined /> : ''}
                        onClick={() => handleBrandClick( brandId )}
                      >
                        {brandName}
                      </Button>
                    );
                  } )}
                  {!showAllMap[selectedManufacturer]
								&& brandsForReduction.length >= 10
								&& selectedBrands.length !== 10 && (
                    <div className='link-button'>
                      <Button type='link' onClick={() => handleShowAllManufacturersClick( selectedManufacturer )}>
											Show All
                      </Button>
                    </div>
                  )}
                </Space>
                {selectedBrands.length > 0 && (
                  <>
                    <Divider style={{ margin: '1rem 0 1rem' }} />
                    <div style={{ paddingBottom: 16 }}>
                      <span className='buyer_type-info'>Buyer Type</span>
                      <Popover content={tooltipContent( BUYER_TYPE_DESCRIPTION )} trigger='hover' placement='bottom'>
                        <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', paddingLeft: 8, cursor: 'pointer' }} />
                      </Popover>
                    </div>
                    <Space className='blade-buttons'>
                      <Button
                        key='All Buyer Types'
                        type={isAllTypesSelected ? 'primary' : 'default'}
                        style={{ width: 621 }}
                        onClick={handleClickAllBuyerTypes}
                      >
                        {ALL_BUYER_TYPES}
                      </Button>
                    </Space>
                    <Space className='blade-buttons' wrap style={{ paddingTop: 16 }}>
                      {Object.values( BuyerType ).map( ( buyerType ) => {
                        const isSelected = selectedBuyerTypes.includes( buyerType );

                        return (
                          <Button
                            key={buyerType}
                            style={{
                              maxWidth:      '620px',
                              overflow:      'hidden',
                              whiteSpace:    'nowrap',
                              textOverflow:  'ellipsis',
                              textTransform: 'capitalize',
                              color:         isSelected ? BUTTON_BORDER_COLOR : '',
                              borderColor:   isSelected ? BUTTON_BORDER_COLOR : '',
                            }}
                            icon={isSelected ? <CheckOutlined /> : ''}
                            onClick={() => handleBuyerTypeClick( buyerType )}
                          >
                            {buyerTypeToTagName[buyerType]}
                          </Button>
                        );
                      } )}
                    </Space>
                    {selectedBuyerTypes.length > 0 && (
                      <>
                        <Divider style={{ margin: '1rem 0 1rem' }} />
                        <div className='next-button'>
                          <Button onClick={handleFinishWizardClick}>Finish Wizard</Button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Panel>
      </Collapse>;
};
export default CompetitorsCollapse;
