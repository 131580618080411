import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AttributesCalcInitResult = {
  __typename?: 'AttributesCalcInitResult';
  exclusive: Array<SegmentCalculationAnnotation>;
  only: Array<SegmentCalculationAnnotation>;
  overlap: Array<SegmentCalculationAnnotation>;
  requestId: Scalars['String']['output'];
};

export type AudienceActivation = {
  requestId: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
  selectedAudiences: Scalars['String']['input'];
  submittedAudiences: Scalars['String']['input'];
};

export type AudienceComparisonParameters = {
  __typename?: 'AudienceComparisonParameters';
  brands: Array<Scalars['Int']['output']>;
  buyerTypes: Array<BuyerType>;
  category: Scalars['Int']['output'];
  d2c?: Maybe<Scalars['Boolean']['output']>;
  engagementStatus?: Maybe<EngagementStatus>;
  firstParty?: Maybe<Scalars['Boolean']['output']>;
  manufacturer: Scalars['Int']['output'];
  marketingCampaigns?: Maybe<Array<Scalars['Int']['output']>>;
  thirdParty?: Maybe<Scalars['Boolean']['output']>;
};

export type AudienceComparisonTemplate = {
  __typename?: 'AudienceComparisonTemplate';
  audienceA: AudienceToCompare;
  audienceB: AudienceToCompare;
  audienceC?: Maybe<AudienceToCompare>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};

export type AudienceDistribution = {
  __typename?: 'AudienceDistribution';
  exclusive: Array<AudienceSegment>;
  only: Array<AudienceSegment>;
  overlap: Array<AudienceSegment>;
};

export type AudienceMutation = {
  __typename?: 'AudienceMutation';
  deleteAudienceTemplate: AudienceComparisonTemplate;
  initiateAttributesCalculations: AttributesCalcInitResult;
  initiateAudienceDistributionCalc: ComparisonInitiationResult;
  renameAudienceTemplate: AudienceTemplateAnnotation;
  submitAudienceForActivation: EmailSendResult;
  templateCreation: AudienceComparisonTemplate;
};


export type AudienceMutationDeleteAudienceTemplateArgs = {
  templateId: Scalars['Int']['input'];
};


export type AudienceMutationInitiateAttributesCalculationsArgs = {
  totalsPrerequisites: TotalsPrerequisites;
};


export type AudienceMutationInitiateAudienceDistributionCalcArgs = {
  audienceA: IncomingAudience;
  audienceB: IncomingAudience;
  audienceC?: InputMaybe<IncomingAudience>;
};


export type AudienceMutationRenameAudienceTemplateArgs = {
  audienceTemplateAnnotation: IncomingAudienceTemplateAnnotation;
};


export type AudienceMutationSubmitAudienceForActivationArgs = {
  audienceActivation: AudienceActivation;
};


export type AudienceMutationTemplateCreationArgs = {
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
};

export type AudienceParameters = {
  brands: Array<Scalars['Int']['input']>;
  buyerTypes: Array<BuyerType>;
  category: Scalars['Int']['input'];
  d2c?: InputMaybe<Scalars['Boolean']['input']>;
  engagementStatus?: InputMaybe<EngagementStatus>;
  firstParty?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer: Scalars['Int']['input'];
  marketingCampaigns?: InputMaybe<Array<Scalars['Int']['input']>>;
  thirdParty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudienceQuery = {
  __typename?: 'AudienceQuery';
  audienceComparisonDetailedAttributes: DetailedComparisonAttributes;
  audienceDistribution: AudienceDistribution;
  benchmarkAttributes: Array<BenchmarkChartAttributeCategory>;
  brandMarketingCampaigns: D2C;
  categorizedBrands: Array<Category>;
  checkAudienceTemplateUnique: AudienceTemplateAnnotation;
  divergentAttributes: Array<DivergentChartCategory>;
  filteringAttributes: Array<FilterAttributeCategory>;
  getAudienceTemplate: AudienceComparisonTemplate;
  listAudienceTemplates: Array<AudienceTemplateAnnotation>;
  preBuiltAudienceNames: Array<PreBuiltAudience>;
};


export type AudienceQueryAudienceComparisonDetailedAttributesArgs = {
  comparisonSegments: ComparisonSegments;
};


export type AudienceQueryAudienceDistributionArgs = {
  totalsPrerequisites: TotalsPrerequisites;
};


export type AudienceQueryBenchmarkAttributesArgs = {
  benchmarkAttributes: BenchmarkAttributes;
};


export type AudienceQueryBrandMarketingCampaignsArgs = {
  brands: Array<IncomingBrand>;
};


export type AudienceQueryCategorizedBrandsArgs = {
  isCompetitor?: Scalars['Boolean']['input'];
};


export type AudienceQueryCheckAudienceTemplateUniqueArgs = {
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
};


export type AudienceQueryDivergentAttributesArgs = {
  requestedDivergentAttributes: RequestedDivergentAttributes;
};


export type AudienceQueryGetAudienceTemplateArgs = {
  templateId: Scalars['Int']['input'];
};

export type AudienceSegment = {
  __typename?: 'AudienceSegment';
  benchmark: Scalars['Boolean']['output'];
  membersNumber: Scalars['Int']['output'];
  position: Array<Scalars['String']['output']>;
  segmentId: Scalars['String']['output'];
};

export type AudienceTemplateAnnotation = {
  __typename?: 'AudienceTemplateAnnotation';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AudienceToCompare = {
  __typename?: 'AudienceToCompare';
  audienceParameters?: Maybe<AudienceComparisonParameters>;
  benchmark: Scalars['Boolean']['output'];
  preBuiltAudienceIds?: Maybe<Array<Scalars['Int']['output']>>;
};

export type BenchmarkAttributes = {
  attributes: Array<Scalars['String']['input']>;
  queryId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
};

export type BenchmarkChartAttribute = {
  __typename?: 'BenchmarkChartAttribute';
  lowCoverageValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  values: Array<BenchmarkChartAttributeValue>;
};

export type BenchmarkChartAttributeCategory = {
  __typename?: 'BenchmarkChartAttributeCategory';
  attributeGroups?: Maybe<Array<BenchmarkChartAttributeGroup>>;
  attributes?: Maybe<Array<BenchmarkChartAttribute>>;
  name: Scalars['String']['output'];
};

export type BenchmarkChartAttributeGroup = {
  __typename?: 'BenchmarkChartAttributeGroup';
  attributes?: Maybe<Array<BenchmarkChartAttribute>>;
  name: Scalars['String']['output'];
};

export type BenchmarkChartAttributeValue = {
  __typename?: 'BenchmarkChartAttributeValue';
  fillRate: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Brand = {
  __typename?: 'Brand';
  brandId: Scalars['Int']['output'];
  brandName: Scalars['String']['output'];
};

export enum BuyerType {
  Heavy = 'HEAVY',
  Lapsed = 'LAPSED',
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  NonBuyer = 'NON_BUYER'
}

export type Category = {
  __typename?: 'Category';
  categoryId: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  manufacturers: Array<Manufacturer>;
};

export type ComparisonAttribute = {
  __typename?: 'ComparisonAttribute';
  attributeName: Scalars['String']['output'];
  attributeValue: Scalars['String']['output'];
};

export type ComparisonInitiationResult = {
  __typename?: 'ComparisonInitiationResult';
  queryId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
};

export type ComparisonSegments = {
  benchmark?: InputMaybe<IncomingSegmentAttributesAnnotation>;
  comparison: IncomingSegmentAttributesAnnotation;
  requestId: Scalars['String']['input'];
};

export type D2C = {
  __typename?: 'D2C';
  no?: Maybe<Array<MarketingCampaign>>;
  yes?: Maybe<Array<MarketingCampaign>>;
};

export type DefaultComparisonAttribute = {
  __typename?: 'DefaultComparisonAttribute';
  attributeName: Scalars['String']['output'];
  attributePercentage: Scalars['Float']['output'];
  attributeValue: Scalars['String']['output'];
};

export type DetailedComparisonAttributes = {
  __typename?: 'DetailedComparisonAttributes';
  defaultAttributes: Array<DefaultComparisonAttribute>;
  overrepresented?: Maybe<Array<ComparisonAttribute>>;
  underrepresented?: Maybe<Array<ComparisonAttribute>>;
};

export type DivergentAttributesSegment = {
  benchmark: Scalars['Boolean']['input'];
  queryId: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
};

export type DivergentBar = {
  __typename?: 'DivergentBar';
  attributeCount: Scalars['Int']['output'];
  divergentRatio: Scalars['Int']['output'];
  segmentId: Scalars['String']['output'];
};

export type DivergentChartAttribute = {
  __typename?: 'DivergentChartAttribute';
  attributeName: Scalars['String']['output'];
  comparisonValues: Array<DivergentChartValue>;
  lowCoverage?: Maybe<Array<DivergentChartCoverageIndex>>;
};

export type DivergentChartCategory = {
  __typename?: 'DivergentChartCategory';
  attributeGroups?: Maybe<Array<DivergentChartGroup>>;
  attributes?: Maybe<Array<DivergentChartAttribute>>;
  categoryName: Scalars['String']['output'];
};

export type DivergentChartCoverageIndex = {
  __typename?: 'DivergentChartCoverageIndex';
  coverageValue: Scalars['Float']['output'];
  segmentId: Scalars['String']['output'];
};

export type DivergentChartGroup = {
  __typename?: 'DivergentChartGroup';
  attributes: Array<DivergentChartAttribute>;
  groupName: Scalars['String']['output'];
};

export type DivergentChartValue = {
  __typename?: 'DivergentChartValue';
  bars: Array<DivergentBar>;
  valueName: Scalars['String']['output'];
};

export type EmailSendResult = {
  __typename?: 'EmailSendResult';
  authorEmail: Scalars['String']['output'];
  selectedAudiences: Scalars['String']['output'];
  submittedAudiences: Scalars['String']['output'];
};

export enum EngagementStatus {
  Clicked = 'CLICKED',
  Opened = 'OPENED',
  Reached = 'REACHED'
}

export type FilterAttribute = {
  __typename?: 'FilterAttribute';
  default?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  techNameId: Scalars['String']['output'];
};

export type FilterAttributeCategory = {
  __typename?: 'FilterAttributeCategory';
  attributeGroups?: Maybe<Array<FilterAttributeGroup>>;
  attributes?: Maybe<Array<FilterAttribute>>;
  name: Scalars['String']['output'];
};

export type FilterAttributeGroup = {
  __typename?: 'FilterAttributeGroup';
  attributes?: Maybe<Array<FilterAttribute>>;
  name: Scalars['String']['output'];
};

export type IncomingAudience = {
  audienceParameters?: InputMaybe<AudienceParameters>;
  benchmark: Scalars['Boolean']['input'];
  preBuiltAudienceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IncomingAudienceComparisonTemplate = {
  audienceA: IncomingAudience;
  audienceB: IncomingAudience;
  audienceC?: InputMaybe<IncomingAudience>;
  name: Scalars['String']['input'];
  version?: Scalars['Int']['input'];
};

export type IncomingAudienceTemplateAnnotation = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type IncomingBrand = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type IncomingSegmentAttributesAnnotation = {
  queryId: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  brands: Array<Brand>;
  manufacturerId: Scalars['Int']['output'];
  manufacturerName: Scalars['String']['output'];
};

export type MarketingCampaign = {
  __typename?: 'MarketingCampaign';
  brandName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isEmail: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type PreBuiltAudience = {
  __typename?: 'PreBuiltAudience';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RequestedDivergentAttributes = {
  attributes: Array<Scalars['String']['input']>;
  requestId: Scalars['String']['input'];
  segments: Array<DivergentAttributesSegment>;
};

export type SegmentCalculationAnnotation = {
  __typename?: 'SegmentCalculationAnnotation';
  benchmark: Scalars['Boolean']['output'];
  position: Array<Scalars['String']['output']>;
  queryId: Scalars['String']['output'];
  segmentId: Scalars['String']['output'];
};

export type TotalsPrerequisites = {
  audienceNames?: InputMaybe<Array<Scalars['String']['input']>>;
  queryId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};

export type PreBuiltAudienceNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type PreBuiltAudienceNamesQuery = { __typename?: 'AudienceQuery', preBuiltAudienceNames: Array<{ __typename?: 'PreBuiltAudience', name: string, id: number }> };

export type GetBrandsQueryVariables = Exact<{
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBrandsQuery = { __typename?: 'AudienceQuery', categorizedBrands: Array<{ __typename?: 'Category', categoryId: number, categoryName: string, manufacturers: Array<{ __typename?: 'Manufacturer', manufacturerId: number, manufacturerName: string, brands: Array<{ __typename?: 'Brand', brandId: number, brandName: string }> }> }> };

export type BrandMarketingCampaignsQueryVariables = Exact<{
  brands: Array<IncomingBrand> | IncomingBrand;
}>;


export type BrandMarketingCampaignsQuery = { __typename?: 'AudienceQuery', brandMarketingCampaigns: { __typename?: 'D2C', yes?: Array<{ __typename?: 'MarketingCampaign', id: number, name: string, brandName: string, isEmail: boolean }> | null, no?: Array<{ __typename?: 'MarketingCampaign', id: number, name: string, brandName: string, isEmail: boolean }> | null } };

export type InitiateAudienceDistributionCalcMutationVariables = Exact<{
  audienceA: IncomingAudience;
  audienceB: IncomingAudience;
  audienceC?: InputMaybe<IncomingAudience>;
}>;


export type InitiateAudienceDistributionCalcMutation = { __typename?: 'AudienceMutation', initiateAudienceDistributionCalc: { __typename?: 'ComparisonInitiationResult', requestId: string, queryId: string } };

export type AudienceDistributionQueryVariables = Exact<{
  totalsPrerequisites: TotalsPrerequisites;
}>;


export type AudienceDistributionQuery = { __typename?: 'AudienceQuery', audienceDistribution: { __typename?: 'AudienceDistribution', only: Array<{ __typename?: 'AudienceSegment', segmentId: string, position: Array<string>, benchmark: boolean, membersNumber: number }>, exclusive: Array<{ __typename?: 'AudienceSegment', segmentId: string, position: Array<string>, benchmark: boolean, membersNumber: number }>, overlap: Array<{ __typename?: 'AudienceSegment', segmentId: string, position: Array<string>, benchmark: boolean, membersNumber: number }> } };

export type TemplateCreationMutationVariables = Exact<{
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
}>;


export type TemplateCreationMutation = { __typename?: 'AudienceMutation', templateCreation: { __typename?: 'AudienceComparisonTemplate', id: number, name: string } };

export type RenameAudienceTemplateMutationVariables = Exact<{
  audienceTemplateAnnotation: IncomingAudienceTemplateAnnotation;
}>;


export type RenameAudienceTemplateMutation = { __typename?: 'AudienceMutation', renameAudienceTemplate: { __typename?: 'AudienceTemplateAnnotation', id?: number | null, name?: string | null } };

export type CheckAudienceTemplateUniqueQueryVariables = Exact<{
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
}>;


export type CheckAudienceTemplateUniqueQuery = { __typename?: 'AudienceQuery', checkAudienceTemplateUnique: { __typename?: 'AudienceTemplateAnnotation', id?: number | null, name?: string | null } };

export type ListAudienceTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAudienceTemplatesQuery = { __typename?: 'AudienceQuery', listAudienceTemplates: Array<{ __typename?: 'AudienceTemplateAnnotation', id?: number | null, name?: string | null }> };

export type DeleteAudienceTemplateMutationVariables = Exact<{
  templateId: Scalars['Int']['input'];
}>;


export type DeleteAudienceTemplateMutation = { __typename?: 'AudienceMutation', deleteAudienceTemplate: { __typename?: 'AudienceComparisonTemplate', id: number, name: string } };

export type GetAudienceTemplateQueryVariables = Exact<{
  templateId: Scalars['Int']['input'];
}>;


export type GetAudienceTemplateQuery = { __typename?: 'AudienceQuery', getAudienceTemplate: { __typename?: 'AudienceComparisonTemplate', id: number, version: number, name: string, audienceA: { __typename?: 'AudienceToCompare', benchmark: boolean, preBuiltAudienceIds?: Array<number> | null, audienceParameters?: { __typename?: 'AudienceComparisonParameters', thirdParty?: boolean | null, firstParty?: boolean | null, engagementStatus?: EngagementStatus | null, buyerTypes: Array<BuyerType>, category: number, manufacturer: number, brands: Array<number>, marketingCampaigns?: Array<number> | null } | null }, audienceB: { __typename?: 'AudienceToCompare', benchmark: boolean, preBuiltAudienceIds?: Array<number> | null, audienceParameters?: { __typename?: 'AudienceComparisonParameters', thirdParty?: boolean | null, firstParty?: boolean | null, engagementStatus?: EngagementStatus | null, buyerTypes: Array<BuyerType>, category: number, manufacturer: number, brands: Array<number>, marketingCampaigns?: Array<number> | null } | null }, audienceC?: { __typename?: 'AudienceToCompare', benchmark: boolean, preBuiltAudienceIds?: Array<number> | null, audienceParameters?: { __typename?: 'AudienceComparisonParameters', thirdParty?: boolean | null, firstParty?: boolean | null, engagementStatus?: EngagementStatus | null, buyerTypes: Array<BuyerType>, category: number, manufacturer: number, brands: Array<number>, marketingCampaigns?: Array<number> | null } | null } | null } };

export type InitiateAttributesCalculationsMutationVariables = Exact<{
  totalsPrerequisites: TotalsPrerequisites;
}>;


export type InitiateAttributesCalculationsMutation = { __typename?: 'AudienceMutation', initiateAttributesCalculations: { __typename?: 'AttributesCalcInitResult', requestId: string, only: Array<{ __typename?: 'SegmentCalculationAnnotation', segmentId: string, queryId: string, position: Array<string>, benchmark: boolean }>, exclusive: Array<{ __typename?: 'SegmentCalculationAnnotation', segmentId: string, queryId: string, position: Array<string>, benchmark: boolean }>, overlap: Array<{ __typename?: 'SegmentCalculationAnnotation', segmentId: string, queryId: string, position: Array<string>, benchmark: boolean }> } };

export type AudienceComparisonDetailedAttributesQueryVariables = Exact<{
  comparisonSegments: ComparisonSegments;
}>;


export type AudienceComparisonDetailedAttributesQuery = { __typename?: 'AudienceQuery', audienceComparisonDetailedAttributes: { __typename?: 'DetailedComparisonAttributes', defaultAttributes: Array<{ __typename?: 'DefaultComparisonAttribute', attributeName: string, attributeValue: string, attributePercentage: number }>, underrepresented?: Array<{ __typename?: 'ComparisonAttribute', attributeName: string, attributeValue: string }> | null, overrepresented?: Array<{ __typename?: 'ComparisonAttribute', attributeName: string, attributeValue: string }> | null } };

export type SubmitAudienceForActivationMutationVariables = Exact<{
  audienceActivation: AudienceActivation;
}>;


export type SubmitAudienceForActivationMutation = { __typename?: 'AudienceMutation', submitAudienceForActivation: { __typename?: 'EmailSendResult', authorEmail: string, selectedAudiences: string, submittedAudiences: string } };

export type FilteringAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type FilteringAttributesQuery = { __typename?: 'AudienceQuery', filteringAttributes: Array<{ __typename?: 'FilterAttributeCategory', name: string, attributes?: Array<{ __typename?: 'FilterAttribute', techNameId: string, name: string, default?: boolean | null }> | null, attributeGroups?: Array<{ __typename?: 'FilterAttributeGroup', name: string, attributes?: Array<{ __typename?: 'FilterAttribute', techNameId: string, name: string, default?: boolean | null }> | null }> | null }> };

export type BenchmarkAttributesQueryVariables = Exact<{
  benchmarkAttributes: BenchmarkAttributes;
}>;


export type BenchmarkAttributesQuery = { __typename?: 'AudienceQuery', benchmarkAttributes: Array<{ __typename?: 'BenchmarkChartAttributeCategory', name: string, attributeGroups?: Array<{ __typename?: 'BenchmarkChartAttributeGroup', name: string, attributes?: Array<{ __typename?: 'BenchmarkChartAttribute', name: string, lowCoverageValue?: number | null, values: Array<{ __typename?: 'BenchmarkChartAttributeValue', name: string, fillRate: string }> }> | null }> | null, attributes?: Array<{ __typename?: 'BenchmarkChartAttribute', name: string, lowCoverageValue?: number | null, values: Array<{ __typename?: 'BenchmarkChartAttributeValue', name: string, fillRate: string }> }> | null }> };

export type DivergentAttributesQueryVariables = Exact<{
  requestedDivergentAttributes: RequestedDivergentAttributes;
}>;


export type DivergentAttributesQuery = { __typename?: 'AudienceQuery', divergentAttributes: Array<{ __typename?: 'DivergentChartCategory', categoryName: string, attributeGroups?: Array<{ __typename?: 'DivergentChartGroup', groupName: string, attributes: Array<{ __typename?: 'DivergentChartAttribute', attributeName: string, lowCoverage?: Array<{ __typename?: 'DivergentChartCoverageIndex', segmentId: string, coverageValue: number }> | null, comparisonValues: Array<{ __typename?: 'DivergentChartValue', valueName: string, bars: Array<{ __typename?: 'DivergentBar', segmentId: string, divergentRatio: number, attributeCount: number }> }> }> }> | null, attributes?: Array<{ __typename?: 'DivergentChartAttribute', attributeName: string, lowCoverage?: Array<{ __typename?: 'DivergentChartCoverageIndex', segmentId: string, coverageValue: number }> | null, comparisonValues: Array<{ __typename?: 'DivergentChartValue', valueName: string, bars: Array<{ __typename?: 'DivergentBar', segmentId: string, divergentRatio: number, attributeCount: number }> }> }> | null }> };


export const PreBuiltAudienceNamesDocument = gql`
    query preBuiltAudienceNames {
  preBuiltAudienceNames {
    name
    id
  }
}
    `;

/**
 * __usePreBuiltAudienceNamesQuery__
 *
 * To run a query within a React component, call `usePreBuiltAudienceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreBuiltAudienceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreBuiltAudienceNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreBuiltAudienceNamesQuery(baseOptions?: Apollo.QueryHookOptions<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>(PreBuiltAudienceNamesDocument, options);
      }
export function usePreBuiltAudienceNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>(PreBuiltAudienceNamesDocument, options);
        }
export function usePreBuiltAudienceNamesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>(PreBuiltAudienceNamesDocument, options);
        }
export type PreBuiltAudienceNamesQueryHookResult = ReturnType<typeof usePreBuiltAudienceNamesQuery>;
export type PreBuiltAudienceNamesLazyQueryHookResult = ReturnType<typeof usePreBuiltAudienceNamesLazyQuery>;
export type PreBuiltAudienceNamesSuspenseQueryHookResult = ReturnType<typeof usePreBuiltAudienceNamesSuspenseQuery>;
export type PreBuiltAudienceNamesQueryResult = Apollo.QueryResult<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>;
export const GetBrandsDocument = gql`
    query getBrands($isCompetitor: Boolean = false) {
  categorizedBrands(isCompetitor: $isCompetitor) {
    categoryId
    categoryName
    manufacturers {
      manufacturerId
      manufacturerName
      brands {
        brandId
        brandName
      }
    }
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      isCompetitor: // value for 'isCompetitor'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const BrandMarketingCampaignsDocument = gql`
    query brandMarketingCampaigns($brands: [IncomingBrand!]!) {
  brandMarketingCampaigns(brands: $brands) {
    yes {
      id
      name
      brandName
      isEmail
    }
    no {
      id
      name
      brandName
      isEmail
    }
  }
}
    `;

/**
 * __useBrandMarketingCampaignsQuery__
 *
 * To run a query within a React component, call `useBrandMarketingCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandMarketingCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandMarketingCampaignsQuery({
 *   variables: {
 *      brands: // value for 'brands'
 *   },
 * });
 */
export function useBrandMarketingCampaignsQuery(baseOptions: Apollo.QueryHookOptions<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>(BrandMarketingCampaignsDocument, options);
      }
export function useBrandMarketingCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>(BrandMarketingCampaignsDocument, options);
        }
export function useBrandMarketingCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>(BrandMarketingCampaignsDocument, options);
        }
export type BrandMarketingCampaignsQueryHookResult = ReturnType<typeof useBrandMarketingCampaignsQuery>;
export type BrandMarketingCampaignsLazyQueryHookResult = ReturnType<typeof useBrandMarketingCampaignsLazyQuery>;
export type BrandMarketingCampaignsSuspenseQueryHookResult = ReturnType<typeof useBrandMarketingCampaignsSuspenseQuery>;
export type BrandMarketingCampaignsQueryResult = Apollo.QueryResult<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>;
export const InitiateAudienceDistributionCalcDocument = gql`
    mutation initiateAudienceDistributionCalc($audienceA: IncomingAudience!, $audienceB: IncomingAudience!, $audienceC: IncomingAudience) {
  initiateAudienceDistributionCalc(
    audienceA: $audienceA
    audienceB: $audienceB
    audienceC: $audienceC
  ) {
    requestId
    queryId
  }
}
    `;
export type InitiateAudienceDistributionCalcMutationFn = Apollo.MutationFunction<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>;

/**
 * __useInitiateAudienceDistributionCalcMutation__
 *
 * To run a mutation, you first call `useInitiateAudienceDistributionCalcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateAudienceDistributionCalcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateAudienceDistributionCalcMutation, { data, loading, error }] = useInitiateAudienceDistributionCalcMutation({
 *   variables: {
 *      audienceA: // value for 'audienceA'
 *      audienceB: // value for 'audienceB'
 *      audienceC: // value for 'audienceC'
 *   },
 * });
 */
export function useInitiateAudienceDistributionCalcMutation(baseOptions?: Apollo.MutationHookOptions<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>(InitiateAudienceDistributionCalcDocument, options);
      }
export type InitiateAudienceDistributionCalcMutationHookResult = ReturnType<typeof useInitiateAudienceDistributionCalcMutation>;
export type InitiateAudienceDistributionCalcMutationResult = Apollo.MutationResult<InitiateAudienceDistributionCalcMutation>;
export type InitiateAudienceDistributionCalcMutationOptions = Apollo.BaseMutationOptions<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>;
export const AudienceDistributionDocument = gql`
    query audienceDistribution($totalsPrerequisites: TotalsPrerequisites!) {
  audienceDistribution(totalsPrerequisites: $totalsPrerequisites) {
    only {
      segmentId
      position
      benchmark
      membersNumber
    }
    exclusive {
      segmentId
      position
      benchmark
      membersNumber
    }
    overlap {
      segmentId
      position
      benchmark
      membersNumber
    }
  }
}
    `;

/**
 * __useAudienceDistributionQuery__
 *
 * To run a query within a React component, call `useAudienceDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceDistributionQuery({
 *   variables: {
 *      totalsPrerequisites: // value for 'totalsPrerequisites'
 *   },
 * });
 */
export function useAudienceDistributionQuery(baseOptions: Apollo.QueryHookOptions<AudienceDistributionQuery, AudienceDistributionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudienceDistributionQuery, AudienceDistributionQueryVariables>(AudienceDistributionDocument, options);
      }
export function useAudienceDistributionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudienceDistributionQuery, AudienceDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudienceDistributionQuery, AudienceDistributionQueryVariables>(AudienceDistributionDocument, options);
        }
export function useAudienceDistributionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AudienceDistributionQuery, AudienceDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AudienceDistributionQuery, AudienceDistributionQueryVariables>(AudienceDistributionDocument, options);
        }
export type AudienceDistributionQueryHookResult = ReturnType<typeof useAudienceDistributionQuery>;
export type AudienceDistributionLazyQueryHookResult = ReturnType<typeof useAudienceDistributionLazyQuery>;
export type AudienceDistributionSuspenseQueryHookResult = ReturnType<typeof useAudienceDistributionSuspenseQuery>;
export type AudienceDistributionQueryResult = Apollo.QueryResult<AudienceDistributionQuery, AudienceDistributionQueryVariables>;
export const TemplateCreationDocument = gql`
    mutation templateCreation($audienceComparisonTemplate: IncomingAudienceComparisonTemplate!) {
  templateCreation(audienceComparisonTemplate: $audienceComparisonTemplate) {
    id
    name
  }
}
    `;
export type TemplateCreationMutationFn = Apollo.MutationFunction<TemplateCreationMutation, TemplateCreationMutationVariables>;

/**
 * __useTemplateCreationMutation__
 *
 * To run a mutation, you first call `useTemplateCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateCreationMutation, { data, loading, error }] = useTemplateCreationMutation({
 *   variables: {
 *      audienceComparisonTemplate: // value for 'audienceComparisonTemplate'
 *   },
 * });
 */
export function useTemplateCreationMutation(baseOptions?: Apollo.MutationHookOptions<TemplateCreationMutation, TemplateCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateCreationMutation, TemplateCreationMutationVariables>(TemplateCreationDocument, options);
      }
export type TemplateCreationMutationHookResult = ReturnType<typeof useTemplateCreationMutation>;
export type TemplateCreationMutationResult = Apollo.MutationResult<TemplateCreationMutation>;
export type TemplateCreationMutationOptions = Apollo.BaseMutationOptions<TemplateCreationMutation, TemplateCreationMutationVariables>;
export const RenameAudienceTemplateDocument = gql`
    mutation renameAudienceTemplate($audienceTemplateAnnotation: IncomingAudienceTemplateAnnotation!) {
  renameAudienceTemplate(audienceTemplateAnnotation: $audienceTemplateAnnotation) {
    id
    name
  }
}
    `;
export type RenameAudienceTemplateMutationFn = Apollo.MutationFunction<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>;

/**
 * __useRenameAudienceTemplateMutation__
 *
 * To run a mutation, you first call `useRenameAudienceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAudienceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAudienceTemplateMutation, { data, loading, error }] = useRenameAudienceTemplateMutation({
 *   variables: {
 *      audienceTemplateAnnotation: // value for 'audienceTemplateAnnotation'
 *   },
 * });
 */
export function useRenameAudienceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>(RenameAudienceTemplateDocument, options);
      }
export type RenameAudienceTemplateMutationHookResult = ReturnType<typeof useRenameAudienceTemplateMutation>;
export type RenameAudienceTemplateMutationResult = Apollo.MutationResult<RenameAudienceTemplateMutation>;
export type RenameAudienceTemplateMutationOptions = Apollo.BaseMutationOptions<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>;
export const CheckAudienceTemplateUniqueDocument = gql`
    query checkAudienceTemplateUnique($audienceComparisonTemplate: IncomingAudienceComparisonTemplate!) {
  checkAudienceTemplateUnique(
    audienceComparisonTemplate: $audienceComparisonTemplate
  ) {
    id
    name
  }
}
    `;

/**
 * __useCheckAudienceTemplateUniqueQuery__
 *
 * To run a query within a React component, call `useCheckAudienceTemplateUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAudienceTemplateUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAudienceTemplateUniqueQuery({
 *   variables: {
 *      audienceComparisonTemplate: // value for 'audienceComparisonTemplate'
 *   },
 * });
 */
export function useCheckAudienceTemplateUniqueQuery(baseOptions: Apollo.QueryHookOptions<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>(CheckAudienceTemplateUniqueDocument, options);
      }
export function useCheckAudienceTemplateUniqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>(CheckAudienceTemplateUniqueDocument, options);
        }
export function useCheckAudienceTemplateUniqueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>(CheckAudienceTemplateUniqueDocument, options);
        }
export type CheckAudienceTemplateUniqueQueryHookResult = ReturnType<typeof useCheckAudienceTemplateUniqueQuery>;
export type CheckAudienceTemplateUniqueLazyQueryHookResult = ReturnType<typeof useCheckAudienceTemplateUniqueLazyQuery>;
export type CheckAudienceTemplateUniqueSuspenseQueryHookResult = ReturnType<typeof useCheckAudienceTemplateUniqueSuspenseQuery>;
export type CheckAudienceTemplateUniqueQueryResult = Apollo.QueryResult<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>;
export const ListAudienceTemplatesDocument = gql`
    query listAudienceTemplates {
  listAudienceTemplates {
    id
    name
  }
}
    `;

/**
 * __useListAudienceTemplatesQuery__
 *
 * To run a query within a React component, call `useListAudienceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAudienceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAudienceTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAudienceTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>(ListAudienceTemplatesDocument, options);
      }
export function useListAudienceTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>(ListAudienceTemplatesDocument, options);
        }
export function useListAudienceTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>(ListAudienceTemplatesDocument, options);
        }
export type ListAudienceTemplatesQueryHookResult = ReturnType<typeof useListAudienceTemplatesQuery>;
export type ListAudienceTemplatesLazyQueryHookResult = ReturnType<typeof useListAudienceTemplatesLazyQuery>;
export type ListAudienceTemplatesSuspenseQueryHookResult = ReturnType<typeof useListAudienceTemplatesSuspenseQuery>;
export type ListAudienceTemplatesQueryResult = Apollo.QueryResult<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>;
export const DeleteAudienceTemplateDocument = gql`
    mutation DeleteAudienceTemplate($templateId: Int!) {
  deleteAudienceTemplate(templateId: $templateId) {
    id
    name
  }
}
    `;
export type DeleteAudienceTemplateMutationFn = Apollo.MutationFunction<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>;

/**
 * __useDeleteAudienceTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteAudienceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAudienceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAudienceTemplateMutation, { data, loading, error }] = useDeleteAudienceTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteAudienceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>(DeleteAudienceTemplateDocument, options);
      }
export type DeleteAudienceTemplateMutationHookResult = ReturnType<typeof useDeleteAudienceTemplateMutation>;
export type DeleteAudienceTemplateMutationResult = Apollo.MutationResult<DeleteAudienceTemplateMutation>;
export type DeleteAudienceTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>;
export const GetAudienceTemplateDocument = gql`
    query GetAudienceTemplate($templateId: Int!) {
  getAudienceTemplate(templateId: $templateId) {
    id
    version
    name
    audienceA {
      benchmark
      preBuiltAudienceIds
      audienceParameters {
        thirdParty
        firstParty
        engagementStatus
        buyerTypes
        category
        manufacturer
        brands
        marketingCampaigns
      }
    }
    audienceB {
      benchmark
      preBuiltAudienceIds
      audienceParameters {
        thirdParty
        firstParty
        engagementStatus
        buyerTypes
        category
        manufacturer
        brands
        marketingCampaigns
      }
    }
    audienceC {
      benchmark
      preBuiltAudienceIds
      audienceParameters {
        thirdParty
        firstParty
        engagementStatus
        buyerTypes
        category
        manufacturer
        brands
        marketingCampaigns
      }
    }
  }
}
    `;

/**
 * __useGetAudienceTemplateQuery__
 *
 * To run a query within a React component, call `useGetAudienceTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetAudienceTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>(GetAudienceTemplateDocument, options);
      }
export function useGetAudienceTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>(GetAudienceTemplateDocument, options);
        }
export function useGetAudienceTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>(GetAudienceTemplateDocument, options);
        }
export type GetAudienceTemplateQueryHookResult = ReturnType<typeof useGetAudienceTemplateQuery>;
export type GetAudienceTemplateLazyQueryHookResult = ReturnType<typeof useGetAudienceTemplateLazyQuery>;
export type GetAudienceTemplateSuspenseQueryHookResult = ReturnType<typeof useGetAudienceTemplateSuspenseQuery>;
export type GetAudienceTemplateQueryResult = Apollo.QueryResult<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>;
export const InitiateAttributesCalculationsDocument = gql`
    mutation initiateAttributesCalculations($totalsPrerequisites: TotalsPrerequisites!) {
  initiateAttributesCalculations(totalsPrerequisites: $totalsPrerequisites) {
    requestId
    only {
      segmentId
      queryId
      position
      benchmark
    }
    exclusive {
      segmentId
      queryId
      position
      benchmark
    }
    overlap {
      segmentId
      queryId
      position
      benchmark
    }
  }
}
    `;
export type InitiateAttributesCalculationsMutationFn = Apollo.MutationFunction<InitiateAttributesCalculationsMutation, InitiateAttributesCalculationsMutationVariables>;

/**
 * __useInitiateAttributesCalculationsMutation__
 *
 * To run a mutation, you first call `useInitiateAttributesCalculationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateAttributesCalculationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateAttributesCalculationsMutation, { data, loading, error }] = useInitiateAttributesCalculationsMutation({
 *   variables: {
 *      totalsPrerequisites: // value for 'totalsPrerequisites'
 *   },
 * });
 */
export function useInitiateAttributesCalculationsMutation(baseOptions?: Apollo.MutationHookOptions<InitiateAttributesCalculationsMutation, InitiateAttributesCalculationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateAttributesCalculationsMutation, InitiateAttributesCalculationsMutationVariables>(InitiateAttributesCalculationsDocument, options);
      }
export type InitiateAttributesCalculationsMutationHookResult = ReturnType<typeof useInitiateAttributesCalculationsMutation>;
export type InitiateAttributesCalculationsMutationResult = Apollo.MutationResult<InitiateAttributesCalculationsMutation>;
export type InitiateAttributesCalculationsMutationOptions = Apollo.BaseMutationOptions<InitiateAttributesCalculationsMutation, InitiateAttributesCalculationsMutationVariables>;
export const AudienceComparisonDetailedAttributesDocument = gql`
    query audienceComparisonDetailedAttributes($comparisonSegments: ComparisonSegments!) {
  audienceComparisonDetailedAttributes(comparisonSegments: $comparisonSegments) {
    defaultAttributes {
      attributeName
      attributeValue
      attributePercentage
    }
    underrepresented {
      attributeName
      attributeValue
    }
    overrepresented {
      attributeName
      attributeValue
    }
  }
}
    `;

/**
 * __useAudienceComparisonDetailedAttributesQuery__
 *
 * To run a query within a React component, call `useAudienceComparisonDetailedAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceComparisonDetailedAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceComparisonDetailedAttributesQuery({
 *   variables: {
 *      comparisonSegments: // value for 'comparisonSegments'
 *   },
 * });
 */
export function useAudienceComparisonDetailedAttributesQuery(baseOptions: Apollo.QueryHookOptions<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>(AudienceComparisonDetailedAttributesDocument, options);
      }
export function useAudienceComparisonDetailedAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>(AudienceComparisonDetailedAttributesDocument, options);
        }
export function useAudienceComparisonDetailedAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>(AudienceComparisonDetailedAttributesDocument, options);
        }
export type AudienceComparisonDetailedAttributesQueryHookResult = ReturnType<typeof useAudienceComparisonDetailedAttributesQuery>;
export type AudienceComparisonDetailedAttributesLazyQueryHookResult = ReturnType<typeof useAudienceComparisonDetailedAttributesLazyQuery>;
export type AudienceComparisonDetailedAttributesSuspenseQueryHookResult = ReturnType<typeof useAudienceComparisonDetailedAttributesSuspenseQuery>;
export type AudienceComparisonDetailedAttributesQueryResult = Apollo.QueryResult<AudienceComparisonDetailedAttributesQuery, AudienceComparisonDetailedAttributesQueryVariables>;
export const SubmitAudienceForActivationDocument = gql`
    mutation submitAudienceForActivation($audienceActivation: AudienceActivation!) {
  submitAudienceForActivation(audienceActivation: $audienceActivation) {
    authorEmail
    selectedAudiences
    submittedAudiences
  }
}
    `;
export type SubmitAudienceForActivationMutationFn = Apollo.MutationFunction<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>;

/**
 * __useSubmitAudienceForActivationMutation__
 *
 * To run a mutation, you first call `useSubmitAudienceForActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAudienceForActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAudienceForActivationMutation, { data, loading, error }] = useSubmitAudienceForActivationMutation({
 *   variables: {
 *      audienceActivation: // value for 'audienceActivation'
 *   },
 * });
 */
export function useSubmitAudienceForActivationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>(SubmitAudienceForActivationDocument, options);
      }
export type SubmitAudienceForActivationMutationHookResult = ReturnType<typeof useSubmitAudienceForActivationMutation>;
export type SubmitAudienceForActivationMutationResult = Apollo.MutationResult<SubmitAudienceForActivationMutation>;
export type SubmitAudienceForActivationMutationOptions = Apollo.BaseMutationOptions<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>;
export const FilteringAttributesDocument = gql`
    query filteringAttributes {
  filteringAttributes {
    name
    attributes {
      techNameId
      name
      default
    }
    attributeGroups {
      name
      attributes {
        techNameId
        name
        default
      }
    }
  }
}
    `;

/**
 * __useFilteringAttributesQuery__
 *
 * To run a query within a React component, call `useFilteringAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteringAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteringAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilteringAttributesQuery(baseOptions?: Apollo.QueryHookOptions<FilteringAttributesQuery, FilteringAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilteringAttributesQuery, FilteringAttributesQueryVariables>(FilteringAttributesDocument, options);
      }
export function useFilteringAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteringAttributesQuery, FilteringAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilteringAttributesQuery, FilteringAttributesQueryVariables>(FilteringAttributesDocument, options);
        }
export function useFilteringAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FilteringAttributesQuery, FilteringAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilteringAttributesQuery, FilteringAttributesQueryVariables>(FilteringAttributesDocument, options);
        }
export type FilteringAttributesQueryHookResult = ReturnType<typeof useFilteringAttributesQuery>;
export type FilteringAttributesLazyQueryHookResult = ReturnType<typeof useFilteringAttributesLazyQuery>;
export type FilteringAttributesSuspenseQueryHookResult = ReturnType<typeof useFilteringAttributesSuspenseQuery>;
export type FilteringAttributesQueryResult = Apollo.QueryResult<FilteringAttributesQuery, FilteringAttributesQueryVariables>;
export const BenchmarkAttributesDocument = gql`
    query benchmarkAttributes($benchmarkAttributes: BenchmarkAttributes!) {
  benchmarkAttributes(benchmarkAttributes: $benchmarkAttributes) {
    name
    attributeGroups {
      name
      attributes {
        name
        lowCoverageValue
        values {
          name
          fillRate
        }
      }
    }
    attributes {
      name
      lowCoverageValue
      values {
        name
        fillRate
      }
    }
  }
}
    `;

/**
 * __useBenchmarkAttributesQuery__
 *
 * To run a query within a React component, call `useBenchmarkAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkAttributesQuery({
 *   variables: {
 *      benchmarkAttributes: // value for 'benchmarkAttributes'
 *   },
 * });
 */
export function useBenchmarkAttributesQuery(baseOptions: Apollo.QueryHookOptions<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>(BenchmarkAttributesDocument, options);
      }
export function useBenchmarkAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>(BenchmarkAttributesDocument, options);
        }
export function useBenchmarkAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>(BenchmarkAttributesDocument, options);
        }
export type BenchmarkAttributesQueryHookResult = ReturnType<typeof useBenchmarkAttributesQuery>;
export type BenchmarkAttributesLazyQueryHookResult = ReturnType<typeof useBenchmarkAttributesLazyQuery>;
export type BenchmarkAttributesSuspenseQueryHookResult = ReturnType<typeof useBenchmarkAttributesSuspenseQuery>;
export type BenchmarkAttributesQueryResult = Apollo.QueryResult<BenchmarkAttributesQuery, BenchmarkAttributesQueryVariables>;
export const DivergentAttributesDocument = gql`
    query divergentAttributes($requestedDivergentAttributes: RequestedDivergentAttributes!) {
  divergentAttributes(requestedDivergentAttributes: $requestedDivergentAttributes) {
    categoryName
    attributeGroups {
      groupName
      attributes {
        attributeName
        lowCoverage {
          segmentId
          coverageValue
        }
        comparisonValues {
          valueName
          bars {
            segmentId
            divergentRatio
            attributeCount
          }
        }
      }
    }
    attributes {
      attributeName
      lowCoverage {
        segmentId
        coverageValue
      }
      comparisonValues {
        valueName
        bars {
          segmentId
          divergentRatio
          attributeCount
        }
      }
    }
  }
}
    `;

/**
 * __useDivergentAttributesQuery__
 *
 * To run a query within a React component, call `useDivergentAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivergentAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivergentAttributesQuery({
 *   variables: {
 *      requestedDivergentAttributes: // value for 'requestedDivergentAttributes'
 *   },
 * });
 */
export function useDivergentAttributesQuery(baseOptions: Apollo.QueryHookOptions<DivergentAttributesQuery, DivergentAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DivergentAttributesQuery, DivergentAttributesQueryVariables>(DivergentAttributesDocument, options);
      }
export function useDivergentAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DivergentAttributesQuery, DivergentAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DivergentAttributesQuery, DivergentAttributesQueryVariables>(DivergentAttributesDocument, options);
        }
export function useDivergentAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DivergentAttributesQuery, DivergentAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DivergentAttributesQuery, DivergentAttributesQueryVariables>(DivergentAttributesDocument, options);
        }
export type DivergentAttributesQueryHookResult = ReturnType<typeof useDivergentAttributesQuery>;
export type DivergentAttributesLazyQueryHookResult = ReturnType<typeof useDivergentAttributesLazyQuery>;
export type DivergentAttributesSuspenseQueryHookResult = ReturnType<typeof useDivergentAttributesSuspenseQuery>;
export type DivergentAttributesQueryResult = Apollo.QueryResult<DivergentAttributesQuery, DivergentAttributesQueryVariables>;