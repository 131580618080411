import React, { useRef, useState } from 'react';
import cn from 'classnames';
import {
  BenchmarkChartAttribute,
  BenchmarkChartAttributeGroup,
  DivergentChartAttribute,
  DivergentChartGroup,
  SegmentCalculationAnnotation,
} from '../../../../apollo/graphql-types';

import { Badge, Button, Col, Flex, Popover, Radio, Row, Select, Switch } from 'antd';
import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import { TextPopover } from '../../../../custom/TextPopover/TextPopover';
import { BarChartDiagram } from '../BarChartDiagram/BarChartDiagram';
import { generateBenchmarkData, optionsBenchmark } from '../BarChartDiagram/barChartConfig';
import { AUDIENCE_ID, AUDIENCE_TO_AUDIENCE_ID } from '../../../Blade/collapse/const';
import { useAudienceShortName } from '../../AudienceShortName';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/empty.svg';
import { BarChartOutlined, DownOutlined, ExportOutlined, InfoCircleFilled, PieChartOutlined, UpOutlined } from '@ant-design/icons';
import * as htmlToImage from 'html-to-image';

import './AttributeContent.scss';
import { BarChartComparisonContent } from './BarChartComparisonContent';
import { DoughnutDiagram } from '../DoughnutDiagram/DoughnutDiagram';
import { generateDoughnutData } from '../DoughnutDiagram/doughnutConfig';

interface IAttributeContentProps {
  benchmarkAttribute: BenchmarkChartAttribute | BenchmarkChartAttributeGroup;
  comparisonAttribute: DivergentChartAttribute | DivergentChartGroup | undefined;
  comparisonSegments: SegmentCalculationAnnotation[];
}

export const AttributeContent = ( {
  benchmarkAttribute,
  comparisonSegments,
  comparisonAttribute = {} as DivergentChartAttribute }: IAttributeContentProps,
) => {
  const audienceNames = useAudienceShortName();
  const titleRef = useRef<HTMLDivElement>( null );
  const comparisonLegendRef = useRef<HTMLDivElement>( null );
  const parentDiagramRef = useRef<HTMLDivElement>( null );

  const [showAllValues, setShowAllValues] = useState( false );
  const [chartType, setChartType] = useState<'bar' | 'doughnut'>( 'bar' );
  const [showDoughnutValues, setShowDoughnutValues] = useState( false );


  const isBenchmarkGroup = (
    attribute: BenchmarkChartAttribute | BenchmarkChartAttributeGroup
  ): attribute is BenchmarkChartAttributeGroup => ( attribute as BenchmarkChartAttributeGroup ).attributes !== undefined;

  const isComparisonGroup = (
    comparisonAttribute: DivergentChartAttribute | DivergentChartGroup
  ): comparisonAttribute is DivergentChartGroup => ( comparisonAttribute as DivergentChartGroup ).attributes !== undefined;

  const [selectedAttribute, setSelectedAttribute] = useState(
    isBenchmarkGroup( benchmarkAttribute ) ? benchmarkAttribute.attributes?.[0] : benchmarkAttribute
  );

  const comparisonSelectedAttribute = isComparisonGroup( comparisonAttribute )
    ? comparisonAttribute.attributes.find( ( attr ) => attr.attributeName === selectedAttribute?.name ) || {} as DivergentChartAttribute
    : comparisonAttribute || {} as DivergentChartAttribute;

  const benchmarkLowCoverage = selectedAttribute?.lowCoverageValue;
  const comparisonLowCoverage = comparisonSelectedAttribute.lowCoverage;
  const comparisonWithoutLowCoverage = comparisonSegments.filter( ( segment ) =>
    comparisonLowCoverage?.find( ( item ) => item.segmentId === segment.segmentId && item.coverageValue === 0 ) );
  const hasMore = ( selectedAttribute?.values.length || 0 ) > 6 && ( selectedAttribute?.lowCoverageValue !== 0
    || comparisonLowCoverage?.filter( ( attr ) => attr.coverageValue === 0 ).length !== comparisonSegments.length );

  const filter = ( node: HTMLElement ) => {
    const exclusionClasses = ['show-more', 'diagram-switcher', 'export-button', 'anticon-info-circle', 'show-all-labels'];
    return !exclusionClasses.some( ( classname ) => node.classList?.contains( classname ) );
  };

  const handleExportDiagram = () => {
    if ( !!parentDiagramRef.current ) {
      const attributeName = `${
        benchmarkAttribute.name}${selectedAttribute?.name !== benchmarkAttribute.name ? '_' + selectedAttribute?.name : ''
      }`;
      htmlToImage.toJpeg( parentDiagramRef.current, { backgroundColor: '#ffffff', filter: filter } )
        .then( function ( dataUrl ) {
          const link = document.createElement( 'a' );
          link.download = `CDS_${attributeName}.jpeg`;
          link.href = dataUrl;
          link.click();
        } );
    }
  };

  return <div
    key={selectedAttribute?.name}
    style={{
      '--values-height':  `${parentDiagramRef.current?.scrollHeight}px`,
      '--headers-height': `${( titleRef.current?.scrollHeight || 0 ) + ( comparisonLegendRef.current?.scrollHeight || 0 )}px`,
    } as React.CSSProperties
    }
    className={cn( 'attribute-content', {
      expanded:     showAllValues,
      'has-more':   hasMore,
    } )}
  >

    <div ref={parentDiagramRef} className='attribute-content-inner'>
      <Row gutter={24}>
        <Col span={benchmarkLowCoverage !== 0 ? 9 : 12} className='group-title' ref={titleRef}>
          <TitleWithCaption headingLevel={5} heading={benchmarkAttribute.name} />
          {
            isBenchmarkGroup( benchmarkAttribute ) && ( benchmarkAttribute.attributes?.length || 0 ) === 1
          && <TitleWithCaption headingLevel={5} heading={selectedAttribute?.name} />
          }
        </Col>
        {
          benchmarkLowCoverage !== 0 && (
            <Col span={3} className={cn( 'diagram-switcher', { group: isBenchmarkGroup( benchmarkAttribute ) } )}>
              <Radio.Group value={chartType} onChange={( e ) => setChartType( e.target.value )}>
                <Radio.Button value='bar'><BarChartOutlined /></Radio.Button>
                <Radio.Button value='doughnut'><PieChartOutlined /></Radio.Button>
              </Radio.Group>
            </Col>
          )
        }
        <Col span={12}>
          <Flex justify='end' align='end' gap={4} style={{ height: '100%', paddingBottom: 7 }}>
            {isBenchmarkGroup( benchmarkAttribute ) && ( benchmarkAttribute.attributes?.length || 0 ) > 1 && (
              <Select
                defaultValue={selectedAttribute?.name}
                className='select-attribute-to-show'
                style={{ width: 320 }}
                value={selectedAttribute?.name}
                onChange={( value ) => {
                  setShowAllValues( false );
                  setSelectedAttribute( benchmarkAttribute.attributes?.find( ( attr ) => attr.name === value ) );
                }}
                options={
                  benchmarkAttribute.attributes?.map( ( attr ) => ( {
                    value: attr.name,
                    label: attr.name,
                  } ) )
                }
              />
            ) }
            {
              ( benchmarkLowCoverage !== 0 || comparisonWithoutLowCoverage.length !== comparisonSegments.length )
              && <Button className='export-button' icon={<ExportOutlined />} onClick={handleExportDiagram}>Export</Button>
            }

          </Flex>
        </Col>

        <Col span={12}>
          <p className='bar-chart-subtitle'>Benchmark Audience Distribution</p>
          {
            selectedAttribute?.lowCoverageValue !== 0 && (
              <div className='legend-container'>
                <div className={cn( 'bar-chart-legend', { doughnut: chartType === 'doughnut' } )}>
                  <span className='legend-color'>
                    <TextPopover text={audienceNames[AUDIENCE_ID.BENCHMARK]} />
                    {( selectedAttribute?.lowCoverageValue || 0 ) > 0 && (
                      <Popover
                        arrow={false}
                        placement='bottom'
                        content={<><Badge status='error' /> Low data coverage: {selectedAttribute?.lowCoverageValue}%</>}
                      >
                        <Badge status='error' />
                      </Popover>
                    )}
                  </span>
                </div>
                { chartType === 'doughnut'
               && <div className='show-all-labels'>
                Show All Labels <Switch size='small' onChange={( value ) => setShowDoughnutValues( value )} value={showDoughnutValues} />
               </div>
                }
              </div>
            )
          }
        </Col>
        <Col span={12} ref={comparisonLegendRef}>
          <div className='bar-chart-subtitle'>Comparison Audience Index <Popover
            arrow={false}
            placement='top'
            rootClassName='bar-chart-subtitle-info'
            getPopupContainer={( node ) => node.parentElement!}
            overlayStyle={{ maxWidth: '100%', left: 12 }}
            content={(
              <>
                <p>Bars represent the relative difference (index) between the Comparison and Benchmark audiences.</p>
                <p>Solid bar point to the right:  indicate the difference is equal to 30% or more,
                suggesting a more significant and trustworthy <b>over representation</b> of such characteristic compared to benchmark.</p>
                <p>Solid bar point to the left: indicate the difference is equal to 30% or more,
                suggesting a more significant and trustworthy <b>under representation </b>
                of such characteristic compared to the benchmark.</p>
                <p>Hashed bar: indicate the difference is less than 30%, implying a less
                substantial and less reliable difference between the audiences</p>
              </>
            )}
          >
            <InfoCircleFilled style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
          </Popover></div>
          {
            comparisonWithoutLowCoverage.length !== comparisonSegments.length
             && (
               <>
                 <div className='bar-chart-legend'>
                   {
                     comparisonSegments.map( ( segment ) => {
                       if (
                         comparisonLowCoverage?.find( ( lowCoverage ) =>
                           lowCoverage.coverageValue === 0 && lowCoverage.segmentId === segment.segmentId )
                       ) {
                         return null;
                       }

                       const lowCoverage = comparisonLowCoverage
                         ?.find( ( lowCoverage ) => lowCoverage.coverageValue !== 0 && lowCoverage.segmentId === segment.segmentId );

                       return (
                         <span key={segment.position[0]} className={cn( 'legend-color', {
                           green:  AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === AUDIENCE_ID.COMPARISON,
                           purple: AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === AUDIENCE_ID.SECOND_COMPARISON,
                         } )}>
                           <TextPopover text={audienceNames[AUDIENCE_TO_AUDIENCE_ID[segment.position[0]]]} />
                           { lowCoverage && (
                             <Popover
                               arrow={false}
                               placement='bottom'
                               content={<><Badge status='error' /> Low data coverage: {lowCoverage.coverageValue}%</>}
                             >
                               <Badge status='error' />
                             </Popover>
                           )}
                         </span>
                       );
                     } )
                   }
                   {
                     selectedAttribute?.lowCoverageValue !== 0 && (
                       <>
                         <span className='legend-color gray' key={'index>30'}>{'|Index| >30%'}</span>
                         <span className='legend-color gray-dashed' key={'index<30'}>{'|Index| <30%'}</span>
                       </>
                     )
                   }
                 </div>
                 {
                   comparisonSegments.map( ( segment ) => {
                     if (
                       comparisonLowCoverage?.find( ( lowCoverage ) =>
                         lowCoverage.coverageValue === 0 && lowCoverage.segmentId === segment.segmentId )
                     ) {

                       return (
                         <div className='no-attribute' key={segment.position[0]}>
                           <TextPopover
                             text={`Attribute is not present for ${audienceNames[AUDIENCE_TO_AUDIENCE_ID[segment.position[0]]]}`}
                           />
                         </div>
                       );
                     }

                     return null;
                   } )
                 }
               </>
             )
          }
        </Col>

        {
          benchmarkLowCoverage === 0 && comparisonWithoutLowCoverage.length === comparisonSegments.length
            ? (
              <Col span={24}>
                <Flex className='empty'>
                  <EmptyIcon />
                  <p className='text'>Attribute is not present for selected audiences</p>
                </Flex>
              </Col>
            )
            : <>
              <Col span={12}>
                <div className='diagram-content-wrapper'>
                  {
                    selectedAttribute?.lowCoverageValue !== 0
                      ? chartType === 'bar'
                        ? (
                          <BarChartDiagram options={optionsBenchmark} data={generateBenchmarkData( selectedAttribute?.values || [] )} />
                        )
                        : (
                          <DoughnutDiagram
                            data={generateDoughnutData( selectedAttribute?.values || [] )}
                            showValues={showDoughnutValues}
                          />
                        )
                      : (
                        <Flex className='empty'>
                          <EmptyIcon />
                          <p className='text'>Attribute is not present for <TextPopover text={audienceNames[AUDIENCE_ID.BENCHMARK]} /></p>
                        </Flex>
                      )
                  }
                </div>
              </Col>

              <Col span={12}>
                <div className='diagram-content-wrapper'>
                  {
                    comparisonWithoutLowCoverage.length !== comparisonSegments.length
                      ? (
                        <BarChartComparisonContent
                          comparisonAttribute={comparisonSelectedAttribute}
                          benchmarkAttribute={selectedAttribute}
                          comparisonSegments={comparisonSegments}
                          noBenchmarkCoverage={selectedAttribute?.lowCoverageValue === 0}
                        />
                      )
                      : (
                        <Flex className='empty'>
                          <EmptyIcon />
                          <p className='text'>Attribute is not present for comparison audiences</p>
                        </Flex>
                      )
                  }
                </div>
              </Col>
              {
                hasMore && (
                  <div className='show-more'>
                    <Button onClick={() => setShowAllValues( !showAllValues )}>
                      {showAllValues
                        ? <>Show Less Values <UpOutlined /></>
                        : <>Show All Values <DownOutlined /></>}
                    </Button>
                  </div>
                )
              }
            </>
        }
      </Row>
    </div>















    {/* <Row
      ref={ref}
      gutter={24}
      className={cn( 'diagram-content', {
        expanded:     showAllValues,
        'has-more':   hasMore,
      } )}
      style={{ '--values-height': `${ref.current?.scrollHeight}px` } as React.CSSProperties}
    >
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }} ref={parentDiagramRef}>
        <Flex style={{ width: '100%' }}>
          <Col span={9} className='group-title'>
            <TitleWithCaption headingLevel={5} heading={benchmarkAttribute.name} />
            {
              isBenchmarkGroup( benchmarkAttribute ) && ( benchmarkAttribute.attributes?.length || 0 ) === 1
          && <TitleWithCaption headingLevel={5} heading={selectedAttribute?.name} />
            }
          </Col>
          {
            benchmarkLowCoverage !== 0 && (
              <Col span={3} className={cn( 'diagram-switcher', { group: isBenchmarkGroup( benchmarkAttribute ) } )}>
                <Radio.Group value={chartType} onChange={( e ) => setChartType( e.target.value )}>
                  <Radio.Button value='bar'><BarChartOutlined /></Radio.Button>
                  <Radio.Button value='doughnut'><PieChartOutlined /></Radio.Button>
                </Radio.Group>
              </Col>
            )
          }
          <Col span={12}>
            <Flex justify='end' align='end' gap={4} style={{ height: '100%', paddingBottom: 7 }}>
              {isBenchmarkGroup( benchmarkAttribute ) && ( benchmarkAttribute.attributes?.length || 0 ) > 1 && (
                <Select
                  defaultValue={selectedAttribute?.name}
                  className='select-attribute-to-show'
                  style={{ width: 320 }}
                  value={selectedAttribute?.name}
                  onChange={( value ) => {
                    setShowAllValues( false );
                    setSelectedAttribute( benchmarkAttribute.attributes?.find( ( attr ) => attr.name === value ) );
                  }}
                  options={
                    benchmarkAttribute.attributes?.map( ( attr ) => ( {
                      value: attr.name,
                      label: attr.name,
                    } ) )
                  }
                />
              ) }
              <Button className='export-button' icon={<ExportOutlined />} onClick={handleExportDiagram}>Export</Button>
            </Flex>
          </Col>
        </Flex>
        <Flex style={{ width: '100%' }}>
          <Col span={12}>
            <p className='bar-chart-subtitle'>Benchmark Audience Distribution</p>
            {
              selectedAttribute?.lowCoverageValue !== 0 && (
                <div className='legend-container'>
                  <div className={cn( 'bar-chart-legend', { doughnut: chartType === 'doughnut' } )}>
                    <span className='legend-color'>
                      <TextPopover text={audienceNames[AUDIENCE_ID.BENCHMARK]} />
                      {( selectedAttribute?.lowCoverageValue || 0 ) > 0 && (
                        <Popover
                          arrow={false}
                          placement='bottom'
                          content={<><Badge status='error' /> Low data coverage: {selectedAttribute?.lowCoverageValue}%</>}
                        >
                          <Badge status='error' />
                        </Popover>
                      )}
                    </span>
                  </div>
                  { chartType === 'doughnut'
               && <div className='show-all-labels'>
                Show All Labels <Switch size='small' onChange={( value ) => setShowDoughnutValues( value )} value={showDoughnutValues} />
               </div>
                  }
                </div>
              )
            }
          </Col>
          <Col span={12}>
            <div className='bar-chart-subtitle'>Comparison Audience Index <Popover
              arrow={false}
              placement='top'
              rootClassName='bar-chart-subtitle-info'
              getPopupContainer={( node ) => node.parentElement!}
              overlayStyle={{ maxWidth: '100%', left: 12 }}
              content={(
                <>
                  <p>Bars represent the relative difference (index) between the Comparison and Benchmark audiences.</p>
                  <p>Solid bar point to the right:  indicate the difference is equal to 30% or more,
                suggesting a more significant and trustworthy <b>over representation</b> of such characteristic compared to benchmark.</p>
                  <p>Solid bar point to the left: indicate the difference is equal to 30% or more,
                suggesting a more significant and trustworthy <b>under representation </b>
                of such characteristic compared to the benchmark.</p>
                  <p>Hashed bar: indicate the difference is less than 30%, implying a less
                substantial and less reliable difference between the audiences</p>
                </>
              )}
            >
              <InfoCircleFilled style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
            </Popover></div>
            {
              comparisonWithoutLowCoverage.length !== comparisonSegments.length
             && (
               <>
                 <div className='bar-chart-legend'>
                   {
                     comparisonSegments.map( ( segment ) => {
                       if (
                         comparisonLowCoverage?.find( ( lowCoverage ) =>
                           lowCoverage.coverageValue === 0 && lowCoverage.segmentId === segment.segmentId )
                       ) {
                         return null;
                       }

                       const lowCoverage = comparisonLowCoverage
                         ?.find( ( lowCoverage ) => lowCoverage.coverageValue !== 0 && lowCoverage.segmentId === segment.segmentId );

                       return (
                         <span key={segment.position[0]} className={cn( 'legend-color', {
                           green:  AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === AUDIENCE_ID.COMPARISON,
                           purple: AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === AUDIENCE_ID.SECOND_COMPARISON,
                         } )}>
                           <TextPopover text={audienceNames[AUDIENCE_TO_AUDIENCE_ID[segment.position[0]]]} />
                           { lowCoverage && (
                             <Popover
                               arrow={false}
                               placement='bottom'
                               content={<><Badge status='error' /> Low data coverage: {lowCoverage.coverageValue}%</>}
                             >
                               <Badge status='error' />
                             </Popover>
                           )}
                         </span>
                       );
                     } )
                   }
                   {
                     selectedAttribute?.lowCoverageValue !== 0 && (
                       <>
                         <span className='legend-color gray' key={'index>30'}>{'|Index| >30%'}</span>
                         <span className='legend-color gray-dashed' key={'index<30'}>{'|Index| <30%'}</span>
                       </>
                     )
                   }
                 </div>
                 {
                   comparisonSegments.map( ( segment ) => {
                     if (
                       comparisonLowCoverage?.find( ( lowCoverage ) =>
                         lowCoverage.coverageValue === 0 && lowCoverage.segmentId === segment.segmentId )
                     ) {

                       return (
                         <div className='no-attribute' key={segment.position[0]}>
                           <TextPopover
                             text={`Attribute is not present for ${audienceNames[AUDIENCE_TO_AUDIENCE_ID[segment.position[0]]]}`}
                           />
                         </div>
                       );
                     }

                     return null;
                   } )
                 }
               </>
             )
            }
          </Col>
        </Flex>
        {
          benchmarkLowCoverage === 0 && comparisonWithoutLowCoverage.length === comparisonSegments.length
            ? (
              <Col span={24}>
                <Flex className='empty'>
                  <EmptyIcon />
                  <p className='text'>Attribute is not present for selected audiences</p>
                </Flex>
              </Col>
            )
            : <>
              <Col span={12}>
                <div className='diagram-content-wrapper'>
                  {
                    selectedAttribute?.lowCoverageValue !== 0
                      ? chartType === 'bar'
                        ? (
                          <BarChartDiagram options={optionsBenchmark} data={generateBenchmarkData( selectedAttribute?.values || [] )} />
                        )
                        : (
                          <DoughnutDiagram
                            data={generateDoughnutData( selectedAttribute?.values || [] )}
                            showValues={showDoughnutValues}
                          />
                        )
                      : (
                        <Flex className='empty'>
                          <EmptyIcon />
                          <p className='text'>Attribute is not present for <TextPopover text={audienceNames[AUDIENCE_ID.BENCHMARK]} /></p>
                        </Flex>
                      )
                  }
                </div>
              </Col>

              <Col span={12}>
                <div className='diagram-content-wrapper'>
                  {
                    comparisonWithoutLowCoverage.length !== comparisonSegments.length
                      ? (
                        <BarChartComparisonContent
                          comparisonAttribute={comparisonSelectedAttribute}
                          benchmarkAttribute={selectedAttribute}
                          comparisonSegments={comparisonSegments}
                          noBenchmarkCoverage={selectedAttribute?.lowCoverageValue === 0}
                        />
                      )
                      : (
                        <Flex className='empty'>
                          <EmptyIcon />
                          <p className='text'>Attribute is not present for comparison audiences</p>
                        </Flex>
                      )
                  }
                </div>
              </Col>
              {
                hasMore && (
                  <div className='show-more'>
                    <Button onClick={() => setShowAllValues( !showAllValues )}>
                      {showAllValues
                        ? <>Show Less Values <UpOutlined /></>
                        : <>Show All Values <DownOutlined /></>}
                    </Button>
                  </div>
                )
              }
            </>
        }
      </div>
    </Row> */}
  </div>;
};
