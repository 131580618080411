import { createAction } from '@reduxjs/toolkit';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { IAudienceComparisonItem, IAudienceComparisonState, IAudienceToCompare } from './typings';
import { AudienceParameters } from '../../apollo/graphql-types';

export const setPreBuiltAudienceIds = createAction<[AUDIENCE_ID, number[] | undefined]>( 'audienceComparison/setPreBuiltAudienceIds' );
export const setAudienceParameters = createAction<[
  AUDIENCE_ID,
  Partial<AudienceParameters> | undefined]
>( 'audienceComparison/setAudienceParameters' );
export const setAudienceComparisonStats = createAction
<IAudienceComparisonState['stats']>( 'audienceComparison/setAudienceComparisonStats' );
export const setChosenAudience = createAction<IAudienceComparisonItem | null>( 'audienceComparison/setChosenAudience' );
export const setAudienceComparisonIsLoading = createAction<boolean>( 'audienceComparison/setAudienceComparisonIsLoading' );
export const setAudienceComparisonIsAborting = createAction<boolean>( 'audienceComparison/setAudienceComparisonIsAborting' );
export const resetAudienceComparison = createAction( 'audienceComparison/resetAudienceComparison' );
export const setHoveredId = createAction<string | null>( 'audienceComparison/setHoveredId' );
export const setAudiencesToCompare = createAction<Record<AUDIENCE_ID, IAudienceToCompare>>( 'audienceComparison/setAudiencesToCompare' );
export const setAppliedAudiencesToCompare = createAction
<Record<AUDIENCE_ID, IAudienceToCompare>>( 'audienceComparison/setAppliedAudiencesToCompare' );
