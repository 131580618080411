import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.scss';
import App from './App';
import { audienceClient } from './apollo/apollo';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) as HTMLElement );
root.render(
  <ApolloProvider client={audienceClient}>
    <App />
  </ApolloProvider>
);

reportWebVitals();
