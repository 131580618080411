import { createSlice } from '@reduxjs/toolkit';
import { ICampaignSliceInterface } from './campaignSlice';
import { AUDIENCE_ID } from '../components/Blade/collapse/const';

interface IWizardTagInterface {
  name: string;
  value: string[];
}
export interface ICommonWizardInterface {
  tag: IWizardTagInterface[];
  isFinished: boolean;
  isExpand: boolean;
  isVisible: boolean;
  isLoading: boolean;
  error: boolean;
}

export interface ISourceWizardInterface extends ICommonWizardInterface {
  value: string;
}

export interface IMarsBrandsWizardInterface extends ICommonWizardInterface {
  hasD2C: boolean;
  hasMarketingCampaigns: boolean;
  isSelectedOnlyD2C: boolean;
}
export interface IAdditionalParametersWizardInterface extends ICommonWizardInterface {
  value: string;
  campaigns: ICampaignSliceInterface[];
}


export interface ITabState {
  source: ISourceWizardInterface;
  prebuiltAudience: ICommonWizardInterface;
  marsBrands: IMarsBrandsWizardInterface;
  origin: ICommonWizardInterface;
  additionalParameters: IAdditionalParametersWizardInterface;
  competitors: ICommonWizardInterface;
}

export interface IWizardState {
  isOpen: boolean;
  wizards: Record<AUDIENCE_ID, ITabState>;
}

const commonWizardState = {
  tag:        [],
  isFinished: false,
  isExpand:   false,
  isVisible:  false,
  isLoading:  false,
  error:      false,
};

const defaultSourceState: ISourceWizardInterface = {
  ...commonWizardState,
  isExpand: true,
  value:    '',
};
const defaultPrebuiltAudienceState: ICommonWizardInterface = {
  ...commonWizardState,
};
const defaultMarsBrandsState: IMarsBrandsWizardInterface = {
  ...commonWizardState,
  hasD2C:                false,
  hasMarketingCampaigns: false,
  isSelectedOnlyD2C:     false,
};
const defaultAdditionalParametersState: IAdditionalParametersWizardInterface = {
  ...commonWizardState,
  value:      '',
  campaigns:  [],
};

const initialTabState: ITabState = {
  source:               defaultSourceState,
  prebuiltAudience:     defaultPrebuiltAudienceState,
  marsBrands:           defaultMarsBrandsState,
  competitors:          commonWizardState,
  origin:               commonWizardState,
  additionalParameters: defaultAdditionalParametersState,
};

const initialState: IWizardState = {
  isOpen:  false,
  wizards: {
    [AUDIENCE_ID.BENCHMARK]:         initialTabState,
    [AUDIENCE_ID.COMPARISON]:        initialTabState,
    [AUDIENCE_ID.SECOND_COMPARISON]: initialTabState,
  },
};

export const wizardSlice = createSlice( {
  name:     'wizard',
  initialState,
  reducers: {
    setWizardIsOpen: ( state, action ) => {
      state.isOpen = action.payload;
    },
    collapseAllWizards: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source.isExpand = false;
      state.wizards[activeTab].prebuiltAudience.isExpand = false;
      state.wizards[activeTab].competitors.isExpand = false;
      state.wizards[activeTab].marsBrands.isExpand = false;
      state.wizards[activeTab].origin.isExpand = false;
      state.wizards[activeTab].additionalParameters.isExpand = false;
    },
    resetAllWizards: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source = initialState.wizards[activeTab].source;
      state.wizards[activeTab].source = {
        ...state.wizards[activeTab].source,
        isExpand: true,
      };
      state.wizards[activeTab].prebuiltAudience = initialState.wizards[activeTab].prebuiltAudience;
      state.wizards[activeTab].competitors = initialState.wizards[activeTab].competitors;
      state.wizards[activeTab].marsBrands = initialState.wizards[activeTab].marsBrands;
      state.wizards[activeTab].origin = initialState.wizards[activeTab].origin;
      state.wizards[activeTab].additionalParameters = initialState.wizards[activeTab].additionalParameters;
    },
    resetSourceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source = initialState.wizards[activeTab].source;
    },
    resetPrebuiltAudienceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].prebuiltAudience = initialState.wizards[activeTab].prebuiltAudience;
    },
    resetMarsBrandsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].marsBrands = initialState.wizards[activeTab].marsBrands;
    },
    resetOriginWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].origin = initialState.wizards[activeTab].origin;
    },
    resetAdditionalParametersWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].additionalParameters = initialState.wizards[activeTab].additionalParameters;
    },
    resetCompetitorsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].competitors = initialState.wizards[activeTab].competitors;
    },

    setSourceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source = { ...state.wizards[activeTab].source, ...action.payload };
    },
    setPrebuiltAudienceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].prebuiltAudience = { ...state.wizards[activeTab].prebuiltAudience, ...action.payload };
    },
    setMarsBrandsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].marsBrands = { ...state.wizards[activeTab].marsBrands, ...action.payload };
    },
    setOriginWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].origin = { ...state.wizards[activeTab].origin, ...action.payload };
    },
    setAdditionalParametersWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].additionalParameters = { ...state.wizards[activeTab].additionalParameters, ...action.payload };
    },
    setCompetitorsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].competitors = { ...state.wizards[activeTab].competitors, ...action.payload };
    },
    resetWizard: ( state ) => {
      state = initialState;
      return state;
    },
  },
} );

// Action creators are generated for each case reducer function
export const {
  setWizardIsOpen,
  collapseAllWizards,
  resetAllWizards,
  resetSourceWizard,
  resetPrebuiltAudienceWizard,
  resetMarsBrandsWizard,
  resetOriginWizard,
  resetAdditionalParametersWizard,
  resetCompetitorsWizard,
  setSourceWizard,
  setPrebuiltAudienceWizard,
  setMarsBrandsWizard,
  setOriginWizard,
  setAdditionalParametersWizard,
  setCompetitorsWizard,
  resetWizard,
} = wizardSlice.actions;
export default wizardSlice.reducer;
