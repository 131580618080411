import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Flex, Spin, message } from 'antd';
import { CloseOutlined, CreditCardOutlined, EllipsisOutlined, GlobalOutlined, PoweroffOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { NetworkStatus } from '@apollo/client';

import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import {
  selectAudienceComparisonTypeBySegmentId,
  selectAudienceComparisonIsBenchmarkBySegmentId,
  selectChosenAudience,
} from '../../../../store/audienceComparison/selectors';
import { setChosenAudience } from '../../../../store/audienceComparison/actions';
import { AUDIENCE_TO_AUDIENCE_ID, options } from '../../../Blade/collapse/const';
import { ReactComponent as GenderSvg } from '../../../../assets/icons/gender.svg';
import { ReactComponent as CakeSvg } from '../../../../assets/icons/cake.svg';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/empty.svg';

import './AttributesTable.scss';
import { useAudienceShortName } from '../../AudienceShortName';
import {
  InitiateAttributesCalculationsMutation,
  useAudienceComparisonDetailedAttributesLazyQuery,
  useSubmitAudienceForActivationMutation,
} from '../../../../apollo/graphql-types';
import CustomTag from '../../../../custom/CustomTag/CustomTag';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';

const deatailedIcons: Record<string, any> = {
  'generations':   <Icon component={CakeSvg} />,
  'gender':        <Icon component={GenderSvg} />,
  'income':        <CreditCardOutlined />,
  'top_30_metros': <GlobalOutlined />,
};

interface IAttributesTableProps {
  requestId?: string;
  dataInitiateAttributes?: InitiateAttributesCalculationsMutation | null;
  loadedQueries: string[];
  setLoadedQueries: React.Dispatch<React.SetStateAction<string[]>>;
}

export const AttributesTable = ( { requestId = '', dataInitiateAttributes, loadedQueries, setLoadedQueries }: IAttributesTableProps ) => {
  const dispatch = useDispatch();

  const [shouldRender, setShouldRender] = useState( false );
  const [animation, setAnimation] = useState<'show' | 'hide'>( 'hide' );
  const [error, setError] = useState( false );
  const [retry, setRetry] = useState( false );

  const chosenAudience = useSelector( selectChosenAudience );
  const audienceComparisonTypeBySegmentId = useSelector( selectAudienceComparisonTypeBySegmentId( chosenAudience?.segmentId || null ) );
  const isBenchmark = useSelector( selectAudienceComparisonIsBenchmarkBySegmentId( chosenAudience?.segmentId || null ) );
  const submitError = <>Submission failed. Please retry or contact
    <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
  if the issue continues.
  </>;

  const audienceNames = useAudienceShortName();

  const [messageApi, contextHolder] = message.useMessage();
  const [detailedAttributes, { data, loading }] = useAudienceComparisonDetailedAttributesLazyQuery();

  const [submitForActivation] = useSubmitAudienceForActivationMutation( options() );

  const errorMessage = () => {
    messageApi.open( {
      type:    'error',
      content: submitError,
    } );
  };

  const successMessage = () => {
    messageApi.open( {
      type:    'success',
      content: 'Submission received. Xi Chen will contact you for the next steps. Thank you for your patience.',
    } );
  };

  const abortController = useRef( new AbortController() );

  const abort = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getAudiencesEmailNames = () => {
    const audienceType = typeof audienceComparisonTypeBySegmentId === 'string'
      ? audienceComparisonTypeBySegmentId.charAt( 0 ).toUpperCase() + audienceComparisonTypeBySegmentId.slice( 1 )
      : '';

    const audienceEmailNames = chosenAudience?.position.map( ( position ) =>
      audienceNames[AUDIENCE_TO_AUDIENCE_ID[position]] ).join( ' VS ' );

    return audienceType + ' ' + audienceEmailNames;
  };

  const items = [
    {
      label:   'Submit for Activation',
      icon:    <PoweroffOutlined />,
      key:     '1',
      onClick: () => {
        if ( !requestId || !chosenAudience?.segmentId ) {
          errorMessage();
          return;
        }

        submitForActivation( { variables: {
          audienceActivation: {
            requestId,
            segmentId:           chosenAudience.segmentId,
            submittedAudiences:  getAudiencesEmailNames(),
            selectedAudiences:   Object.values( audienceNames ).join( ' VS ' ),
          },
        } } )
          .then( ( { data, errors } ) => {
            if ( !errors && !!data?.submitAudienceForActivation.authorEmail ) {
              successMessage();
            } else {
              errorMessage();
            }
          } )
          .catch( () => errorMessage() );
      },
    },
  ];

  useEffect( () => {
    if ( !!chosenAudience ) {
      setShouldRender( true );
      setAnimation( 'show' );
    } else {
      setAnimation( 'hide' );
    }
  }, [chosenAudience] );

  const onAnimationEnd = () => {
    if ( animation === 'hide' ) setShouldRender( false );
  };

  const loadData = async () => {
    try {
      if ( !dataInitiateAttributes ) return;

      let benchmark = null;
      const benchmarkSegmentId = dataInitiateAttributes?.initiateAttributesCalculations.only
        .find( ( item ) => item.benchmark )?.segmentId || '';
      const benchmarkQuerytId = dataInitiateAttributes?.initiateAttributesCalculations.only
        .find( ( item ) => item.benchmark )?.queryId || '';

      const segments = [
        ...dataInitiateAttributes?.initiateAttributesCalculations.only,
        ...dataInitiateAttributes?.initiateAttributesCalculations.exclusive,
        ...dataInitiateAttributes?.initiateAttributesCalculations.overlap,
      ];
      const comparisonQueryId = segments.find( ( item ) => item.segmentId === chosenAudience?.segmentId )?.queryId || '';
      const comparison = {
        queryId:   comparisonQueryId,
        segmentId: chosenAudience?.segmentId || '',
      };

      if ( chosenAudience?.segmentId !== benchmarkSegmentId ) {
        benchmark = {
          queryId:   benchmarkQuerytId,
          segmentId: benchmarkSegmentId,
        };
      }

      const res = await detailedAttributes( {
        ...options(),
        variables: {
          comparisonSegments: {
            requestId,
            benchmark,
            comparison,
          },
        },
        context:     {
          ...options().context,
          fetchOptions: {
            signal: abortController.current.signal,
          },
        },
      } );

      if ( res.networkStatus !== NetworkStatus.error ) {
        if ( !!res.data ) {
          const loadedQueryIds: string[] = [];

          if ( benchmark !== null && !loadedQueries?.includes( benchmarkQuerytId ) ) {
            loadedQueryIds.push( benchmarkQuerytId );
          }

          if ( !loadedQueries?.includes( comparison?.queryId ) ) {
            loadedQueryIds.push( comparison?.queryId );
          }

          if ( !!loadedQueryIds.length ) {
            setLoadedQueries( [...loadedQueries, ...loadedQueryIds] );
          }
        }
      } else {
        if ( !!res.error?.message && res.error?.message !== 'The user aborted a request.' ) {
          setError( true );
          console.error(res.error?.message); //eslint-disable-line
        }
      }
    } catch ( errorMessage ) {
      setError( true );
      console.error(errorMessage); //eslint-disable-line
    }
  };

  useEffect( () => {
    if ( !!chosenAudience?.segmentId && !!dataInitiateAttributes ) {
      abort();
      setError( false );
      setRetry( false );

      if( !!chosenAudience?.membersNumber ) {
        loadData();
      }
    }
  }, [chosenAudience, dataInitiateAttributes] );

  useEffect( () => {
    if ( retry ) {
      setError( false );
      setRetry( false );

      loadData();
    }
  }, [retry] );

  return shouldRender
    ? (
      <div className={`comparison_attributes ${animation}`} onAnimationEnd={onAnimationEnd}>
        {contextHolder}
        <div className='comparison_attributes-title'>
          <TitleWithCaption
            headingLevel={5}
            heading='Attributes Summary Analysis'
          />
          <Flex gap={8}>
            {
              !!chosenAudience?.membersNumber && !error && <Dropdown
                menu={{
                  items,
                }}
                getPopupContainer={( node ) => node.parentElement!}
                overlayClassName='submit-dropdown'
                trigger={['click']}
              >

                <Button type='text' icon={<EllipsisOutlined />} />
              </Dropdown>
            }

            <Button type='text' icon={<CloseOutlined />} onClick={() => dispatch( setChosenAudience( null ) )} />
          </Flex>
        </div>
        <div className={`comparison_attributes-content ${isBenchmark ? 'benchmark' : ''}`}>
          {
            !chosenAudience?.membersNumber
              ? (
                <Flex className='empty'>
                  <EmptyIcon />
                  <p className='text'>No attributes to show</p>
                </Flex>
              )
              : error
                ? (
                  <ErrorRetryCircle
                    title='Error'
                    description={'Something went wrong. Couldn\'t load the data. Please try again.'}
                    onClick={() => {
                      setRetry( true );
                    }}
                  />
                )
                :loading || !dataInitiateAttributes
                  ? (
                    <Flex vertical align='center' justify='center' className='comparison_attributes-spinner'>
                      <Spin />
                    </Flex>
                  )
                  : (
                    <>
                      <Flex className='attributes'>
                        {data?.audienceComparisonDetailedAttributes?.defaultAttributes
                          .map( ( attribute: any ) => (
                            <div className='attribute' key={chosenAudience?.segmentId + attribute.attributeName + attribute.attributeValue}>
                              <div className='icon'>
                                {deatailedIcons[attribute.attributeName.toLowerCase().split( ' ' ).join( '_' )]}
                              </div>
                              <div className='info'>
                                <span>{attribute.attributeName}</span>
                                <span><b>{attribute.attributeValue} ({attribute.attributePercentage}%)</b></span>
                              </div>
                            </div> ) )}
                      </Flex>
                      { !isBenchmark && <Flex className='attributes-represented'>
                        <b className='represented-title'>Overrepresented Attributes:</b>
                        {!!data?.audienceComparisonDetailedAttributes?.overrepresented?.length
                          ? data?.audienceComparisonDetailedAttributes?.overrepresented
                            .map( ( attribute: any ) =>
                              <CustomTag
                                maxPopoverWidth={640}
                                maxWidth={312}
                                tagValue={`${attribute.attributeName}: ${attribute.attributeValue}`}
                                key={chosenAudience?.segmentId + attribute.attributeName + attribute.attributeValue} bordered/>
                            )

                          : <span className='no-text'>No significant attributes to show</span>
                        }
                      </Flex>
                      }

                      {!isBenchmark && <Flex className='attributes-represented'>
                        <b className='represented-title'>Underrepresented Attributes:</b>
                        {!!data?.audienceComparisonDetailedAttributes?.underrepresented?.length
                          ? data?.audienceComparisonDetailedAttributes?.underrepresented
                            .map( ( attribute: any ) =>
                              <CustomTag
                                maxPopoverWidth={640}
                                maxWidth={312}
                                tagValue={`${attribute.attributeName}: ${attribute.attributeValue}`}
                                key={chosenAudience?.segmentId + attribute.attributeName + attribute.attributeValue} bordered/> )
                          : <span className='no-text'>No significant attributes to show</span>
                        }
                      </Flex>}
                    </>
                  )
          }
        </div>
      </div>
    )
    : null;
};
