import React, { memo, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ChartData,
  ArcElement,
  DoughnutController,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import './DoughnutDiagram.scss';
import { optionsDoughnut } from '../DoughnutDiagram/doughnutConfig';
import cn from 'classnames';


ChartJS.register(
  ArcElement,
  ChartDataLabels,
  DoughnutController
);

interface IDoughnutDiagramProps {
  data: ChartData<'doughnut'>;
  showValues: boolean;
}

export const DoughnutDiagram = ( { data, showValues }: IDoughnutDiagramProps ) => {
  const [shouldRedraw, setShouldRedraw] = useState( false );

  useEffect( () => {
    if ( !shouldRedraw ) {
      setShouldRedraw( true );

      setTimeout( () => {
        setShouldRedraw( false );
      }, 1 );
    }
  }, [showValues] );

  return <div className={cn( 'doughnut-diagram', { 'show-values': showValues } )}>
    <Doughnut options={optionsDoughnut( showValues )} data={data} redraw={shouldRedraw}/>
  </div>;
};
