import { Space, Spin } from 'antd';
import React from 'react';

const BladeLoader: React.FC = () => (
  <Space className='loader' size='middle'>
    <Spin />
  </Space>
);

export default BladeLoader;
