import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Divider, Flex } from 'antd';

import {
  selectAudienceComparisonBySegmentId,
  selectAudienceComparisonCount,
  selectAudienceComparisonTypeBySegmentId,
  selectAudienceOnlyMembersNumberByTabName,
  selectChosenAudience,
} from '../../../store/audienceComparison/selectors';
import { AUDIENCE_ID, TAB_HEADERS } from '../../Blade/collapse/const';

import { positionToAudienceName } from '../const';
import { TextPopover } from '../../../custom/TextPopover/TextPopover';

import { useAudienceShortName } from '../AudienceShortName';
import './Overview.scss';

export const Overview = () => {
  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const benchmarkOnlyMembersNumber = useSelector( selectAudienceOnlyMembersNumberByTabName( 'audienceA' ) );
  const comparisonkOnlyMembersNumber = useSelector( selectAudienceOnlyMembersNumberByTabName( 'audienceB' ) );
  const secondComparisonOnlyMembersNumber = useSelector( selectAudienceOnlyMembersNumberByTabName( 'audienceC' ) );
  const audienceNames = useAudienceShortName();
  const chosenAudience = useSelector( selectChosenAudience );
  const audienceComparisonTypeBySegmentId = useSelector( selectAudienceComparisonTypeBySegmentId( chosenAudience?.segmentId || null ) );
  const audienceComparisonBySegmentId = useSelector( selectAudienceComparisonBySegmentId( chosenAudience?.segmentId || null ) );

  const getGeneralName = () => {
    const position = audienceComparisonBySegmentId?.position;

    const generalName = position?.map( ( pos ) => TAB_HEADERS[positionToAudienceName[pos]] ).join( ' + ' );

    return generalName;
  };

  return (
    <Flex className={cn( `overview-info count-${audienceComparisonCount}`, { chosen: !!chosenAudience?.segmentId } )}>
      { !!chosenAudience?.segmentId
        ? ( <>
          <div>
            <span className='overview-info-name type'>{audienceComparisonTypeBySegmentId}:</span>
            <span className={`overview-info-${!chosenAudience.segmentId ? 'count' : 'name-second'}`}>{getGeneralName()}</span>
          </div>
          <Divider type='vertical' />
          <div>
            <span className='overview-info-name'>Audience Size:</span>
            <span className='overview-info-count'>{audienceComparisonBySegmentId?.membersNumber.toLocaleString( 'en-US' )}</span>
          </div>
        </> )
        : ( <>
          <div>
            <span className='overview-info-name'><TextPopover text={`${audienceNames[AUDIENCE_ID.BENCHMARK]}:`} maxWidth={640} /></span>
            <span className={`overview-info-${!chosenAudience?.segmentId ? 'count' : 'name-second'}`}>{benchmarkOnlyMembersNumber}</span>
          </div>
          <Divider type='vertical' />
          <div>
            <span className='overview-info-name'><TextPopover text={`${audienceNames[AUDIENCE_ID.COMPARISON]}:`} maxWidth={640} /></span>
            <span className='overview-info-count'>{comparisonkOnlyMembersNumber}</span>
          </div>
        </> )
      }

      {audienceComparisonCount === 3 && !chosenAudience?.segmentId
        ? (
          <>
            <Divider type='vertical' />
            <div>
              <span className='overview-info-name'>
                <TextPopover text={`${audienceNames[AUDIENCE_ID.SECOND_COMPARISON]}:`} maxWidth={640} />
              </span>
              <span className='overview-info-count'>{secondComparisonOnlyMembersNumber}</span>
            </div>
          </>
        )
        : null}
    </Flex>
  );
};
