import { createSlice } from '@reduxjs/toolkit';
import { AUDIENCE_ID } from '../components/Blade/collapse/const';

export interface ITabSlice {
  activeTab: AUDIENCE_ID;
  tabsList: Record<AUDIENCE_ID, ITabItem>;
}

export interface ITabItem {
  isFinished: boolean;
  isMars: boolean;
  isAudiences: boolean;
  isCompetitors: boolean;
  isVisible: boolean;
}

const initialState: ITabSlice = {
  activeTab: AUDIENCE_ID.BENCHMARK,
  tabsList:  {
    [AUDIENCE_ID.BENCHMARK]:         {} as ITabItem,
    [AUDIENCE_ID.COMPARISON]:        {} as ITabItem,
    [AUDIENCE_ID.SECOND_COMPARISON]: {} as ITabItem,
  },
};

export const tabSlice = createSlice( {
  name:     'tabs',
  initialState,
  reducers: {
    resetTabs: ( state ) => {
      state = initialState;
      return state;
    },
    setTabs: ( state, action ) => {
      state = action.payload;
      return state;
    },
    setActiveTab: ( state, action ) => {
      state.activeTab = action.payload;
    },
    setSpecificTab: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.tabsList[activeTab] = { ...state.tabsList[activeTab], ...action.payload };
    },
  },
} );

export const { setTabs, setActiveTab, setSpecificTab, resetTabs } = tabSlice.actions;
export default tabSlice.reducer;
