import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse, Divider, Radio, RadioChangeEvent, Space } from 'antd';
import { CheckOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import { ICampaignSliceInterface, selectMarketingCampaigns } from '../../../../store/campaignSlice';
import { setSpecificTab } from '../../../../store/tabSlice';
import { collapseAllWizards, setAdditionalParametersWizard } from '../../../../store/wizardSlice';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import { AUDIENCE_ID, BUTTON_BORDER_COLOR, options } from '../const';
import { ICollapseProps } from '../types';
import {
  D2C_WEBSITE_DATA,
  MARKETING_CAMPAIGNS,
  ENGAGEMENT,
  COLLECTED_THROUGH,
  engagementOptions,
  engagementToTagName,
} from './const';
import { selectWizardAdditionalParametersByTabName, selectWizardMarsBrandsByTabName } from '../../WizardTabs/selectors';

import './AdditionalParametersCollapse.scss';
import {
  selectAudiencesToCompare,
  selectCampaignsByAudienceId,
  selectD2CByAudienceId,
  selectEngagementByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
} from '../../../../store/audienceComparison/selectors';
import { EngagementStatus, useBrandMarketingCampaignsLazyQuery, useGetBrandsLazyQuery } from '../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { ICategorizedBrandInterface } from '../../../../store/brandSlice';
import { sortBy } from 'lodash';
import { handleScrollIntoView } from '../utils';

export const getBrandsByCategoryId = ( categoryId: number, categorizedBrands?: ICategorizedBrandInterface[] ) => categorizedBrands
  ?.find( ( brands: ICategorizedBrandInterface ) => brands.categoryId === categoryId )
  ?.manufacturers[0].brands.filter( ( brand ) => brand.brandName );

interface IAdditionalParametersProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const AdditionalParametersCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IAdditionalParametersProps ) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();

  const selectedD2C = useSelector( selectD2CByAudienceId( activeTab ) );
  const marsBrandsWizard = useSelector( selectWizardMarsBrandsByTabName( activeTab ) );
  const selectedCampaigns = useSelector( selectCampaignsByAudienceId( activeTab ) );
  const selectedEngagement = useSelector( selectEngagementByAudienceId( activeTab ) );
  const marketingCampaignsList = useSelector( selectMarketingCampaigns( activeTab ) );
  const additionalParametersWizard = useSelector( selectWizardAdditionalParametersByTabName( activeTab ) );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );

  const [showAllMap, setShowAllMap] = useState<boolean>( false );
  const [campaignsForReduction, setCampaignsForReduction] = useState<any>( [] );

  const scrollRef = useRef<HTMLDivElement | null>( null );

  const isEngagement = !!selectedCampaigns?.filter( ( campaignId ) =>
    marketingCampaignsList?.find( ( c ) =>
      c.id === campaignId )?.isEmail
  )?.length;

  const hasD2C = marsBrandsWizard.hasD2C;
  const isSelectedOnlyD2C = marsBrandsWizard.isSelectedOnlyD2C;
  const hasMarketingCampaigns = marsBrandsWizard.hasMarketingCampaigns;

  const tagValue = [
    { name: 'Collected Through', value: MARKETING_CAMPAIGNS },
    {
      name:  'Campaigns',
      value: selectedCampaigns?.map( ( campaign ) => marketingCampaignsList?.find( ( item ) => item.id === campaign )?.name || '' ) || '',
    },
  ];

  if ( isEngagement && !!selectedEngagement ) {
    tagValue.push( { name: ENGAGEMENT, value: engagementToTagName[selectedEngagement] } );
  }

  const [loadCampaigns] = useBrandMarketingCampaignsLazyQuery( options() );
  const [loadMarsBrands] = useGetBrandsLazyQuery( options() );

  const handleShowAllClick = () => {
    setShowAllMap( true );
  };

  const handleAdditionalParametersRadioGroupChange = ( e: RadioChangeEvent ) => {
    setShowAllMap( false );
    dispatch(
      setAdditionalParametersWizard( {
        activeTab,
        isFinished: false,
        tag:        [],
      } ),
    );

    const parameters = e.target.value === MARKETING_CAMPAIGNS
      ? {
        marketingCampaigns: selectedCampaigns || [],
        d2c:                undefined,
      }
      : {
        marketingCampaigns: undefined,
        engagementStatus:   undefined,
        d2c:                true,
      };

    dispatch( setAudienceParameters( [activeTab, parameters] ) );
  };

  const handleEngagementRadioGroupChange = ( e: RadioChangeEvent ) => {
    dispatch( setAdditionalParametersWizard( { isFinished: false, activeTab } ) );
    dispatch( setAudienceParameters( [activeTab, { engagementStatus: e.target.value }] ) );
  };

  const handleMarketingCampaignsButtonClick = ( id: number ) => {
    dispatch(
      setAdditionalParametersWizard( {
        ...additionalParametersWizard,
        activeTab,
        isFinished: false,
      } ),
    );

    let selectedCampaignsList: number[];
    if ( selectedCampaigns?.includes( id ) ) {
      selectedCampaignsList = selectedCampaigns.filter( ( item ) => item !== id );
    } else {
      selectedCampaignsList = [...( selectedCampaigns || [] ), id];
    }

    dispatch( setAudienceParameters( [activeTab, { marketingCampaigns: selectedCampaignsList }] ) );
  };

  const handleFinishWizardClick = ( parameter: string ) => {
    dispatch(
      setAdditionalParametersWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );
    if ( parameter === MARKETING_CAMPAIGNS ) {
      dispatch(
        setAdditionalParametersWizard( {
          tag: tagValue,
          activeTab,
        } ),
      );

      dispatch( setAudienceParameters( [activeTab, { d2c: undefined }] ) );
    }
    if ( parameter === D2C_WEBSITE_DATA ) {
      dispatch(
        setAdditionalParametersWizard( {
          tag: [{ name: TAG_VALUES.COLLECTED_THROUGH, value: D2C_WEBSITE_DATA }],
          activeTab,
        } ),
      );
      dispatch( setAudienceParameters( [activeTab, { d2c: true, marketingCampaigns: undefined, engagementStatus: undefined }] ) );
    }
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true, isMars: true } ) );
  };

  useEffect( () => {
    if ( isEngagement && !selectedEngagement ) {
      dispatch( setAudienceParameters( [activeTab, { engagementStatus: EngagementStatus.Reached }] ) );
    }

    if ( !isEngagement && !!selectedEngagement ) {
      dispatch( setAudienceParameters( [activeTab, { engagementStatus: undefined }] ) );
    }
  }, [isEngagement] );

  useEffect( () => {
    if ( !additionalParametersWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [additionalParametersWizard.isFinished] );

  useEffect( () => {
    const shouldShowAll = showAllMap || false;

    setCampaignsForReduction( marketingCampaignsList?.slice( 0, shouldShowAll ? marketingCampaignsList.length : 10 ) );
  }, [marketingCampaignsList, showAllMap] );

  useEffect( () => {
    if ( !!selectedCampaigns || !!selectedEngagement || !!selectedD2C ) {
      handleScrollIntoView( scrollRef );
    }
  }, [selectedCampaigns, selectedEngagement, selectedD2C] );

  useEffect( () => {
    handleScrollIntoView( scrollRef );

    const prepareCollapse = async () => {
      const audience = audiencesToCompare[activeTab];

      const isMarsBrandsSource = [18, 32].includes( audience.audienceParameters?.manufacturer || 0 );

      if ( isMarsBrandsSource && ( !!audience.audienceParameters?.d2c || !!audience.audienceParameters?.marketingCampaigns ) ) {
        dispatch(
          setAdditionalParametersWizard( {
            activeTab,
            isFinished: true,
            isExpand:   false,
          } ),
        );
        if ( !!audience.audienceParameters?.marketingCampaigns?.length ) {
          const marsBrandsData = await loadMarsBrands();

          const brandsToLoad = getBrandsByCategoryId( audience.audienceParameters.category, marsBrandsData.data?.categorizedBrands )
            ?.filter( ( brand: any ) => audience.audienceParameters?.brands.includes( brand.brandId ) )
            .map( ( brand: any ) => ( { id: brand.brandId, name: brand.brandName } ) ) || [];

          const campaignsData = await loadCampaigns( {
            variables: {
              brands: sortBy( brandsToLoad, 'id' ),
            },
          } );

          const marketingCampaigns = campaignsData.data?.brandMarketingCampaigns.no;
          const tag = [
            { name: 'Collected Through', value: MARKETING_CAMPAIGNS },
            {
              name:  'Campaigns',
              value: audience.audienceParameters.marketingCampaigns
                ?.map( ( campaign ) =>
                  marketingCampaigns?.find( ( item ) => item.id === campaign )?.name || '' ).filter( ( item ) => !!item ) || '',
            },
          ];

          if ( !!audience.audienceParameters.engagementStatus ) {
            tag.push( { name: ENGAGEMENT, value: engagementToTagName[audience.audienceParameters.engagementStatus] } );
          }

          dispatch(
            setAdditionalParametersWizard( {
              tag,
              activeTab,
            } ),
          );
        }
        if ( !!audience.audienceParameters?.d2c ) {
          dispatch(
            setAdditionalParametersWizard( {
              tag:       [{ name: TAG_VALUES.COLLECTED_THROUGH, value: D2C_WEBSITE_DATA }],
              activeTab,
            } ),
          );
        }
        dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
      }
    };

    if ( hasAudiencesToCompare && isEqualComparisons ) {
      prepareCollapse();
    }
  }, [] );

  return (
    <Collapse
      defaultActiveKey={WIZARD_NAME.ADDITIONAL_PARAMETERS}
      activeKey={additionalParametersWizard.isExpand ? WIZARD_NAME.ADDITIONAL_PARAMETERS : ''}
      collapsible='icon'
      expandIconPosition='end'
      onChange={() => handleCollapseChange( setAdditionalParametersWizard, additionalParametersWizard )}
      expandIcon={() =>
        additionalParametersWizard.isExpand
          ? (
            <MinusOutlined style={expandIconStyles} />
          )
          : (
            <PlusOutlined style={expandIconStyles} />
          )
      }
    >
      <Panel
        key={WIZARD_NAME.ADDITIONAL_PARAMETERS}
        header={
          <CollapseHeader
            step={WIZARD_NAME.ADDITIONAL_PARAMETERS}
            heading={`Step 04: ${STEP_NAMES.ADDITIONAL_PARAMETERS}`}
            caption='Select additional parameters for a selected brand'
          />
        }
        style={{ padding: '0.75rem 0.5rem 0.75rem' }}
      >
        {hasMarketingCampaigns && !hasD2C && (
          <TitleWithCaption caption={`${COLLECTED_THROUGH}: ${MARKETING_CAMPAIGNS}`} captionFontSize={16} />
        )}
        {!hasMarketingCampaigns && hasD2C && (
          <TitleWithCaption caption={`${COLLECTED_THROUGH}: ${D2C_WEBSITE_DATA}`} captionFontSize={16} />
        )}
        {hasMarketingCampaigns && hasD2C && (
          <>
            <Radio.Group
              options={[
                { label: MARKETING_CAMPAIGNS, value: MARKETING_CAMPAIGNS },
                { label: D2C_WEBSITE_DATA, value: D2C_WEBSITE_DATA },
              ]}
              className='two-wide-button-group'
              optionType='button'
              buttonStyle='solid'
              value={( !!selectedD2C && D2C_WEBSITE_DATA )
                || ( selectedCampaigns !== undefined && selectedCampaigns !== null && MARKETING_CAMPAIGNS ) }
              style={{ width: '100%' }}
              onChange={handleAdditionalParametersRadioGroupChange}
            />
            {!isSelectedOnlyD2C && (
              <div className='note-caption'>
                <TitleWithCaption
                  caption='*D2C Website Data is available only for M&MS, Ethel M'
                  captionFontSize={12}
                />
              </div>
            )}
          </>
        )}
        {hasMarketingCampaigns && ( !!selectedCampaigns || !hasD2C ) && (
          <>
            <Divider style={{ margin: '1rem 0 1rem' }} />
            <div style={{ paddingBottom: 16 }}>
              <TitleWithCaption caption='Marketing Campaign' captionFontSize={16} />
            </div>
            <Space className='blade-buttons' wrap>
              {campaignsForReduction.map( ( campaign: ICampaignSliceInterface ) => (
                <Button
                  key={campaign.name}
                  style={{
                    maxWidth:      '620px',
                    overflow:      'hidden',
                    whiteSpace:    'nowrap',
                    textOverflow:  'ellipsis',
                    color:         selectedCampaigns?.includes( campaign.id ) ? BUTTON_BORDER_COLOR : '',
                    borderColor:   selectedCampaigns?.includes( campaign.id ) ? BUTTON_BORDER_COLOR : '',
                  }}
                  icon={selectedCampaigns?.includes( campaign.id ) ? <CheckOutlined /> : ''}
                  onClick={() => handleMarketingCampaignsButtonClick( campaign.id )}
                >
                  {campaign.name}
                </Button>
              ) )}
              {!showAllMap && ( marketingCampaignsList?.length || 0 ) > 10 && (
                <div className='link-button'>
                  <Button type='link' onClick={handleShowAllClick}>
										Show All
                  </Button>
                </div>
              )}
            </Space>
            {selectedCampaigns !== undefined && isEngagement && (
              <>
                <Divider style={{ margin: '1rem 0 1rem' }} />
                <div style={{ paddingBottom: 16 }}>
                  <TitleWithCaption caption={ENGAGEMENT} captionFontSize={16} />
                </div>
                <Radio.Group
                  options={engagementOptions}
                  optionType='button'
                  buttonStyle='solid'
                  value={selectedEngagement}
                  style={{ width: '100%' }}
                  onChange={handleEngagementRadioGroupChange}
                />
                <div className='note-caption'>
                  <TitleWithCaption
                    caption='*Engagement is available only for Email Campaigns'
                    captionFontSize={12}
                  />
                </div>
              </>
            )}
            {( selectedCampaigns?.length || 0 ) > 0 && (
              <>
                <Divider style={{ margin: '1rem 0 1rem' }} />
                <Space className='next-button'>
                  <Button onClick={() => handleFinishWizardClick( MARKETING_CAMPAIGNS )}>Finish Wizard</Button>
                </Space>
              </>
            )}
          </>
        )}
        <div ref={scrollRef}>
          {( !!selectedD2C || ( !hasMarketingCampaigns && hasD2C ) ) && (
            <>
              <Divider style={{ margin: '1rem 0 1rem' }} />
              <Space className='next-button'>
                <Button onClick={() => handleFinishWizardClick( D2C_WEBSITE_DATA )}>Finish Wizard</Button>
              </Space>
            </>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default AdditionalParametersCollapse;
