import { EPartyData } from '../Blade/collapse/OriginCollapse/const';
import { AUDIENCE_ID } from '../Blade/collapse/const';

export const comparisonColors: Record<string, string> = {
  [AUDIENCE_ID.BENCHMARK]:         'blue',
  [AUDIENCE_ID.COMPARISON]:        'green',
  [AUDIENCE_ID.SECOND_COMPARISON]: 'purple',
};

export const positionToAudienceName: Record<string, AUDIENCE_ID> = {
  'audienceA': AUDIENCE_ID.BENCHMARK,
  'audienceB': AUDIENCE_ID.COMPARISON,
  'audienceC': AUDIENCE_ID.SECOND_COMPARISON,
};

export const webLinks: Record<number, string> = {
  97:  'mms.com',
  242: 'ethelm.com',
};

export const partyData: Partial<Record<EPartyData, string>> = {
  [EPartyData.firstParty]: '1PD',
  [EPartyData.thirdParty]: '3PD',
};
