import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Flex } from 'antd';

import { VennDiagramSvg } from './VennDiagramSvg';
import { selectAudienceComparisonCount, selectChosenAudience } from '../../../../store/audienceComparison/selectors';
import { TextPopover } from '../../../../custom/TextPopover/TextPopover';

import './VennDiagramContainer.scss';
import { AUDIENCE_ID } from '../../../Blade/collapse/const';
import { useAudienceShortName } from '../../AudienceShortName';

const VennDiagram = () => {
  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const audienceNames = useAudienceShortName();
  const chosenAudience = useSelector( selectChosenAudience );

  return (
    <div className={cn( 'venn-diagram-container', { chosen: chosenAudience?.segmentId } )}>
      <Flex className='legend'>
        <div className='legend-item benchmark'><TextPopover text={`Benchmark Audience: ${audienceNames[AUDIENCE_ID.BENCHMARK]}`} /></div>
        <div className='legend-item comparison_a'>
          <TextPopover text={`Comparison Audience: ${audienceNames[AUDIENCE_ID.COMPARISON]}`} />
        </div>
        {audienceComparisonCount === 3
				&& <div className='legend-item comparison_b'>
				  <TextPopover text={`2nd Comparison Audience: ${audienceNames[AUDIENCE_ID.SECOND_COMPARISON]}`} />
				</div>}
      </Flex>

      <VennDiagramSvg />
    </div>
  );
};
export default VennDiagram;
