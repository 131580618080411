import { createSelector } from 'reselect';
import { RootState } from '../../../store/store';
import { AUDIENCE_ID } from '../collapse/const';
import { WIZARD_NAME } from '../types';

export const selectTabs = ( state: RootState ) => state?.tabs.tabsList;
export const selectTabsActiveTab = ( state: RootState ) => state?.tabs.activeTab;
export const selectTabsIsComparisonFinished = ( state: RootState ) => state?.tabs.tabsList[AUDIENCE_ID.COMPARISON].isFinished;
export const selectTabsIsSecondComparisonFinished = ( state: RootState ) => state?.tabs.tabsList[AUDIENCE_ID.SECOND_COMPARISON].isFinished;

export const selectWizards = ( state: RootState ) => state?.wizard.wizards;
export const selectIsWizardOpen = ( state: RootState ) => state?.wizard.isOpen;

export const selectBenchmarkWizard = ( state: RootState ) => state?.wizard.wizards[AUDIENCE_ID.BENCHMARK];
export const selectComparisonWizard = ( state: RootState ) => state?.wizard.wizards[AUDIENCE_ID.COMPARISON];
export const selectSecondComparisonWizard = ( state: RootState ) => state?.wizard.wizards[AUDIENCE_ID.SECOND_COMPARISON];

export const selectWizardPrebuiltAudienceByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].prebuiltAudience
);

export const selectWizardSourceByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].source
);
export const selectWizardAdditionalParametersByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].additionalParameters
);
export const selectWizardMarsBrandsByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].marsBrands
);
export const selectWizardOriginByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].origin
);
export const selectWizardCompetitorsByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].competitors
);

export const selectWizardStepByTabAndStepName = ( tabName: AUDIENCE_ID, stepName: WIZARD_NAME ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName][stepName]
);
