import React, { useEffect } from 'react';
import { Button, Collapse, Divider, Radio, RadioChangeEvent } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetAdditionalParametersWizard,
  resetCompetitorsWizard,
  resetMarsBrandsWizard,
  resetOriginWizard,
  resetPrebuiltAudienceWizard,
  setCompetitorsWizard,
  setMarsBrandsWizard,
  setPrebuiltAudienceWizard,
  setSourceWizard,
} from '../../../../store/wizardSlice';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import { COMPETITORS, MARS_BRANDS, PREBUILT_AUDIENCES, AUDIENCE_ID } from '../const';
import { ICollapseProps } from '../types';
import { sourceOptions, SOURCE_SUFFIX } from './const';
import { selectWizardSourceByTabName } from '../../WizardTabs/selectors';
import {
  selectAudiencesToCompare,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
} from '../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../store/tabSlice';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';

const { Panel } = Collapse;

interface ISourceCollapseProps extends ICollapseProps {
  audience: AUDIENCE_ID;
}

const SourceCollapse = ( { handleCollapseChange, expandIconStyles, audience }: ISourceCollapseProps ) => {
  const dispatch = useDispatch();
  const activeTab = audience;
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );

  const sourceWizard = useSelector( selectWizardSourceByTabName( activeTab ) );

  // When we change value in 'Source' Button Group
  const handleRadioGroupChange = ( e: RadioChangeEvent ) => {
    dispatch( setSourceWizard( { value: e.target.value, isFinished: false, activeTab } ) );
    dispatch( resetPrebuiltAudienceWizard( { activeTab } ) );
    dispatch( resetMarsBrandsWizard( { activeTab } ) );
    dispatch( resetOriginWizard( { activeTab } ) );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    dispatch( resetCompetitorsWizard( { activeTab } ) );
    dispatch( setAudienceParameters( [activeTab, undefined] ) );
  };

  // After 'Next' click in 'Source' Collapse
  const handleSourceNextClick = () => {
    dispatch(
      setSourceWizard( {
        value:      sourceWizard.value,
        isFinished: true,
        isExpand:   false,
        tag:        [{ name: TAG_VALUES.SOURCE, value: sourceWizard.value }],
        activeTab,
      } ),
    );

    // When we select 'Prebuilt Audiences' source in step 01
    if ( sourceWizard.value === PREBUILT_AUDIENCES ) {
      dispatch( setPrebuiltAudienceWizard( { value: '', isExpand: true, isVisible: true, error: false, activeTab } ) );
    } else if ( sourceWizard.value === MARS_BRANDS ) {
      dispatch( setMarsBrandsWizard( { isExpand: true, isVisible: true, error: false, activeTab } ) );
    } else if ( sourceWizard.value === COMPETITORS ) {
      dispatch( setCompetitorsWizard( { isExpand: true, isVisible: true, error: false, activeTab } ) );
    }
  };

  useEffect( () => {
    if ( !sourceWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [sourceWizard.isFinished] );

  useEffect( () => {
    if ( hasAudiencesToCompare && isEqualComparisons
			&& ( activeTab === AUDIENCE_ID.SECOND_COMPARISON ? Object.keys( audiencesToCompare.AudienceC || {} ).length > 1 : true ) ) {
      Object.entries( audiencesToCompare ).filter( ( [key, audience] ) => !!audience ).map( ( [key, audience] ) => {
        const isPrebiltSource = !!audience.preBuiltAudienceIds?.length;
        const isMarsBrandsSource = [18, 32].includes( audience.audienceParameters?.manufacturer || 0 );
        const isCompetitorBrandsSource = !!audience.audienceParameters?.manufacturer
				&& ![18, 32].includes( audience.audienceParameters?.manufacturer || 0 );

        const value = isMarsBrandsSource ? MARS_BRANDS : isPrebiltSource ? PREBUILT_AUDIENCES : isCompetitorBrandsSource ? COMPETITORS : '';

        dispatch(
          setSourceWizard( {
            value,
            activeTab:  key,
            isFinished: true,
            isExpand:   false,
            tag:        [{ name: TAG_VALUES.SOURCE, value }],
          } ),
        );

        if ( isCompetitorBrandsSource ) {
          dispatch( setCompetitorsWizard( { isVisible: true, activeTab: key } ) );
        }
        if ( isMarsBrandsSource ) {
          dispatch( setMarsBrandsWizard( { isVisible: true, activeTab: key } ) );
        }

        if ( isPrebiltSource ) {
          dispatch( setPrebuiltAudienceWizard( { isVisible: true, activeTab: key } ) );
        }
      } );
    }
  }, [] );

  useEffect( () => {
    if ( hasAudiencesToCompare && sourceWizard.isFinished ) {
      // dispatch(setActiveTab(AUDIENCE_ID.COMPARISON));
    }
  }, [sourceWizard.isFinished] );

  return (
    <Collapse
      defaultActiveKey={WIZARD_NAME.SOURCE}
      activeKey={sourceWizard.isExpand ? WIZARD_NAME.SOURCE : ''}
      collapsible='icon'
      expandIconPosition='end'
      onChange={() => handleCollapseChange( setSourceWizard, sourceWizard )}
      expandIcon={() =>
        sourceWizard.isExpand ? <MinusOutlined style={expandIconStyles} /> : <PlusOutlined style={expandIconStyles} />
      }
    >
      <Panel
        key={WIZARD_NAME.SOURCE}
        header={
          <CollapseHeader
            step={WIZARD_NAME.SOURCE}
            heading={`Step 01: ${STEP_NAMES.SOURCE}`}
            caption='Select source as a starting point for further parameters'
          />
        }
        style={{ padding: '0.75rem 0.5rem 0.75rem' }}
      >
        <Radio.Group
          options={sourceOptions( sourceWizard.value )}
          optionType='button'
          buttonStyle='solid'
          value={sourceWizard.value}
          style={{ width: '100%' }}
          onChange={handleRadioGroupChange}
        />
        {sourceWizard.value && (
          <>
            <Divider style={{ margin: '1rem 0 1rem' }} />
            <div className='next-button'>
              <Button onClick={handleSourceNextClick}>{`Next - ${SOURCE_SUFFIX[sourceWizard.value]}`}</Button>
            </div>
          </>
        )}
      </Panel>
    </Collapse>
  );
};

export default SourceCollapse;
