import React, { useEffect, useState } from 'react';
import { ITemplate } from './DashboardCard';
import { ALL_BUYER_TYPES, options } from '../../Blade/collapse/const';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import VSTag from '../../../custom/VSTag';
import CustomTag from '../../../custom/CustomTag/CustomTag';
import {
  selectCategorizedCompetitorsBrands,
  selectCompetitorBrandNames,
  selectCompetitorManufacturerNames,
  selectMarsBrandNames,
  setCompetitorsBrands,
  setMarsBrands,
} from '../../../store/brandSlice';
import { EPartyDataToName } from '../../Blade/collapse/OriginCollapse/const';
import { buyerTypeToTagName, engagementToTagName } from '../../Blade/collapse/AdditionalParametersCollapse/const';
import { Space, Spin } from 'antd';
import {
  PreBuiltAudienceNamesQuery,
  useBrandMarketingCampaignsLazyQuery,
  useGetAudienceTemplateQuery,
  useGetBrandsLazyQuery,
  usePreBuiltAudienceNamesLazyQuery,
} from '../../../apollo/graphql-types';

interface ITagsProps {
  template: ITemplate;
  width?: number;
  visible: boolean;
}
export const Tags = ( { template, width, visible }: ITagsProps ) => {
  const dispatch = useDispatch();
  const [preBuiltAudiences, setPreBuiltAudiences] = useState<PreBuiltAudienceNamesQuery['preBuiltAudienceNames']>( [] );
  const competitorBrandNames = useSelector( selectCompetitorBrandNames );
  const competitorManufacturerNames = useSelector( selectCompetitorManufacturerNames );
  const marsBrandNames = useSelector( selectMarsBrandNames );
  const competitorsList = useSelector( selectCategorizedCompetitorsBrands );

  const [campaigns, setCampaigns] = useState( {} as any );
  const [loading, setLoading] = useState( false );
  const [error, setError] = useState( false );

  const [loadAudiences, { loading: loadingAudiences, error: errorAudiences }] = usePreBuiltAudienceNamesLazyQuery( options() );
  const [loadCompetitors, { loading: loadingCompetitors, error: errorCompetitors }] = useGetBrandsLazyQuery( {
    ...options(),
    variables: { isCompetitor: true },
  } );
  const [loadCampaigns, { loading: loadingCampaigns, error: errorCampaigns }] = useBrandMarketingCampaignsLazyQuery( options() );
  const [loadMarsBrands, { loading: loadingMarsBrands, error: errorMarsBrands }] = useGetBrandsLazyQuery( options() );
  const { data, loading: loadingTemplate, error: errorTemplate } = useGetAudienceTemplateQuery( {
    ...options(),
    variables: { templateId: template.id },
  } );

  useEffect( () => {
    if ( loadingAudiences || loadingCompetitors || loadingCampaigns || loadingMarsBrands || loadingTemplate ) {
      setLoading( true );
    } else {
      setLoading( false );
    }
  }, [loadingAudiences, loadingCompetitors, loadingCampaigns, loadingMarsBrands, loadingTemplate] );

  useEffect( () => {
    if ( errorAudiences || errorCompetitors || errorCampaigns || errorMarsBrands || errorTemplate ) {
      setError( true );
    } else {
      setError( false );
    }
  }, [errorAudiences, errorCompetitors, errorCampaigns, errorMarsBrands, errorTemplate] );

  useEffect( () => {
    if ( !!data?.getAudienceTemplate && visible ) {
      const audiences = [
        data.getAudienceTemplate.audienceA,
        data.getAudienceTemplate.audienceB,
        data.getAudienceTemplate.audienceC,
      ].filter( ( audience ) => !!audience );
      let hasPreBuiltAudiences = false;
      let hasMarsBrands = false;
      let hasCompetitors = false;
      let hasCampaings = false;
      let selectedMarsBrands: number[] = [];

      audiences.map( ( audience: any, index ) => {
        if ( !!audience?.preBuiltAudienceIds ){
          hasPreBuiltAudiences = true;
        }

        if ( !!audience.audienceParameters ) {
          if ( [18, 32].includes( audience.audienceParameters.manufacturer || 0 ) ) {
            hasMarsBrands = true;
            hasCampaings = !!audience.audienceParameters.marketingCampaigns?.length;
            selectedMarsBrands = [...selectedMarsBrands, ...audience.audienceParameters.brands];
          } else {
            hasCompetitors = true;
          }
        }
      } );

      const loadAdditionalData = async () => {
        if ( hasPreBuiltAudiences && !preBuiltAudiences.length ) {
          const resolve = await loadAudiences();
          setPreBuiltAudiences( resolve.data?.preBuiltAudienceNames || [] );
        }

        if ( hasCompetitors && isEmpty( competitorBrandNames ) ) {
          const resolve = await loadCompetitors();
          dispatch( setCompetitorsBrands( resolve.data?.categorizedBrands ) );
        }

        if ( hasMarsBrands && isEmpty( marsBrandNames ) ) {
          const resolve = await loadMarsBrands();
          dispatch( setMarsBrands( resolve.data?.categorizedBrands ) );
        }

        if ( hasCampaings && !isEmpty( marsBrandNames ) ) {
          const brandsToLoad = selectedMarsBrands.map( ( brandId ) => {
            const name = marsBrandNames[brandId];
            return {
              id: brandId,
              name,
            };
          } );

          const resolve = await loadCampaigns( { variables: { brands: brandsToLoad } } );
          setCampaigns( resolve.data?.brandMarketingCampaigns );
        }
      };

      loadAdditionalData().catch( ( error ) => console.log( error ) ); // eslint-disable-line
    }

  }, [data, marsBrandNames, visible] );

  const audiences = [
    data?.getAudienceTemplate.audienceA,
    data?.getAudienceTemplate.audienceB,
    data?.getAudienceTemplate.audienceC,
  ].filter( ( audience ) => !!audience );

  const renderTags = () => {
    const tagsToShow = audiences.map( ( audience, index ) => {
      const tags = [];
      const audienceParameters = audience?.audienceParameters;
      const audiencePreBuiltAudiencesIds = audience?.preBuiltAudienceIds;
      const selectedBrands = audienceParameters?.brands;
      const buyerTypes = audienceParameters?.buyerTypes?.length === 5
        ? ALL_BUYER_TYPES
        : audienceParameters?.buyerTypes?.map( ( bt ) => buyerTypeToTagName[bt] ).join( ', ' );

      if ( !isEmpty( audiencePreBuiltAudiencesIds ) && !isEmpty( preBuiltAudiences ) ) {
        tags.push( 'Prebuilt Audiences' );
        const preAudiencesNames = audiencePreBuiltAudiencesIds
          ?.map( ( preBuilt: number ) => preBuiltAudiences.find( ( a ) => a.id === preBuilt )?.name );

        preAudiencesNames && tags.push( preAudiencesNames.join( ', ' ) );

        if ( ( audiences.length === 3 && [0, 1].includes( index ) ) || ( audiences.length === 2 && index === 0 ) ) {
          tags.push( <VSTag key={index}/> );
        }
      } else if ( ![18, 32].includes( audienceParameters?.manufacturer || 0 ) && !isEmpty( competitorBrandNames ) ) {
        const competitorsBrandsNames = selectedBrands?.map( ( brand: any ) => competitorBrandNames[brand] ).join( ', ' );
        const manufacturer = audienceParameters?.manufacturer || 0;
        const availableBrandsLength = competitorsList
          ?.find( ( category: any ) => category.categoryId === audienceParameters?.category )
          ?.manufacturers.find( ( manufacturer: any ) =>
            manufacturer.manufacturerId === audienceParameters?.manufacturer )?.brands.length;

        const brandsToShow = availableBrandsLength === selectedBrands?.length ? 'All Brands' : competitorsBrandsNames;

        tags.push( 'Competitors' );
        tags.push( ( competitorManufacturerNames?.[manufacturer] || '' )?.concat( ` ${brandsToShow}` ) );
        tags.push( buyerTypes );
        if ( ( audiences.length === 3 && [0, 1].includes( index ) ) || ( audiences.length === 2 && index === 0 ) ) {
          tags.push( <VSTag key={index}/> );
        }
      } else if ( [18, 32].includes( audienceParameters?.manufacturer || 0 ) && !isEmpty( marsBrandNames ) ) {
        tags.push( 'Mars Brands' );
        tags.push( selectedBrands?.map( ( brand: any ) => marsBrandNames[brand] ).join( ', ' ) );
        tags.push( buyerTypes );

        const originValue = () => {
          if ( audienceParameters?.firstParty === true ) {
            return EPartyDataToName.FIRST_PARTY_DATA;
          } else if ( audienceParameters?.thirdParty === true ) {
            return EPartyDataToName.THIRD_PARTY_DATA;
          }

          return '';
        };

        if ( originValue() !== '' ) {
          tags.push( originValue() );
        }

        if ( !!audienceParameters?.marketingCampaigns ) {
          tags.push( 'Marketing Campaigns' );
          tags.push( audienceParameters.marketingCampaigns
            ?.map( ( campaign: any ) => campaigns?.no?.find( ( item: any ) => item.id === campaign )?.name ).join( ', ' ) );
        }

        if ( !!audienceParameters?.engagementStatus ) {
          tags.push( engagementToTagName[audienceParameters.engagementStatus] );
        }

        if ( ( audiences.length === 3 && [0, 1].includes( index ) ) || ( audiences.length === 2 && index === 0 ) ) {
          tags.push( <VSTag key={index}/> );
        }
      }
      return tags.map( ( tag ) => typeof tag === 'string'
        ? <CustomTag key={tag} maxWidth={( width || 200 ) - 55} tagValue={tag} />
        : tag
      );
    } );

    return tagsToShow;
  };

  return (
    <div className='tooltip-tags'>
      {!error
        ? !loading ? renderTags() : <Space className='loader' size='middle'><Spin /></Space>
        : (
          <div className='error'>Couldn't load the data. Please retry.</div>
        )}
      {}
    </div>
  );
};
