const getOrCreateTooltip = ( chart: any ) => {
  let tooltipEl = chart.canvas.parentNode.querySelector( '.tooltip-element' );

  if ( !tooltipEl ) {
    tooltipEl = document.createElement( 'div' );
    tooltipEl.classList.add( 'tooltip-element' );

    const table = document.createElement( 'table' );
    table.style.margin = '0px';

    tooltipEl.appendChild( table );
    chart.canvas.parentNode.appendChild( tooltipEl );
  }

  return tooltipEl;
};

export const externalDoughnutTooltipHandler = ( context: any ) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip( chart );

  if ( tooltip.opacity === 0 ) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if ( tooltip.body ) {
    const bodyLines = tooltip.body.map( ( b: any ) => b.lines );

    const tableBody = document.createElement( 'tbody' );
    bodyLines.forEach( ( body: any, i: any ) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement( 'canvas' );
      const context = span.getContext( '2d' );

      if ( context != null ) {
        span.height = 10;
        span.width = 10;

        context.fillStyle = colors.backgroundColor;

        context.fillRect( 0, 0, 10, 10 );
        span.style.marginRight = '10px';
      }

      const tr = document.createElement( 'tr' );
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const td = document.createElement( 'td' );
      td.style.borderWidth = '0';

      const text = document.createTextNode( tooltip.title[0] === 'no_data'
        ? 'Data unavailable or unspecified for this portion of the audience'
        : `${tooltip.title}: ${body}%`
      );

      if ( tooltip.title[0] !== 'no_data' ) {
        td.appendChild( span );
      }

      td.appendChild( text );
      tr.appendChild( td );
      tableBody.appendChild( tr );
    } );

    const tableRoot = tooltipEl.querySelector( 'table' );

    while ( tableRoot.firstChild ) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild( tableBody );
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const toooltipLeft = tooltip.caretX - tooltipEl.scrollWidth/2 + 5 < 0
    ? 5 + 'px'
    : positionX + tooltip.caretX - tooltipEl.scrollWidth/2 + 'px';

  tooltipEl.style.width = 300;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = toooltipLeft;
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '12px';
};
