export interface IBladeProps {
  open: boolean;
  currentStep: number;
  handlePrev: () => void;
  handleNext: () => void;
  handleClose: () => void;
}

export enum WIZARD_NAME {
  SOURCE = 'source',
  PREBUILT_AUDIENCE = 'prebuiltAudience',
  MARS_BRANDS = 'marsBrands',
  ORIGIN = 'origin',
  ADDITIONAL_PARAMETERS = 'additionalParameters',
  COMPETITORS = 'competitors',
}

export interface ICollapseHeaderProps {
  step: WIZARD_NAME;
  heading: string;
  caption: string;
}

export enum STEP_NAMES {
  SOURCE = 'Source',
  PREBUILT_AUDIENCE = 'Prebuilt Audience',
  MARS_BRANDS = 'Mars Brands',
  ORIGIN = 'Origin',
  ADDITIONAL_PARAMETERS = 'Additional Parameters',
  COMPETITORS = 'Competitors',
}

export enum TAG_VALUES {
  SOURCE = 'Source',
  PREBUILT_AUDIENCE = 'Prebuilt Audience',
  BRAND = 'Brand',
  BUYER_TYPE = 'Buyer Type',
  ORIGIN = 'Origin',
  COLLECTED_THROUGH = 'Collected Through',
}
