import React, { useEffect } from 'react';
import { Dropdown, MenuProps, Tabs, TabsProps } from 'antd';
import { iconStyles } from './const';
import { CheckOutlined, ClearOutlined, DeleteOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, setSpecificTab } from '../../../store/tabSlice';
import { resetAllWizards } from '../../../store/wizardSlice';
import BladeContent from '../bladeCommon/BladeContent';
import { TAB_HEADERS, AUDIENCE_ID } from '../collapse/const';
import { selectIsWizardOpen, selectTabs, selectTabsActiveTab, selectWizardSourceByTabName } from './selectors';
import { selectAudiencesToCompare, selectHasAudiencesToCompare } from '../../../store/audienceComparison/selectors';
import { setAudienceParameters } from '../../../store/audienceComparison/actions';
import { resetMarketingCampaignByAudienceId } from '../../../store/campaignSlice';

import './WizardTabs.scss';

const WizardTabs: React.FC = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector( selectTabsActiveTab );
  const sourceWizard = useSelector( selectWizardSourceByTabName( activeTab ) );
  const tabs: { [key: string]: any } = useSelector( selectTabs );
  const isWizardOpen = useSelector( selectIsWizardOpen );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );

  const handleTabChange = ( tabName: string ) => {
    dispatch( setActiveTab( tabName ) );
  };

  const handleClearTab = () => {
    dispatch( resetAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, undefined] ) );
  };

  const handleDelete2nd = () => {
    dispatch( resetAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isVisible: false, isFinished: false } ) );
    dispatch( setActiveTab( AUDIENCE_ID.COMPARISON ) );
    dispatch( setAudienceParameters( [activeTab, undefined] ) );
  };

  // <Dropdown /> menu
  const items: MenuProps['items'] = [
    {
      key:      'reset',
      disabled: !sourceWizard.isFinished,
      label:    (
        <div style={{ padding: '4px 8px' }}>
          <ClearOutlined style={{ marginRight: 8 }} />
          <span>Reset parameters</span>
        </div>
      ),
      onClick: handleClearTab,
    },
    {
      key:    'delete',
      danger: true,
      label:
				activeTab === AUDIENCE_ID.SECOND_COMPARISON
				  ? (
				  <div style={{ padding: '4px 8px' }}>
				    <DeleteOutlined style={{ marginRight: 8 }} />
				    <span>Delete</span>
				  </div>
				  )
				  : null,
      style:   { display: activeTab === AUDIENCE_ID.SECOND_COMPARISON ? 'inherit' : 'none' },
      onClick: handleDelete2nd,
    },
  ];

  const generateMenuContent = () => (
    <Dropdown trigger={['click']} menu={{ items }} placement='bottomRight'>
      <EllipsisOutlined style={{ margin: '0 0 0 12px', ...iconStyles.style }} />
    </Dropdown>
  );

  const generateLabel = ( name: AUDIENCE_ID ) => {
    const isFinished = tabs[name]?.isFinished;
    const text = TAB_HEADERS[name];
    return (
      <div className='tab-label'>
        {isFinished && <CheckOutlined {...iconStyles} />}
        <span>{text}</span>
        {generateMenuContent()}
      </div>
    );
  };

  // <Tabs /> items
  const tabItems: TabsProps['items'] = [
    {
      forceRender: true,
      key:         AUDIENCE_ID.BENCHMARK,
      label:       generateLabel( AUDIENCE_ID.BENCHMARK ),
      children:    <BladeContent audience={AUDIENCE_ID.BENCHMARK} />,
    },
    {
      forceRender: true,
      key:         AUDIENCE_ID.COMPARISON,
      label:       generateLabel( AUDIENCE_ID.COMPARISON ),
      children:    <BladeContent audience={AUDIENCE_ID.COMPARISON} />,
    },
    {
      forceRender: true,
      key:         AUDIENCE_ID.SECOND_COMPARISON,
      label:       tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isVisible
        ? generateLabel( AUDIENCE_ID.SECOND_COMPARISON )
        : <PlusOutlined {...iconStyles} />,
      children:    tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isVisible ? <BladeContent audience={AUDIENCE_ID.SECOND_COMPARISON} /> : null,
    },
  ];

  const handleTabClick = ( tabName: string ) => {
    if ( AUDIENCE_ID.SECOND_COMPARISON === tabName ) {
      if ( !tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isVisible ) {
        dispatch( resetAllWizards( { activeTab: AUDIENCE_ID.SECOND_COMPARISON } ) );
        dispatch( setActiveTab( AUDIENCE_ID.SECOND_COMPARISON ) );
        dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.SECOND_COMPARISON, isVisible: true } ) );
      }
    }
  };

  useEffect( () => {
    if ( !tabs[activeTab]?.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isMars: false, isAudiences: false, isCompetitors: false } ) );
    }
  }, [tabs[activeTab]?.isFinished] );

  useEffect( () => {
    const hasSecondComparison = !!audiencesToCompare[AUDIENCE_ID.SECOND_COMPARISON]?.audienceParameters
		|| !!audiencesToCompare[AUDIENCE_ID.SECOND_COMPARISON]?.preBuiltAudienceIds;
    if ( isWizardOpen && hasSecondComparison && !tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isVisible ) {
      dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.SECOND_COMPARISON, isVisible: true } ) );
    }

    if ( isWizardOpen && hasAudiencesToCompare ) {
      dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.BENCHMARK, isFinished: true } ) );
      dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.COMPARISON, isFinished: true } ) );

      if ( hasSecondComparison ) {
        dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.SECOND_COMPARISON, isFinished: true } ) );
      }
    }
  }, [isWizardOpen, hasAudiencesToCompare] );

  return (
    <Tabs
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      tabBarStyle={{ marginBottom: 24 }}
      items={tabItems}
      onChange={handleTabChange}
      onTabClick={handleTabClick}
    />
  );
};

export default WizardTabs;
