import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { deleteCookie } from '../utils/cookie';

const apiUrl = process.env.REACT_APP_ENV === 'test' 
? 'https://cds-be.cloud-effem.com'  
: `https://mars-${process.env.REACT_APP_ENV}-be.ciklum.net`

export const attributesExcelAPI = `${apiUrl}/attributes/excel?`;
export const authSSOAPI = `${apiUrl}/sso_login`;

const link = createHttpLink({
  uri: `${apiUrl}/audiences`,
  credentials: 'include'
});

const onErrorHandler = () =>  onError(({ networkError }) => {
  if (networkError && networkError.message.indexOf('401') > -1) {
    deleteCookie('session_token');
    document.location.pathname = '/login';
  }
})

export const audienceClient = new ApolloClient( {
  link: ApolloLink.from([
    onErrorHandler(),
    link,
  ]),
  cache:             new InMemoryCache( { addTypename: false } ),
  connectToDevTools: process.env.REACT_APP_ENV !== 'test',
} );
