import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Flex, Layout, Row, Spin, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { ComparisonTable } from './Overview/ComparisonTable/ComparisonTable';
import VennDiagram from './Overview/VennDiagram/VennDiagramContainer';
import TitleWithCaption from '../../custom/TitleWithCaption/TitleWithCaption';

import { AUDIENCE_ID, options } from '../Blade/collapse/const';
import { resetWizard, setWizardIsOpen } from '../../store/wizardSlice';
import { selectTabs } from '../Blade/WizardTabs/selectors';
import { resetTabs, setActiveTab } from '../../store/tabSlice';
import { SaveTemplate } from './Header/SaveTemplate/SaveTemplate';
import { Overview } from './Overview/Overview';
import { Entries } from '../../store/typings';
import { HeaderCaption } from './Header/HeaderCaption';
import { AttributesComparison } from './AttributesComparison/AttributesComparison';
import { ExportXLSX } from './Header/ExportXLSX';
import ErrorSvg from '../../assets/icons/404.svg';
import { AttributesTable } from './Overview/AttributesTable/AttributesTable';

import {
  selectAudiencesToCompare,
  selectAudienceComparisonIsAborting,
  selectAudienceComparisonIsLoading,
  selectChosenAudience } from '../../store/audienceComparison/selectors';
import {
  resetAudienceComparison,
  setAppliedAudiencesToCompare,
  setAudienceComparisonIsAborting,
  setAudienceComparisonIsLoading,
  setAudienceComparisonStats,
  setAudiencesToCompare,
} from '../../store/audienceComparison/actions';
import { resetMarketingCampaignByAudienceId, selectAllMarketingCampaigns, setAllCampaignsList } from '../../store/campaignSlice';
import { useAudienceShortName } from './AudienceShortName';
import {
  useAudienceDistributionLazyQuery,
  useInitiateAttributesCalculationsMutation,
  useInitiateAudienceDistributionCalcMutation,
} from '../../apollo/graphql-types';

import './FilledAudienceComparison.scss';

const FilledAudienceComparison = () => {
  const [loadedQueries, setLoadedQueries] = useState<string[]>( [] );
  const [appliedAudiences, setAppliedAudiences] = useState<any>( {} );
  const [error, setError] = useState( false );
  const [retry, setRetry] = useState( false );
  const [namesUpdated, setNamesUpdated] = useState( false );

  const audienceNames = useAudienceShortName();

  const chosenAudience = useSelector( selectChosenAudience );
  const isAudienceComparisonLoading = useSelector( selectAudienceComparisonIsLoading );
  const tabs = useSelector( selectTabs );
  const allMarketingCampaigns = useSelector( selectAllMarketingCampaigns );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const filledAudiences = ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> ).filter( ( [key, audience] ) => {
    if ( ( tabs[AUDIENCE_ID.BENCHMARK].isFinished ? tabs[key].isFinished : true )
    && ( !isEmpty( audience?.audienceParameters ) || !isEmpty( audience?.preBuiltAudienceIds ) ) ) {
      return true;
    }

    return false;
  } ).map( ( [key, audience] ) => audience );

  const audienceComparisonIsAborting = useSelector( selectAudienceComparisonIsAborting );

  const dispatch = useDispatch();

  const onEditClick = () => {
    dispatch( setWizardIsOpen( true ) );
    dispatch( setActiveTab( AUDIENCE_ID.BENCHMARK ) );
  };

  const abortController = useRef( new AbortController() );
  const abortControllerSecond = useRef( new AbortController() );

  const [initiateComparison, {
    data: dataInitiate,
    error: initiateError,
    reset: resetInitialLoading,
  }] = useInitiateAudienceDistributionCalcMutation();

  const [audienceDistribution, { data: dataDistribution, error: distributionError }] = useAudienceDistributionLazyQuery();
  const [initiateDetailedAttributes, {
    data: dataInitiateAttributes,
    loading: initiateLoading,
    reset: resetInitiateCalculations,
  }] = useInitiateAttributesCalculationsMutation();

  const abort = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const abortSecondCalculation = () => {
    abortControllerSecond.current.abort();
    abortControllerSecond.current = new AbortController();
  };

  useEffect( () => {
    if ( !!dataInitiate
      && !!dataDistribution
      && !isAudienceComparisonLoading
      && namesUpdated
      && !!Object.values( audienceNames ).length
    ) {
      try {
        abortSecondCalculation();
        initiateDetailedAttributes( {
          variables: {
            totalsPrerequisites: {
              requestId:     dataInitiate?.initiateAudienceDistributionCalc?.requestId,
              queryId:       dataInitiate?.initiateAudienceDistributionCalc?.queryId,
              audienceNames: Object.values( audienceNames ),
            },
          },
          ...options(),
          context:     {
            ...options().context,
            fetchOptions: {
              signal: abortControllerSecond.current.signal,
            },
          },
        } ).catch( ( error ) => {
          if ( error.message !== 'The user aborted a request.' ) {
            setError( true );
            console.error(error); //eslint-disable-line
          }
        } );
      } catch ( error ) {
        console.error(error); //eslint-disable-line
      }
    }
  }, [dataInitiate, dataDistribution, isAudienceComparisonLoading, namesUpdated] );

  useEffect( () => {
    if ( audienceComparisonIsAborting ) {
      abort();
      dispatch( setAudienceComparisonIsAborting( false ) );
      dispatch( setAudienceComparisonIsLoading( false ) );

      dispatch( resetAudienceComparison() );
      dispatch( resetWizard() );
      dispatch( resetTabs() );
    }
  }, [audienceComparisonIsAborting] );

  useEffect( () => {
    if ( !namesUpdated ) {
      setNamesUpdated( true );
    }
  }, [audienceNames] );

  useEffect( () => {
    const loadAudiencesComparison = async () => {
      try {
        if ( !dataInitiate?.initiateAudienceDistributionCalc?.requestId ) return false;

        const audienceToCompareUpdated: any = {
          AudienceA: appliedAudiences?.audienceA,
          AudienceB: appliedAudiences?.audienceB,
        };

        if ( !!appliedAudiences?.audienceC?.audienceParameters || !!appliedAudiences?.audienceC?.preBuiltAudienceIds ) {
          audienceToCompareUpdated.AudienceC = appliedAudiences?.audienceC;
        }

        dispatch( setAppliedAudiencesToCompare( audienceToCompareUpdated ) );
        dispatch( setAudiencesToCompare( audienceToCompareUpdated ) );
        dispatch( resetWizard() );
        dispatch( resetTabs() );

        if ( !!allMarketingCampaigns.AudienceC && !allMarketingCampaigns.AudienceB ) {
          dispatch( setAllCampaignsList( {
            activeTab: AUDIENCE_ID.COMPARISON,
            campaings: allMarketingCampaigns.AudienceC,
          } ) );
          dispatch( resetMarketingCampaignByAudienceId( AUDIENCE_ID.SECOND_COMPARISON ) );
        }

        const { data, error } = await audienceDistribution( {
          variables: {
            totalsPrerequisites: {
              requestId:     dataInitiate?.initiateAudienceDistributionCalc?.requestId,
              queryId:       dataInitiate?.initiateAudienceDistributionCalc?.queryId,
              audienceNames: [],
            } },
          ...options(),
          context:     {
            ...options().context,
            fetchOptions: {
              signal: abortController.current.signal,
            },
          },
        } );

        if ( !data?.audienceDistribution ) {
          throw new Error( 'Something went wrong' );
        }
        setNamesUpdated( false );
        dispatch( setAudienceComparisonStats( data?.audienceDistribution ) );
        dispatch( setAudienceComparisonIsLoading( false ) );

      } catch ( error ) {
        setError( true );
      }
    };

    if ( !!dataInitiate && !isEmpty( appliedAudiences ) ) {
      loadAudiencesComparison();
    }
  }, [dataInitiate, appliedAudiences, retry] );

  const initialLoading = () => {
    try {
      setLoadedQueries( [] );
      resetInitiateCalculations();
      setError( false );
      setRetry( false );
      resetInitialLoading();

      const variables: any = {
        audienceA: filledAudiences[0],
        audienceB: filledAudiences[1],
      };

      if ( filledAudiences.length === 3 ) {
        variables.audienceC = filledAudiences[2];
      }

      setAppliedAudiences( variables );

      initiateComparison( {
        variables,
        ...options(),
        context:     {
          ...options().context,
          fetchOptions: {
            signal: abortController.current.signal,
          },
        } } ).catch( ( error ) => {
        if ( error ) {
          setError( true );
          console.error( error )  // eslint-disable-line
        }
      } );
    } catch ( error ) {
      setError( true );
      console.error( error )  // eslint-disable-line
    }
  };

  useEffect( () => {
    if ( isAudienceComparisonLoading ) {
      initialLoading();
    } else {
      setAppliedAudiences( {} );
    }
  }, [isAudienceComparisonLoading] );

  return (
    <Layout className={cn( 'audience-comparison', {
      loading: isAudienceComparisonLoading,
      error:   initiateError || distributionError || error,
    } )}>
      {
        initiateError || distributionError || error
          ? (
            <>
              <img src={ErrorSvg} alt='Service Temporarily Unavailable' className='error-image'/>
              <Typography.Title level={3} >Service Temporarily Unavailable</Typography.Title>
              <p className='caption'>Service disruption detected. Please try again soon or reach out to
                <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
                for help.</p>
              <Button type='primary' onClick={() => initialLoading()}>Retry</Button>
            </>
          )
          :isAudienceComparisonLoading
            ? <Flex vertical align='center' justify='center' className='audience-comparison-spinner'>
              <Spin />
              <span>The request might take a little longer...</span>
            </Flex>
            : <>
              <Row className='audience-comparison-header'>
                <Col span={12}>
                  <TitleWithCaption headingLevel={1} heading='Audience Comparison' />
                </Col>
                <Col span={12}>
                  <Flex wrap='wrap' gap='small' justify='flex-end' className='audience-comparison-header-buttons'>
                    <Button onClick={onEditClick} icon={<EditOutlined />}>Edit Parameters</Button>
                    <ExportXLSX
                      requestId={dataInitiate?.initiateAudienceDistributionCalc?.requestId}
                      loadedQueries={loadedQueries}
                      loading={initiateLoading}
                      attributesData={dataInitiateAttributes}
                    />
                    <SaveTemplate />
                  </Flex>
                </Col>
                <HeaderCaption />
              </Row>
              <Row>
                <Col span={24} className='overview'>
                  <div className='section-title'>
                    <TitleWithCaption
                      headingLevel={4}
                      heading='Overview'
                    />
                  </div>
                  <Overview />
                </Col>

                <Flex className='audience-comparison-content'>
                  <VennDiagram />
                  <ComparisonTable />

                  {!!chosenAudience?.segmentId && <AttributesTable
                    requestId={dataInitiate?.initiateAudienceDistributionCalc?.requestId}
                    dataInitiateAttributes={dataInitiateAttributes}
                    loadedQueries={loadedQueries}
                    setLoadedQueries={setLoadedQueries}
                  />}
                </Flex>
              </Row>
              <AttributesComparison
                requestId={dataInitiate?.initiateAudienceDistributionCalc?.requestId || ''}
                segmentId={dataDistribution?.audienceDistribution.only.find( ( item ) => item.benchmark )?.segmentId || ''}
                attributeSegments={dataInitiateAttributes?.initiateAttributesCalculations.only || []}
                initiateAttributesLoading={initiateLoading}
              />
            </>
      }
    </Layout>
  );
};

export default FilledAudienceComparison;
