import { ChartData, ChartOptions, ChartType, TooltipPositionerFunction } from 'chart.js';
import { BenchmarkChartAttributeValue } from '../../../../apollo/graphql-types';
import { externalDoughnutTooltipHandler } from './tooltip';
import { isArray } from 'lodash';
import './outerLabels';

declare module 'chart.js' {
  interface TooltipPositionerMap { // eslint-disable-line
    custom: TooltipPositionerFunction<ChartType>;
  }
}

export const optionsDoughnut = ( showValues: boolean ): ChartOptions<'doughnut'> => (
  {
    responsive:          true,
    maintainAspectRatio: false,
    radius:              137.5,
    layout:              {
      padding: 20,
    },
    plugins:             {
      tooltip: {
        enabled:  false,
        position: 'custom',
        external: externalDoughnutTooltipHandler,
        boxWidth: 150,
      },
      datalabels: {
        formatter: function ( value, ctx ) {
          return value > 6
          && ( isArray( ctx.dataset.backgroundColor ) && ctx.dataset.backgroundColor.length !== 2 || ctx.dataIndex !== 1 )
            ? value + '%'
            : '';
        },
        color: 'rgba(255,255,255, 0.88)',
        font:  {
          family: 'WorkSans',
          size:   12,
        },
      },
      outerLabels: {
        enable:           showValues,
        offset:           20,
        padding:          2,
        fontNormalSize:   12,
        fontNormalStyle:  'normal',
        fontNormalFamily: 'WorkSans',
        fontNormalColor:  'rgba(0, 0, 0, 0.65)',
        formatter:        ( n ) => `${n.value} ${n.label}`,
      },
    },
  }
);

const colorPallette = [
  // primary colors
  'rgba(0, 0, 160, 0.85)',
  'rgba(34, 178, 121, 0.85)',
  'rgba(122, 54, 190, 0.85)',
  'rgba(230, 160, 0, 0.85)',
  'rgba(214, 66, 0, 0.85)',
  'rgba(22, 119, 255, 0.85)',
  'rgba(235, 47, 150, 0.85)',
  'rgba(97, 97, 142, 0.85)',

  // secondary colors
  'rgba(54, 71, 222, 0.85)',
  'rgba(19, 194, 194, 0.85)',
  'rgba(118, 89, 234, 0.85)',
  'rgba(238, 198, 57, 0.85)',
  'rgba(255, 122, 69, 0.85)',
  'rgba(2, 178, 234, 0.85)',
  'rgba(221, 76, 198, 0.85)',
  'rgba(145, 145, 183, 0.85)',
];

export const generateDoughnutData = ( data: BenchmarkChartAttributeValue[] ): ChartData<'doughnut'> => {
  const filteredData = data.filter( ( item ) => !!+item.fillRate );
  let labels = filteredData.map( ( item ) => item.name );
  let fillRateData = filteredData.map( ( item ) => +item.fillRate );
  const hasOneValue = fillRateData.length === 1 && fillRateData[0] !== 100;

  if ( hasOneValue ) {
    fillRateData.push( +( 100 - fillRateData[0] ).toFixed( 2 ) );
    labels.push( 'no_data' );
  } else {
    if ( fillRateData.filter( ( fillRate ) => fillRate < 1 ).length > 1 ) {
      let other = 0;
      const newLabels = [];

      fillRateData = filteredData.reduce( ( acc, item ) => {
        if ( +item.fillRate < 1 ) {
          other += +item.fillRate;
        } else {
          acc.push( +item.fillRate );
          newLabels.push( item.name );
        }

        return acc;
      }, [] as number[] );

      if ( other > 0 ) {
        fillRateData.push( Number( other.toFixed( 2 ) ) );
        newLabels.push( 'All Other Values' );
      }

      labels = newLabels;
    }
  }

  return {
    labels,
    datasets:      [
      {
        data:               fillRateData,
        backgroundColor:    hasOneValue ? [colorPallette[0], 'rgba(0, 0, 0, 0.06)'] : colorPallette,
        borderWidth:     1,
      },
    ],
  };
};
