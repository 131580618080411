import React, { useEffect, useRef, useState } from 'react';
import { CheckOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSpecificTab } from '../../../../store/tabSlice';
import { collapseAllWizards, setPrebuiltAudienceWizard } from '../../../../store/wizardSlice';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import { BUTTON_BORDER_COLOR, AUDIENCE_ID, options, stepErrorDescription } from '../const';
import { ICollapseProps } from '../types';
import { selectWizardPrebuiltAudienceByTabName } from '../../WizardTabs/selectors';
import {
  selectAudiencesToCompare,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
  selectPreBuiltAudiencesByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setPreBuiltAudienceIds } from '../../../../store/audienceComparison/actions';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import { usePreBuiltAudienceNamesQuery } from '../../../../apollo/graphql-types';
import { handleScrollIntoView } from '../utils';

interface IPrebuiltAudienceCollapseProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const PrebuiltAudienceCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IPrebuiltAudienceCollapseProps ) => {
  const dispatch = useDispatch();
  const { Panel } = Collapse;

  const selectedPreBuiltAudiencesSelector = useSelector( selectPreBuiltAudiencesByAudienceId( activeTab ) );
  const selectedPreBuiltAudiences = selectedPreBuiltAudiencesSelector || [];
  const prebuiltAudienceWizard = useSelector( selectWizardPrebuiltAudienceByTabName( activeTab ) );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const [retry, setRetry] = useState( false );

  const scrollRef = useRef<HTMLDivElement | null>( null );

  const { data: preBuiltAudiences, loading, error, refetch } = usePreBuiltAudienceNamesQuery( options() );

  useEffect( () => {
    if ( !!error ) {
      dispatch( setPrebuiltAudienceWizard( { error: true, activeTab } ) );
      console.error(`Loading data error: ${error.message}`); //eslint-disable-line
    }
  }, [error] );

  useEffect( () => {
    if ( !loading || !!selectedPreBuiltAudiences ) {
      handleScrollIntoView( scrollRef );
    }
  }, [loading, selectedPreBuiltAudiences] );

  const handlePrebuiltAudienceButtonClick = ( id: number ) => {
    dispatch( setPrebuiltAudienceWizard( { activeTab, isFinished: false } ) );

    if ( selectedPreBuiltAudiences.includes( id ) ) {
      dispatch( setPreBuiltAudienceIds( [activeTab, selectedPreBuiltAudiences.filter( ( audience ) => audience !== id )] ) );

      return;
    }

    dispatch( setPreBuiltAudienceIds( [activeTab, [...selectedPreBuiltAudiences, id]] ) );
  };

  const handlePrebuiltAudienceNextClick = () => {
    const audiences = selectedPreBuiltAudiences
      .map( ( audience ) => preBuiltAudiences?.preBuiltAudienceNames.find( ( a ) => a.id === audience )?.name || '' );

    dispatch(
      setPrebuiltAudienceWizard( {
        tag:        [{ name: TAG_VALUES.PREBUILT_AUDIENCE, value: audiences }],
        isFinished: true,
        isExpand:   false,
        activeTab,
      } ),
    );
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true, isAudiences: true } ) );
  };

  useEffect( () => {
    if ( !prebuiltAudienceWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [prebuiltAudienceWizard.isFinished] );

  useEffect( () => {
    if ( retry ) {
      dispatch( setPrebuiltAudienceWizard( { error: false, activeTab } ) );
      setRetry( false );
      refetch();
    }
  }, [retry] );

  useEffect( () => {
    if ( hasAudiencesToCompare && isEqualComparisons && !!preBuiltAudiences ) {
      const audience = audiencesToCompare[activeTab];
      if ( !!audience.preBuiltAudienceIds ) {
        const audiences = audience.preBuiltAudienceIds
          ?.map( ( audience ) => preBuiltAudiences.preBuiltAudienceNames.find( ( a ) => a.id === audience )?.name || '' );

        dispatch(
          setPrebuiltAudienceWizard( {
            activeTab,
            tag:        [{ name: TAG_VALUES.PREBUILT_AUDIENCE, value: audiences }],
            isFinished: true,
          } ),
        );
        dispatch( setSpecificTab( { activeTab, isFinished: true, isAudiences: true } ) );
      }
    }
  }, [preBuiltAudiences] );

  return prebuiltAudienceWizard.error
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          setRetry( true );
        }}
      />
    )
    : loading || !preBuiltAudiences?.preBuiltAudienceNames.length
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          activeKey={prebuiltAudienceWizard.isExpand ? WIZARD_NAME.PREBUILT_AUDIENCE : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setPrebuiltAudienceWizard, prebuiltAudienceWizard )}
          expandIcon={() =>
            prebuiltAudienceWizard.isExpand
              ? (
                <MinusOutlined style={expandIconStyles} />
              )
              : (
                <PlusOutlined style={expandIconStyles} />
              )
          }
        >
          <Panel
            key={WIZARD_NAME.PREBUILT_AUDIENCE}
            header={
              <CollapseHeader
                step={WIZARD_NAME.PREBUILT_AUDIENCE}
                heading={`Step 02: ${STEP_NAMES.PREBUILT_AUDIENCE}`}
                caption='Select audiences curated by data scientists'
              />
            }
            style={{ padding: '0.75rem 0.5rem 0.75rem' }}
          >
            <Space className='blade-buttons' wrap style={{ width: 640 }}>
              {preBuiltAudiences.preBuiltAudienceNames.map( ( item: any ) => {
                const isSelected = selectedPreBuiltAudiences.includes( item.id );
                return (
                  <Button
                    key={item.name}
                    style={{
                      maxWidth:      '640px',
                      overflow:      'hidden',
                      whiteSpace:    'nowrap',
                      textOverflow:  'ellipsis',
                      textTransform: 'capitalize',
                      color:         isSelected ? BUTTON_BORDER_COLOR : '',
                      borderColor:   isSelected ? BUTTON_BORDER_COLOR : '',
                    }}
                    icon={isSelected ? <CheckOutlined /> : ''}
                    onClick={() => handlePrebuiltAudienceButtonClick( item.id )}
                  >
                    {item.name}
                  </Button>
                );
              } )}
            </Space>
            <div ref={scrollRef}>
              {selectedPreBuiltAudiences.length !== 0 && !prebuiltAudienceWizard.isLoading && (
                <>
                  <Divider style={{ margin: '1rem 0 1rem' }} />
                  <div className='next-button'>
                    <Button onClick={handlePrebuiltAudienceNextClick}>Finish Wizard</Button>
                  </div>
                </>
              )}
            </div>
          </Panel>
        </Collapse>
      );
};

export default PrebuiltAudienceCollapse;
