import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from './store';
import fromPairs from 'lodash/fromPairs';

export interface IBrandInterface {
  brandId: number;
  brandName: string;
  __typename?: string;
}

export interface IManufacturerInterface {
  manufacturerId: number;
  manufacturerName: string;
  brands: IBrandInterface[];
  __typename?: string;
}

export interface ICategorizedBrandInterface {
  categoryName: string;
  categoryId: number;
  manufacturers: IManufacturerInterface[];
  __typename?: string;
}

export interface ICategorizedBrandsState {
  mars: ICategorizedBrands;
  competitors: ICategorizedBrands;
}

interface ICategorizedBrands {
  categorizedBrands?: ICategorizedBrandInterface[];
  brandNames?: Record<number, string>;
  manufacturerNames?: Record<number, string>;
}

const initialState: ICategorizedBrandsState = {
  mars:        {},
  competitors: {},
};

export const collectManufacturerNames = ( categorizedBrands: ICategorizedBrandInterface[] ) => fromPairs( categorizedBrands
  .map( ( item ) => item.manufacturers )
  .flat()
  .map( ( item ) => [item.manufacturerId, item.manufacturerName] )
);

export const collectBrandsNames = ( categorizedBrands: ICategorizedBrandInterface[] ) => fromPairs( categorizedBrands
  .map( ( item ) => item.manufacturers )
  .flat()
  .map( ( item ) => item.brands )
  .flat()
  .map( ( item ) => [item.brandId, item.brandName] )
);

export const brandSlice = createSlice( {
  name:     'audience',
  initialState,
  reducers: {
    setMarsBrands: ( state, action ) => {
      state.mars.categorizedBrands = action.payload;
      state.mars.manufacturerNames = collectManufacturerNames( action.payload );
      state.mars.brandNames = collectBrandsNames( action.payload );
    },
    setCompetitorsBrands: ( state, action ) => {
      state.competitors.categorizedBrands = action.payload;
      state.competitors.manufacturerNames = collectManufacturerNames( action.payload );
      state.competitors.brandNames = collectBrandsNames( action.payload );
    },
  },
} );

export const { setMarsBrands, setCompetitorsBrands } = brandSlice.actions;
export default brandSlice.reducer;

export const selectBrands = ( state: RootState ) => state.brands;
export const selectCategorizedMarsBrands = ( state: RootState ) => state.brands.mars.categorizedBrands;
export const selectCategorizedCompetitorsBrands = ( state: RootState ) => state.brands.competitors.categorizedBrands;
export const selectMarsBrands = ( state: RootState ) => state.brands.mars;
export const selectCompetitorsBrands = ( state: RootState ) => state.brands.competitors;
export const selectMarsBrandsByCategoryId = ( categoryId: number = 0 ) => createSelector(
  selectMarsBrands,
  ( brands ) => brands.categorizedBrands
    ?.find( ( brands: ICategorizedBrandInterface ) => brands.categoryId === categoryId )
    ?.manufacturers[0].brands.filter( ( brand ) => brand.brandName )
);
export const selectCompetitorsManufacturersByCategoryId = ( categoryId: number ) => ( state: RootState ) => {
  const competitorsList = state.brands.competitors;
  return competitorsList.categorizedBrands?.find(
    ( category: ICategorizedBrandInterface ) => category.categoryId === categoryId,
  )?.manufacturers;
};

export const selectMarsManufacturer = ( state: RootState ) =>
  state.brands.mars.categorizedBrands?.[0]?.manufacturers
    .find( ( manufacturer ) => manufacturer.manufacturerName === 'Mars Wrigley' )?.manufacturerId;

export const selectMarsBrandNames = ( state: RootState ) => state.brands.mars.brandNames;
export const selectMarsManufacturerNames = ( state: RootState ) => state.brands.mars.manufacturerNames;
export const selectCompetitorBrandNames = ( state: RootState ) => state.brands.competitors.brandNames;
export const selectCompetitorManufacturerNames = ( state: RootState ) => state.brands.competitors.manufacturerNames;


