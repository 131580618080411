import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartOptions,
  ChartData,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { TextPopover } from '../../../../custom/TextPopover/TextPopover';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './BarChartDiagram.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartDataLabels,
  Tooltip
);

interface IBarChartDiagramProps {
  options: ChartOptions<'bar'>;
  data: ChartData<'bar'>;
}

export const BarChartDiagram = ( { options, data }: IBarChartDiagramProps ) => (
  <div className='bar-chart-diagram'>
    <ul className='diagram-labels'>
      {
        data.labels?.map( ( label ) =>
          <li key={label as 'string'} className='label'> <TextPopover maxHeight={36} maxWidth={600} text={label as 'string'} /></li>
        )
      }
    </ul>

    <div className='bar-diagram' style={{ height: ( data.labels?.length || 1 ) * ( 36 + 16 ) }}>
      <Bar options={options} data={data} />
    </div>
  </div>
);
