import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHoverDirty } from 'react-use';

import { IAudienceComparisonItem, IAudienceComparisonState } from '../../../../store/audienceComparison/typings';
import { setChosenAudience, setHoveredId } from '../../../../store/audienceComparison/actions';
import {
  selectAudienceComparisonExclusive,
  selectAudienceComparisonHoveredId,
  selectAudienceComparisonOnly,
  selectAudienceComparisonOverlap,
  selectChosenAudience,
} from '../../../../store/audienceComparison/selectors';
import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import CustomTag from '../../../../custom/CustomTag/CustomTag';
import { comparisonColors, positionToAudienceName } from '../../const';
import { AUDIENCE_ID } from '../../../Blade/collapse/const';

import './ComparisonTable.scss';
import { useAudienceShortName } from '../../AudienceShortName';

export const ComparisonTable = () => {
  const dispatch = useDispatch();

  const audienceComparisonOnly = useSelector( selectAudienceComparisonOnly );
  const audienceComparisonExclusive = useSelector( selectAudienceComparisonExclusive );
  const audienceComparisonOverlap = useSelector( selectAudienceComparisonOverlap );
  const audienceNames = useAudienceShortName();
  const chosenAudience = useSelector( selectChosenAudience );
  const hoveredId = useSelector( selectAudienceComparisonHoveredId );

  const hoverRef = useRef<null | HTMLDivElement>( null );
  const refsList = useRef<Record<string, HTMLDivElement | null>>( {} );

  const isHover = useHoverDirty( hoverRef );

  const onItemClick = ( item: IAudienceComparisonItem ) => {
    dispatch( setChosenAudience( chosenAudience?.segmentId === item.segmentId ? null : item ) );
  };

  const onMouseOverEvent = ( e: any ) => {
    const segment = e.target.closest( '.comparison_list-item' )?.dataset.segment;
    dispatch( setHoveredId( segment ) );
  };

  const renderTags = ( positionsList: AUDIENCE_ID[] ) => positionsList.map( ( name, index ) => (
    <React.Fragment key={name}>
      <CustomTag maxPopoverWidth={640} maxWidth={284} color={comparisonColors[name]} tagValue={audienceNames[name]} key={name} bordered/>
      {index < positionsList?.length - 1 && <span className='item_tags-plus'>+</span>}
    </React.Fragment>
  ) );

  useEffect( () => {
    if ( !isHover ) {
      dispatch( setHoveredId( null ) );
    }
  }, [isHover] );

  useEffect( () => {
    if ( !!chosenAudience?.segmentId ) {
      refsList.current?.[chosenAudience.segmentId]?.scrollIntoView( { behavior: 'smooth', block: 'nearest' } );
    }
  }, [chosenAudience] );

  const renderAudienceComparisonList = (
    audienceComparison: IAudienceComparisonItem[],
    listType: keyof IAudienceComparisonState['stats']
  ) => audienceComparison.map( ( item, index ) => {
    const positionsList = item.position.map( ( name ) => positionToAudienceName[name] );

    const segment = item.segmentId;
    const itemId = [listType, ...item.position].join( '-' );
    return (
      <div
        ref={( el ) => refsList.current[segment] = el }
        data-id={itemId}
        className={cn( 'comparison_list-item', itemId, {
          active:  chosenAudience?.segmentId === segment,
          hovered: hoveredId === segment,
        } )}
        data-segment={segment}
        onClick={() => onItemClick( item )}
        key={segment}
      >
        <div className='audience'>
          <span className='audience_category'>{listType}</span>
          {item.membersNumber.toLocaleString( 'en-US' )}
        </div>
        <div className='item_tags'>
          {renderTags( positionsList )}
        </div>
      </div>
    );
  } );

  return (
    <div className={`comparison_list ${chosenAudience?.segmentId ? 'chosen' : ''}`}>
      <div className='comparison_list-title'>
        <TitleWithCaption headingLevel={5} heading='Comparison List' />
      </div>

      <div className='comparison_list-scrollable' ref={hoverRef} onMouseOver={onMouseOverEvent}>
        {renderAudienceComparisonList( audienceComparisonOnly, 'only' )}
        {renderAudienceComparisonList( audienceComparisonOverlap, 'overlap' )}
        {renderAudienceComparisonList( audienceComparisonExclusive, 'exclusive' )}
      </div>
    </div>
  );
};
