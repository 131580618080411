import './Summary.scss';
import { Space } from 'antd';
import { useSelector } from 'react-redux';
import CustomTag from '../../../custom/CustomTag/CustomTag';
import TitleWithCaption from '../../../custom/TitleWithCaption/TitleWithCaption';
import VSTag from '../../../custom/VSTag';
import { AUDIENCE_ID, TAB_HEADERS } from '../collapse/const';
import { selectBenchmarkWizard, selectComparisonWizard, selectSecondComparisonWizard, selectTabs } from '../WizardTabs/selectors';

const Summary = () => {
  const tabs = useSelector( selectTabs );
  const benchmarkWizard: { [key: string]: any } = useSelector( selectBenchmarkWizard );
  const comparisonWizard: { [key: string]: any } = useSelector( selectComparisonWizard );
  const secondComparisonWizard: { [key: string]: any } = useSelector( selectSecondComparisonWizard );

  const generateEmptyTag = ( audience: string ) => (
    <CustomTag tagValue={[`${audience}: Not Selected`]} style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
  );

  const bmTags = Object.keys( benchmarkWizard )
    .map( ( item: string ) => benchmarkWizard[item].tag.map( ( item: any ) => item.value ) )
    .filter( ( item ) => item.length > 0 )
    .flat();
  const cmpTags = Object.keys( comparisonWizard )
    .map( ( item: string ) => comparisonWizard[item].tag.map( ( item: any ) => item.value ) )
    .filter( ( item ) => item.length > 0 )
    .flat();
  const sndCmpTags = Object.keys( secondComparisonWizard )
    .map( ( item: string ) => secondComparisonWizard[item].tag.map( ( item: any ) => item.value ) )
    .filter( ( item ) => item.length > 0 )
    .flat();

  const benchmarkTags = tabs[AUDIENCE_ID.BENCHMARK]?.isFinished
    ? bmTags.map( ( tag, index ) => <CustomTag tagValue={tag} key={`bmTags-${AUDIENCE_ID.BENCHMARK}-${index}`}/> )
    : generateEmptyTag( TAB_HEADERS[AUDIENCE_ID.BENCHMARK] );
  const comparisonTags = tabs[AUDIENCE_ID.COMPARISON]?.isFinished
    ? cmpTags.map( ( tag, index ) => <CustomTag tagValue={tag} key={`cmpTags-${AUDIENCE_ID.BENCHMARK}-${index}`}/> )
    : generateEmptyTag( TAB_HEADERS[AUDIENCE_ID.COMPARISON] );
  const secondComparisonTags = tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isFinished
    ? sndCmpTags.map( ( tag, index ) => <CustomTag tagValue={tag} key={`sndCmpTags-${AUDIENCE_ID.BENCHMARK}-${index}`} /> )
    : generateEmptyTag( TAB_HEADERS[AUDIENCE_ID.SECOND_COMPARISON] );

  return (
    <div className='summary-wizard'>
      <TitleWithCaption heading='Summary' />
      <Space className='collapse-tag__wrapper summary-tags' wrap style={{ maxWidth: 640, display: 'flex', gap: 0 }}>
        {benchmarkTags}
        <VSTag />
        {comparisonTags}
        {tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isVisible && (
          <>
            <VSTag />
            {secondComparisonTags}
          </>
        )}
      </Space>
    </div>
  );
};

export default Summary;
