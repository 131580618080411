import { ChartData, ChartOptions } from 'chart.js';
import { BenchmarkChartAttributeValue } from '../../../../apollo/graphql-types';

export const optionsBenchmark: ChartOptions<'bar'> = {
  indexAxis:           'y',
  responsive:          true,
  maintainAspectRatio: false,
  layout:              {
    padding: {
      right:  40,
      bottom: -20,
    },
  },
  plugins:             {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      anchor:    'end',
      align:     'right',
      offset:    5,
      formatter: function ( value ) {
        return value > 0 ? value + '%' : 'Value is not present';
      },
      color: 'rgba(0, 0, 0, 0.88)',
    },
  },
  scales: {
    x:             {
      min:         0,
      max:         100,
      border:      {
        display: false,
      },
      ticks: {
        display: false,
      },
      grid: {
        color:  ( ctx ) => ctx.tick.value === 100 ? 'transparent' : 'rgba(0, 0, 0, 0.15)',
      },
    },
    y: {
      grid:        {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

export const generateBenchmarkData = ( data: BenchmarkChartAttributeValue[] ): ChartData<'bar'> => {
  const labels = data.map( ( item ) => item.name );
  const fillRateData = data.map( ( item ) => +item.fillRate );

  return {
    labels,
    datasets:      [
      {
        data:               fillRateData,
        backgroundColor:    'rgba(0, 0, 160, .85)',
        categoryPercentage: 1,
        barPercentage:      1,
        borderWidth:        8,
        borderColor:        'transparent',
      },
    ],
  };
};
