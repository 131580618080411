import { useMemo } from 'react';
import { draw } from 'patternomaly';
import { ChartOptions, Tooltip } from 'chart.js';

import { BenchmarkChartAttribute, DivergentChartAttribute, SegmentCalculationAnnotation } from '../../../../apollo/graphql-types';
import { BarChartDiagram } from '../BarChartDiagram/BarChartDiagram';
import { AUDIENCE_ID, AUDIENCE_TO_AUDIENCE_ID } from '../../../Blade/collapse/const';
import { externalTooltipHandler } from '../BarChartDiagram/tooltip';

interface IBarChartComparisonContentProps {
  comparisonAttribute: DivergentChartAttribute;
  comparisonSegments: SegmentCalculationAnnotation[];
  noBenchmarkCoverage: boolean;
  benchmarkAttribute: BenchmarkChartAttribute | undefined;
}

Tooltip.positioners.custom = function ( elements, eventPosition ) {
  return eventPosition;
};

export const BarChartComparisonContent = ( {
  comparisonAttribute,
  comparisonSegments,
  noBenchmarkCoverage,
  benchmarkAttribute,
}: IBarChartComparisonContentProps ) => {
  const data = useMemo( () => {
    const labelsComparison = comparisonAttribute.comparisonValues?.map( ( item ) => item?.valueName );

    const dataset: any = comparisonSegments.map( ( segment ) => {
      if ( comparisonAttribute.lowCoverage?.find( ( low ) => low.segmentId === segment.segmentId && low.coverageValue === 0 ) ) {
        return null;
      }

      const barColor = AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === AUDIENCE_ID.COMPARISON
        ? 'rgba(34, 178, 121, 0.85)'
        : 'rgba(122, 54, 190, 0.85)';
      const barHashedColor = AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === AUDIENCE_ID.COMPARISON
        ? 'rgba(34, 178, 121, 0.50)'
        : 'rgba(122, 54, 190, 0.50)';

      return {
        data:               comparisonAttribute.comparisonValues?.map( ( value ) => {
          const bar = value.bars.filter( ( bar ) => bar.segmentId === segment.segmentId )?.[0];
          const benchmarkValue = benchmarkAttribute?.values
            .find( ( benchmarkValue ) => benchmarkValue.name === value.valueName )?.fillRate || '';

          return ( {
            benchmarkValue,
            valueName:          value.valueName,
            divergentRatio:     +benchmarkValue > 0 && bar.attributeCount > 0 && bar.divergentRatio === 0
              ? [-2, 2]
              : bar.divergentRatio,
            attributeCount:     bar.attributeCount,
          } );
        } ),
        backgroundColor:     ( ctx: any ) => {
          const index = ctx.dataset.data[ctx.dataIndex]?.divergentRatio || 0;

          if ( !noBenchmarkCoverage && typeof index === 'number' && Math.abs( index ) < 30 ) {
            return draw( 'diagonal-right-left', barHashedColor, 'rgb(255, 255, 255)', 8 );
          }

          return barColor;
        },
        parsing: {
          xAxisKey:  'divergentRatio',
          yAxisKey: 'valueName',
        },
        categoryPercentage: 0.8,
        barPercentage:      0.9,
        borderWidth:        0,
        borderColor:        ( ctx: any ) => {
          const index = ctx.dataset.data[ctx.dataIndex]?.divergentRatio || 0;

          if ( typeof index === 'number' && Math.abs( index ) < 30 ) {
            return barHashedColor;
          }
        },
      };
    } ).filter( ( segment ) => !!segment );

    return {
      labels:   labelsComparison,
      datasets: dataset,
    };
  }, [comparisonAttribute, comparisonSegments] );

  const options: ChartOptions<'bar'> = {
    indexAxis:           'y',
    responsive:          true,
    maintainAspectRatio: false,
    layout:              {
      padding: {
        bottom: -20,
        right:  noBenchmarkCoverage ? 40 : 0,
      },
    },
    plugins:             {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled:  false,
        position: 'custom',
        external: noBenchmarkCoverage ? undefined : externalTooltipHandler,
        boxWidth: 150,
      },
      datalabels: {
        anchor:    'end',
        align:     'right',
        offset:    5,
        formatter: function ( value ) {
          if ( noBenchmarkCoverage ) {
            return value.divergentRatio > 0 ? value.divergentRatio + '%' : 'Value is not present';
          }

          return +value.benchmarkValue === 0 && value.divergentRatio === 0 ? 'Value is not present' : '';
        },
        color: 'rgba(0, 0, 0, 0.88)',
      },
    },
    scales: {
      x: {
        min:    noBenchmarkCoverage ? 0 : -100,
        max:    100,
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        grid: {
          color: ( ctx ) => {
            if ( noBenchmarkCoverage ) {
              return ctx.tick.value === 100 ? 'transparent' : 'rgba(0, 0, 0, 0.15)';
            }

            return ctx.tick.value === 0 ? 'rgba(0, 0, 0, 0.1)' : 'transparent';
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return <BarChartDiagram
    options={options}
    data={data}
  />;
};
