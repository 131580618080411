import { ArcProps, Chart, ChartDataset, ChartMeta, ChartType } from 'chart.js';
import { fontString } from 'chart.js/helpers';

export interface IChartItem {
  value?: number;
  label?: string;
}

export interface IOuterLabelsConfig {
  offset?: number;
  padding?: number;
  fontNormalColor?: string;
  fontNormalStyle?: string;
  fontNormalSize?: number;
  fontNormalFamily?: string;
  enable?: boolean;
  formatter?: ( item: IChartItem ) => string;
}

interface IPoint {
  x: number;
  y: number;
  angle: number;
  segmentAngle?: number;
  index?: number;
  taken?: boolean;
  label?: string;
  middle?: boolean;
}

interface ICoordinates {
  x: number;
  y: number;
}

interface ICircle {
  radius: number;
  center: ICoordinates;
}

interface ILine {
  p1: ICoordinates;
  p2: ICoordinates;
}


// Extend chart.js types with outerLabels plugin
declare module 'chart.js' {
  interface Chart { //eslint-disable-line
    outerLabels: OuterLabels;
  }

  interface PluginOptionsByType<TType extends ChartType> {//eslint-disable-line
    outerLabels?: IOuterLabelsConfig | false;
  }
}

class OuterLabels {
  private chart: Chart<'doughnut'>;
  private config: IOuterLabelsConfig;
  private ctx: CanvasRenderingContext2D;
  private meta: ChartMeta;
  private points: IPoint[];
  private model: IPoint[];

  public init ( chartInstance: Chart ) {
    this.chart = chartInstance as Chart<'doughnut'>;
    this.ctx = chartInstance.ctx;
  }

  public configure ( config: IOuterLabelsConfig ) {
    this.config = config;
  }

  public resolveDataset () {
    const dataset = this.chart.config.data.datasets[0];
    const view = this.chart.getDatasetMeta( 0 );
    this.meta = view;
    this.points = [];

    this.generatePoints( view.data[0] as any );

    if ( !this.points.length ) {
      return;
    }

    this.model = this.resolve( dataset, view );
  }

  public generatePoints ( view: ArcProps ) {
    const startY = view.y - ( view.outerRadius + this.config.offset! ) + 30;
    const endY = view.y + ( view.outerRadius + this.config.offset! ) - 30;
    let n = startY + 1;

    const right = [] as IPoint[];
    const left = [] as IPoint[];

    const line: ILine = {
      p1: { x: 0, y: n },
      p2: { x: 999, y: n },
    };

    const circle: ICircle = {
      radius: view.outerRadius + this.config.offset!,
      center: { x: view.x, y: view.y },
    };

    while ( n < endY && n < 1000 ) {
      const intersection = this.intersectCircleLine( circle, line );

      for ( let i = 0; i < intersection.length; ++i ) {
        const point = intersection[i];
        const angle = this.getAngle( view, point );

        // const ctx = this.ctx;
        // ctx.beginPath();
        // ctx.moveTo( point.x, point.y );
        // ctx.arc( point.x, point.y, 2, 0, 2 * Math.PI, true );
        // ctx.fill();

        const data: IPoint = {
          x:      point.x,
          y:      point.y,
          angle,
          middle: false,
        };

        if ( i % 2 === 0 ) {
          left.push( data );
        } else {
          right.push( data );
        }
      }

      n += this.config.fontNormalSize! + this.config.padding!;

      line.p1.y = n;
      line.p2.y = n;
    }

    // Flag that the middle points can be vertically centred
    const leftMiddleIndex = Math.round( ( left.length - 1 ) / 2 );
    const rightMiddleIndex = Math.round( ( right.length - 1 ) / 2 );
    if ( left[leftMiddleIndex] ) {
      left[leftMiddleIndex].middle = true;
    }
    if ( right[rightMiddleIndex] ) {
      right[rightMiddleIndex].middle = true;
    }

    // ADDITIONAL POINTS, top, bottom
    let iterator = left[0].x + 20;

    const leftAdditional = [] as IPoint[];
    const rightAdditional = [] as IPoint[];

    const verticalLine: ILine = {
      p1: { y: 0, x: iterator },
      p2: { y: 999, x: iterator },
    };

    while ( iterator < right[0].x - 16 ) {
      const intersection = this.intersectCircleLine( circle, verticalLine );

      for ( let i = 0; i < intersection.length; ++i ) {
        const point = intersection[i];
        const angle = this.getAngle( view, point );

        const data = {
          x:      point.x,
          y:      point.y,
          angle,
          middle: false,
        };

        if ( view.x > point.x ) {
          leftAdditional.push( data );
        } else {
          rightAdditional.push( data );
        }
      }

      iterator += 16;

      verticalLine.p1.x = iterator;
      verticalLine.p2.x = iterator;
    }

    const leftFinal = leftAdditional.reduce( ( acc, point, index ) => {
      if ( index < 2 ) {
        acc.push( point );
        return acc;
      }

      const suffix = view.y < point.y ? 1 : -1;
      point.y = point.y + index * 5 * suffix;
      point.angle = this.getAngle( view, { x: point.x, y: point.y } );
      acc.push( point );

      return acc;
    }, [] as IPoint[] );

    const rightFinal = rightAdditional.reduce( ( acc, point, index ) => {
      point.y = leftFinal[leftFinal.length - 1 - index].y;
      point.angle = this.getAngle( view, { x: point.x, y: point.y } );
      acc.push( point );

      return acc;
    }, [] as IPoint[] );

    this.points = [...left, ...leftFinal, ...right, ...rightFinal];
  }

  // Source: https://stackoverflow.com/a/37225895
  public intersectCircleLine ( circle: ICircle, line: ILine ) {
    const v1 = {} as ICoordinates;
    const v2 = {} as ICoordinates;
    v1.x = line.p2.x - line.p1.x;
    v1.y = line.p2.y - line.p1.y;
    v2.x = line.p1.x - circle.center.x;
    v2.y = line.p1.y - circle.center.y;
    let a = v1.x * v2.x + v1.y * v2.y;
    const b = 2 * ( v1.x * v1.x + v1.y * v1.y );
    a *= -2;
    const c = Math.sqrt( a * a - 2 * b * ( v2.x * v2.x + v2.y * v2.y - circle.radius * circle.radius ) );
    if ( isNaN( c ) ) {
      // No intercept
      return [];
    }
    const u1 = ( a - c ) / b; // These represent the unit distance of point one and two on the line
    const u2 = ( a + c ) / b;
    const retP1 = {} as ICoordinates; // Return points
    const retP2 = {} as ICoordinates;
    const ret = []; // Return array
    if ( u1 <= 1 && u1 >= 0 ) {
      // Add point if on the line segment
      retP1.x = line.p1.x + v1.x * u1;
      retP1.y = line.p1.y + v1.y * u1;
      ret[0] = retP1;
    }
    if ( u2 <= 1 && u2 >= 0 ) {
      // Second add point if on the line segment
      retP2.x = line.p1.x + v1.x * u2;
      retP2.y = line.p1.y + v1.y * u2;
      ret[ret.length] = retP2;
    }
    return ret;
  }

  public resolve ( dataset: ChartDataset<'doughnut'>, meta: ChartMeta ) {
    const labels = [];

    // Match each chart segment to a point
    for ( let i = 0; i < meta.data.length; ++i ) {
      const view: ArcProps = meta.data[i] as any;

      const a = ( view.endAngle - view.startAngle ) / 2;
      const segmentAngle = view.startAngle + a;

      const p = this.closest( this.points, segmentAngle );
      const index = this.points.indexOf( p );

      const labelPoint = {
        ...p,
        segmentAngle,
        index,
      };

      this.points[index].taken = true;

      labels.push( labelPoint );
    }

    // Add labels
    labels.sort( ( a, b ) => a.angle - b.angle );
    for ( let i = 0; i < labels.length; ++i ) {
      labels[i].label = !!this.config.formatter
        ? this.config.formatter( {
          label: this.chart.config.data.labels?.[i] as string,
          value: dataset.data[i],
        } )
        : undefined;
    }

    return labels;
  }

  public getAngle ( origin: ArcProps, point: ICoordinates ) {
    let angle = Math.atan2( point.y - origin.y, point.x - origin.x );

    if ( angle < this.radians( -90 ) ) {
      angle += this.radians( 360 );
    }

    return angle;
  }

  public drawLabels () {
    if ( !this.model?.length ) {
      return;
    }
    for ( let i = 0; i < this.model.length; ++i ) {
      this.drawSingleLine( this.meta.data[i] as any, this.model[i] );
    }
  }

  public drawSingleLine ( view: ArcProps, point: IPoint ) {
    const ctx = this.ctx;
    const index = point.label?.indexOf( ' ' ) || 0;
    const value = point.label?.substring( 0, index ) || '';
    let label = point.label?.substring( index + 1 ) || '';
    const canvasLeft = ctx.canvas.clientLeft;
    const canvasWidth = ctx.canvas.clientWidth;

    if ( label === 'no_data' ) {
      label = 'Data unavailable or unspecified for this portion of the audience';
    }

    const a = ( view.endAngle - view.startAngle ) / 2;
    const segmentAngle = view.startAngle + a;

    const x = view.x + view.outerRadius * Math.cos( segmentAngle );
    const y = view.y + view.outerRadius * Math.sin( segmentAngle );

    if ( view.circumference === 0 ) {
      return;
    }

    const valueText = ` (${value}%)`;
    const valueWidth = ctx.measureText( valueText ).width;

    const labelWidth = ctx.measureText( label ).width;

    const startX = point.x;
    let valueX, labelX;

    ctx.fillStyle = this.config.fontNormalColor!;
    ctx.font = fontString(
      this.config.fontNormalSize!,
      this.config.fontNormalStyle!,
      this.config.fontNormalFamily!
    );
    // Calculate drawing origin
    ctx.textBaseline = 'middle';

    if ( point.x < view.x ) {
      ctx.textAlign = 'right';
      valueX = startX - 5;
      labelX = startX - valueWidth - 5;

      if ( labelX - labelWidth < canvasLeft ) {
        while( labelX - ctx.measureText( label ).width < canvasLeft && ctx.measureText( label ).width > 30 ) {
          label = label.substr( 0, label.length - 1 );
        }
        label = label.substr( 0, label.length - 3 );
        label += '...';
      }
    } else {
      ctx.textAlign = 'left';
      valueX = startX + labelWidth + 5;
      labelX = startX + 5;

      if ( labelX + labelWidth + valueWidth > canvasWidth ) {
        while( labelX + ctx.measureText( label ).width + valueWidth > canvasWidth && ctx.measureText( label ).width > 30 ) {
          label = label.substr( 0, label.length - 1 );
        }
        label = label.substr( 0, label.length - 3 );
        label += '...';
        valueX = startX + ctx.measureText( label ).width + 5;
      }
    }

    // Draw label
    ctx.fillText( label, labelX, point.y );

    // Draw value
    ctx.fillText( valueText, valueX, point.y );

    // Draw line to segment
    ctx.beginPath();
    ctx.moveTo( point.x, point.y );
    ctx.lineTo( x, y );
    ctx.strokeStyle = '#595959';
    ctx.stroke();

    ctx.restore();
  }

  public closest ( arr: IPoint[], goal: number ) {
    const filtered = arr.filter( ( n ) => !n.taken );

    return filtered.reduce( ( prev, curr ) => Math.abs( curr.angle - goal ) < Math.abs( prev.angle - goal ) ? curr : prev );
  }

  public radians ( degrees: number ) {
    return ( degrees * Math.PI ) / 180;
  }

  public degrees ( radians: number ) {
    return ( radians * 180 ) / Math.PI;
  }
}

Chart.register( {
  id:         'outerLabels',
  beforeInit: ( chart ) => {
    if ( chart.options.plugins?.outerLabels && chart.options.plugins?.outerLabels.enable ) {
      chart.outerLabels = new OuterLabels();
      chart.outerLabels.init( chart );
      chart.outerLabels.configure( chart.options.plugins?.outerLabels );
    }
  },
  afterDatasetsDraw: ( chart ) => {
    if ( chart.outerLabels && chart.config.data.datasets[0] ) {
      chart.outerLabels.resolveDataset();
      chart.outerLabels.drawLabels();
    }
  },
  afterUpdate: ( chart ) => {
    if ( chart.outerLabels && chart.config.data.datasets[0] ) {
      chart.outerLabels.resolveDataset();
      chart.outerLabels.drawLabels();
    }
  },
  // Disable scriptable options, i.e. formatter should be a callback
  // (not in the TS types for some reason)
  descriptors: {
    _indexable:  false,
    _scriptable: false,
  } as any,
} );
