import React, { useEffect, useState } from 'react';
import { ALL_BUYER_TYPES, AUDIENCE_ID, options } from '../Blade/collapse/const';
import isEmpty from 'lodash/isEmpty';

import { selectAppliedAudiencesToCompare } from '../../store/audienceComparison/selectors';
import {
  selectCategorizedMarsBrands,
  selectCompetitorBrandNames,
  selectCompetitorManufacturerNames,
  selectMarsBrandNames,
  setCompetitorsBrands,
  setMarsBrands,
} from '../../store/brandSlice';
import { partyData, webLinks } from './const';
import { EPartyData } from '../Blade/collapse/OriginCollapse/const';
import { ICampaignStructure, selectAllMarketingCampaigns, setAllCampaignsList } from '../../store/campaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Entries } from '../../store/typings';
import { buyerTypeToTagName, engagementToTagName } from '../Blade/collapse/AdditionalParametersCollapse/const';
import {
  PreBuiltAudienceNamesQuery,
  useBrandMarketingCampaignsLazyQuery,
  useGetBrandsLazyQuery,
  usePreBuiltAudienceNamesLazyQuery,
} from '../../apollo/graphql-types';
import { isEqual } from 'lodash';

import { BuyerType } from '../../typings';

export const getBuyerTypeName = ( buyerTypes: BuyerType[] ) => {
  if ( buyerTypes.length === 5 ) {
    return ALL_BUYER_TYPES;
  }

  const buyers = buyerTypes.map( ( bt ) => buyerTypeToTagName[bt] ).join( ', ' );

  if ( buyerTypes.length === 1 && buyerTypes[0] === BuyerType.NonBuyer ) {
    return buyers;
  }

  return buyers.concat( ' Buyer' );
};

export const useAudienceShortName = () => {
  const dispatch = useDispatch();
  const audiencesToCompare = useSelector( selectAppliedAudiencesToCompare );

  const marsBrandNames = useSelector( selectMarsBrandNames );
  const competitorBrandNames = useSelector( selectCompetitorBrandNames );
  const competitorManufacturerNames = useSelector( selectCompetitorManufacturerNames );
  const categorizedMarsBrands = useSelector( selectCategorizedMarsBrands );
  const allMarketingCampaigns = useSelector( selectAllMarketingCampaigns );

  const [preBuiltAudiences, setPreBuiltAudiences] = useState<PreBuiltAudienceNamesQuery['preBuiltAudienceNames']>( [] );
  const [campaigns, setCampaigns] = useState<Record<AUDIENCE_ID, ICampaignStructure | undefined>>( allMarketingCampaigns );
  const [names, setNames] = useState(
    { [AUDIENCE_ID.BENCHMARK]: 'loading', [AUDIENCE_ID.COMPARISON]: 'loading', [AUDIENCE_ID.SECOND_COMPARISON]: 'loading' }
  );

  const [loadPreBuiltAudiences] = usePreBuiltAudienceNamesLazyQuery( options() );
  const [loadMarsBrands] = useGetBrandsLazyQuery( options() );
  const [loadCompetitors] = useGetBrandsLazyQuery( { ...options(), variables: { isCompetitor: true } } );
  const [loadCampaigns] = useBrandMarketingCampaignsLazyQuery( options() );

  const renderName = () => ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> )
    .filter( ( audience ) => !!audience[1]?.audienceParameters || !!audience[1]?.preBuiltAudienceIds )
    .reduce( ( acc, audience ) => {
      const [audienceKey, audienceValue] = audience;
      const audienceParameters = audienceValue.audienceParameters;
      const preBuiltAudienceIds = audienceValue.preBuiltAudienceIds;

      if ( !!preBuiltAudienceIds ) {
        const preBuildValues = preBuiltAudienceIds.map( ( id ) =>
          preBuiltAudiences?.find( ( audience ) => audience.id === id )?.name || 'loading' );

        acc[audienceKey] = preBuildValues.join( preBuildValues.length < 2 ? '' : ', ' );
      }

      if ( !!audienceParameters ) {
        if ( ![18, 32].includes( audienceParameters?.manufacturer || 0 ) ) {
          const competitorsBrandsNames = audienceParameters?.brands?.map( ( brand ) => competitorBrandNames?.[brand] ).join( ', ' ) || '';
          const manufacturer = audienceParameters.manufacturer || 0;
          const manufacturerName = competitorManufacturerNames?.[manufacturer] || '';

          acc[audienceKey] = `${manufacturerName} ${competitorsBrandsNames} ${getBuyerTypeName( audienceParameters.buyerTypes || [] )}`; // eslint-disable-line
        } else {
          const originValue = () => {
            if ( audienceParameters.firstParty === true ) {
              return EPartyData.firstParty;
            } else if ( audienceParameters.thirdParty === true ) {
              return EPartyData.thirdParty;
            }

            return EPartyData.none;
          };
          const campaignsList = campaigns[audienceKey]?.no;
          const marketingCampaigns = audienceParameters?.marketingCampaigns;
          const engagement = !!audienceParameters?.engagementStatus ? engagementToTagName[audienceParameters.engagementStatus] : '';
          const d2c = audienceParameters?.d2c;
          const origin = originValue() !== EPartyData.none && partyData[originValue()];
          const category = audienceParameters?.category;
          const selectedCategoryName = categorizedMarsBrands
            ?.find( ( categoryItem ) => category === categoryItem.categoryId )?.categoryName || '';
          const categorizedBrandsByCategory = categorizedMarsBrands
            ?.find( ( categoryItem ) => categoryItem.categoryId === category )?.manufacturers[0].brands;

          const campaignsToShow = marketingCampaigns?.map( ( id ) =>
            campaignsList?.find( ( campaign ) => campaign.id === id )?.name ).filter( ( item ) => !!item ).join( ', ' );
          let brandNamesToShow = audienceParameters?.brands
            ?.filter( ( brand ) => {
              if ( marketingCampaigns?.length || 0 > 0 ) {
                return !!marketingCampaigns?.map( ( selectedCampaign ) =>
                  campaignsList?.find( ( c ) => c.id === selectedCampaign && c.brandName === marsBrandNames?.[brand] ) )
                  .filter( ( item ) => !!item ).length;
              } else if ( !!d2c ) {
                return !!webLinks[brand];
              } else if ( originValue() === EPartyData.firstParty ) {
                const hasCampaigns = !!campaignsList?.find( ( c ) => c.brandName === marsBrandNames?.[brand] );
                const hasD2C = !!campaigns[audienceKey]?.yes?.find( ( c ) => c.brandName === marsBrandNames?.[brand] );

                return hasCampaigns || hasD2C;
              }

              return true;
            } )
            .map( ( brand ) => !!d2c ? webLinks[brand] : marsBrandNames?.[brand] )
            .join( ', ' );

          if ( ( marketingCampaigns?.length && !campaignsToShow?.length ) || ( !brandNamesToShow.length ) ) {
            acc[audienceKey] = 'loading';

            return acc;
          }

          brandNamesToShow = brandNamesToShow?.split( ',' ).length === categorizedBrandsByCategory?.length
            ? `${selectedCategoryName} All Brands`
            : brandNamesToShow;
          acc[audienceKey] = `${brandNamesToShow} ${getBuyerTypeName( audienceParameters?.buyerTypes || [] )} ${!!campaignsToShow?.length ? campaignsToShow + ' ' + engagement : !!origin && !d2c && !marketingCampaigns ? origin : ''}`.trim();// eslint-disable-line
        }
      }

      return acc;
    }, {} as Record<AUDIENCE_ID, string> );

  useEffect( () => {
    if ( !!audiencesToCompare ) {
      const audiences = ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> )
        .filter( ( [key, audience] ) => !!audience );
      let hasPreBuiltAudiences = false;
      let hasMarsBrands = false;
      let hasCompetitors = false;
      let hasCampaings = false;
      const selectedMarsBrands: Partial<Record<AUDIENCE_ID, number[]>> = {};

      audiences.map( ( [key, audience] ) => {
        if ( !!audience?.preBuiltAudienceIds ){
          hasPreBuiltAudiences = true;
        }

        if ( !!audience.audienceParameters ) {
          if ( [18, 32].includes( audience.audienceParameters.manufacturer || 0 ) ) {
            hasMarsBrands = true;

            hasCampaings = !hasCampaings
              ? ( !!audience.audienceParameters.marketingCampaigns?.length || !!audience.audienceParameters.firstParty )
              : true;
            selectedMarsBrands[key] = audience.audienceParameters.brands;
          } else {
            hasCompetitors = true;
          }
        }
      } );

      const loadAdditionalData = async () => {
        if ( hasPreBuiltAudiences && !preBuiltAudiences.length ) {
          const resolve = await loadPreBuiltAudiences();
          setPreBuiltAudiences( resolve.data?.preBuiltAudienceNames || [] );
        }

        if ( hasCompetitors && isEmpty( competitorBrandNames ) ) {
          const resolve = await loadCompetitors();
          dispatch( setCompetitorsBrands( resolve.data?.categorizedBrands ) );
        }

        if ( hasMarsBrands && isEmpty( marsBrandNames ) ) {
          const resolve = await loadMarsBrands();
          dispatch( setMarsBrands( resolve.data?.categorizedBrands ) );
        }

        if ( hasCampaings && !isEmpty( marsBrandNames ) ) {
          const brandsToLoad = ( selectedBrands: number[] ) => selectedBrands?.map( ( brandId ) => {
            const name = marsBrandNames[brandId];
            return {
              id: brandId,
              name,
            };
          } );

          if ( !!selectedMarsBrands.AudienceA && !campaigns[AUDIENCE_ID.BENCHMARK] ) {
            const resolve = await loadCampaigns( { variables: { brands: brandsToLoad( selectedMarsBrands.AudienceA ) } } );
            setCampaigns( { ...campaigns, [AUDIENCE_ID.BENCHMARK]: resolve.data?.brandMarketingCampaigns } );

            dispatch( setAllCampaignsList( { activeTab: AUDIENCE_ID.BENCHMARK, campaigns: resolve.data?.brandMarketingCampaigns } ) );
          }

          if ( !!selectedMarsBrands.AudienceB && !campaigns[AUDIENCE_ID.COMPARISON] ) {
            const resolve = await loadCampaigns( { variables: { brands: brandsToLoad( selectedMarsBrands.AudienceB ) } } );
            setCampaigns( { ...campaigns, [AUDIENCE_ID.COMPARISON]: resolve.data?.brandMarketingCampaigns } );
            dispatch( setAllCampaignsList( { activeTab: AUDIENCE_ID.COMPARISON, campaigns: resolve.data?.brandMarketingCampaigns } ) );
          }

          if ( !!selectedMarsBrands.AudienceC && !campaigns[AUDIENCE_ID.SECOND_COMPARISON] ) {
            const resolve = await loadCampaigns( { variables: { brands: brandsToLoad( selectedMarsBrands.AudienceC ) } } );
            setCampaigns( { ...campaigns, [AUDIENCE_ID.SECOND_COMPARISON]: resolve.data?.brandMarketingCampaigns } );
            dispatch( setAllCampaignsList( {
              activeTab: AUDIENCE_ID.SECOND_COMPARISON,
              campaigns: resolve.data?.brandMarketingCampaigns,
            } ) );
          }
        }
        const newNames = renderName();
        if ( !isEqual( newNames, names ) ) {
          setNames( newNames );
        }
      };

        loadAdditionalData().catch( ( error ) => console.log( error ) ); // eslint-disable-line
    }

  }, [marsBrandNames, audiencesToCompare, campaigns, preBuiltAudiences, competitorBrandNames] );

  return names;
};
