import { flatten, isArray, uniq } from 'lodash';
import { FilterAttribute,
  FilterAttributeCategory,
  FilterAttributeGroup,
  FilteringAttributesQuery,
} from '../../../../apollo/graphql-types';

export function customizer ( objValue: any, srcValue: any ) {
  if ( isArray( objValue ) ) {
    return uniq( objValue.concat( srcValue ) );
  }
}

export const checkSearchValue = ( title: number | string, searchText: string ) =>
  !!searchText.length && title.toString().toLowerCase().includes( searchText.toLowerCase() );

export const getSelectedKeysCount = ( checkedKeys: string[], category: FilterAttributeCategory ) => {
  const attributeGroupsWithChildren = category.attributeGroups
    ?.filter( ( attributeGroup ) => !!attributeGroup.attributes?.length )
    .map( ( attributeGroup ) => ( `GROUP - ${attributeGroup.name}` ) );

  return checkedKeys
    ?.filter( ( checked ) => !!attributeGroupsWithChildren?.length ? !attributeGroupsWithChildren?.includes( checked ) : true )
    ?.length || 0;
};


export const getDefaultExpandedAndCheckedKeys = ( data: FilteringAttributesQuery['filteringAttributes'] = [] ) =>
  data.reduce( ( acc, category ) => {
    if ( !category ) return acc;

    const attributes = flatten<FilterAttribute | FilterAttributeGroup>( [category.attributes || [], category.attributeGroups || []] );

    const defaultExpandedKeys: string[] = [];

    const defaultKeys = flatten( attributes.map( ( attributeGroup ) => {
      if ( 'attributes' in attributeGroup && !!attributeGroup.attributes?.length ) {
        return attributeGroup.attributes.map( ( attr ) => {
          if ( attr.default ) {
            defaultExpandedKeys.push( `GROUP - ${attributeGroup.name}` );
            return `${attr.techNameId}`;
          }

          return '';
        } ) || '';
      }

      if ( 'default' in attributeGroup && attributeGroup.default ) {
        defaultExpandedKeys.push( attributeGroup.techNameId );

        return attributeGroup.techNameId;
      }

      return '';
    } ) ).filter( ( key ) => !!key );

    if ( !!defaultKeys.length ) {
      acc[0][category.name] = defaultKeys;
    }

    if ( !!defaultExpandedKeys.length ) {
      acc[1][category.name] = defaultExpandedKeys;
    }

    return acc;
  }, [{} as Record<string, string[]>, {} as Record<string, string[]>] );
