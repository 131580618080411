import { ExportOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { attributesExcelAPI } from '../../../apollo/apollo';
import * as XLSX from 'xlsx';
import { InitiateAttributesCalculationsMutation } from '../../../apollo/graphql-types';
import { pick } from 'lodash';
import { useAudienceShortName } from '../AudienceShortName';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from '../../../utils/cookie';

interface IExportProps {
  requestId?: string;
  loadedQueries?: string[];
  loading: boolean;
  attributesData?: InitiateAttributesCalculationsMutation | null;
}

export const ExportXLSX = ( { requestId, loading, loadedQueries, attributesData }: IExportProps ) => {
  const ref = useRef( null );
  const [downloading, setDownloading] = useState( false );
  const [messageApi, contextHolder] = message.useMessage();
  const audienceNames = useAudienceShortName();
  const navigate = useNavigate();

  const errorMessage = () => {
    messageApi.open( {
      type:    'error',
      content: 'XLSX creation failed, please try again later',
    } );
  };

  const onDownloadClick = async () => {
    try {
      if ( !requestId ) {
        throw new Error( 'request_id wasb\'t provided' );
      }

      const queriesToCheck = Object.values( pick( attributesData?.initiateAttributesCalculations, ['exclusive', 'only', 'overlap'] ) )
        .reduce( ( acc, item ) => {
          if ( typeof item === 'string' ) {
            return acc;
          }

          acc.push( ...item.map( ( subItem ) => subItem.queryId ) );

          return acc;
        }, [] as string[] );

      const queriesToSend = queriesToCheck.filter( ( query ) => !loadedQueries?.includes( query ) );

      const params = queriesToSend.reduce( ( acc, query ) => {
        acc.append( 'query_ids', query );

        return acc;
      }, new URLSearchParams() );

      params.append( 'request_id', requestId );

      const audienceNamesParams = Object.values( audienceNames ).forEach( ( audienceName ) => {
        params.append( 'audience_names', audienceName );
      } );

      const response = await fetch(
        attributesExcelAPI + params,
        {
          method:      'GET',
          credentials: 'include',
        }
      );

      if ( !response.ok ) {
        if ( response.status === 401 ) {
          deleteCookie( 'session_token' );
          navigate( '/login' );
        }

        throw new Error( 'Something went wrong' );
      }

      const ab = await response.arrayBuffer();
      const wb = XLSX.read( ab );

      let fileName = 'AudienceComparisonExport';

      const index = response.headers.get( 'Content-Disposition' )?.indexOf( 'filename="' );

      if ( !!index ) {
        const newFileName = response.headers.get( 'Content-Disposition' )
          ?.slice(
            index + 10,
            ( response.headers.get( 'Content-Disposition' )?.length || 0 ) - 1
          );

        fileName = !!newFileName ? newFileName : fileName;
      }

      XLSX.writeFile( wb, fileName );

    } catch ( error ) {
      errorMessage();
      console.error( error ); //eslint-disable-line
    }

    setDownloading( false );
  };

  useEffect( () => {
    if ( downloading && !loading ) {
      onDownloadClick();
    }

  }, [downloading, loading] );

  return (
    <>
      {contextHolder}
      <a ref={ref} href=''></a>
      <Button loading={downloading} onClick={() => setDownloading( true )} icon={<ExportOutlined />}>Export as XLSX</Button>
    </>
  );
};
