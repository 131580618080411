import React from 'react';
import { Button, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setAudienceComparisonIsLoading, setChosenAudience } from '../../../store/audienceComparison/actions';
import { AUDIENCE_ID } from '../collapse/const';
import Summary from '../Summary/Summary';
import { IBladeProps } from '../types';
import WizardTabs from '../WizardTabs/WizardTabs';
import { selectTabs } from '../WizardTabs/selectors';
import { resetWizard, setWizardIsOpen } from '../../../store/wizardSlice';

import './Blade.scss';
import TitleWithCaption from '../../../custom/TitleWithCaption/TitleWithCaption';
import {
  selectAudiencesToCompare,
  selectFinishedTabsIsEqualComparisons,
} from '../../../store/audienceComparison/selectors';
import { resetTabs } from '../../../store/tabSlice';
import { resetMarketingCampaignByAudienceId } from '../../../store/campaignSlice';

const Blade: React.FC<IBladeProps> = ( { open, handleClose } ) => {
  const dispatch = useDispatch();
  const tabs = useSelector( selectTabs );
  const isEqualComparisons = useSelector( selectFinishedTabsIsEqualComparisons );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );

  const isApplyEnabled = !isEqualComparisons && ( tabs[AUDIENCE_ID.BENCHMARK]?.isFinished
    && ( tabs[AUDIENCE_ID.COMPARISON]?.isFinished || tabs[AUDIENCE_ID.SECOND_COMPARISON]?.isFinished ) );

  const handleApply = () => {
    dispatch( setAudienceComparisonIsLoading( true ) );
    dispatch( setWizardIsOpen( false ) );
    dispatch( setChosenAudience( null ) );


    if ( !!audiencesToCompare.AudienceC?.audienceParameters?.marketingCampaigns && !audiencesToCompare.AudienceB.audienceParameters ) {
      dispatch( resetMarketingCampaignByAudienceId( AUDIENCE_ID.COMPARISON ) );
    }

  };

  const handleAfterOpenChange = ( open: boolean ) => {
    if ( !open ) {
      dispatch( resetTabs() );
      dispatch( resetWizard() );
    }
  };

  return (
    <div>
      <Drawer
        rootClassName='blade'
        destroyOnClose
        maskClosable={false}
        title={<TitleWithCaption
          headingLevel={4}
          heading='Audience Comparison Parameters'
          headingStyles={{ fontFamily: 'WorkSans-SemiBold' }}
        />}
        placement='right'
        size='large'
        afterOpenChange={handleAfterOpenChange}
        onClose={handleClose}
        open={open}
        extra={
          <Button type='primary' disabled={!isApplyEnabled} onClick={handleApply}>
						Apply
          </Button>
        }
      >
        <div className='steps-content'>
          <Summary />
          <WizardTabs />
        </div>
      </Drawer>
    </div>
  );
};

export default Blade;
