import { CheckOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Popover, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import {
  IBrandInterface,
  ICategorizedBrandInterface,
  selectCategorizedMarsBrands,
  selectMarsBrandNames,
  selectMarsBrands,
  selectMarsBrandsByCategoryId,
  selectMarsManufacturer,
  setMarsBrands,
} from '../../../../store/brandSlice';
import { selectMarketingCampaignsByAudience } from '../../../../store/campaignSlice';
import { setSpecificTab } from '../../../../store/tabSlice';
import {
  collapseAllWizards,
  resetAdditionalParametersWizard,
  resetOriginWizard,
  setAdditionalParametersWizard,
  setMarsBrandsWizard,
  setOriginWizard,
  setSourceWizard,
} from '../../../../store/wizardSlice';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import {
  ALL_BUYER_TYPES,
  AUDIENCE_ID,
  BUTTON_BORDER_COLOR,
  BUYER_TYPE_DESCRIPTION,
  getSortedBrandList,
  options,
  stepErrorDescription,
  tooltipContent,
} from '../const';
import { ICollapseProps } from '../types';
import { selectWizardMarsBrandsByTabName } from '../../WizardTabs/selectors';
import {
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectBuyerTypesByAudienceId,
  selectCategoryByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
} from '../../../../store/audienceComparison/selectors';
import { buyerTypeToTagName } from '../AdditionalParametersCollapse/const';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { BuyerType } from '../../../../typings';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import { useGetBrandsLazyQuery } from '../../../../apollo/graphql-types';
import { handleScrollIntoView } from '../utils';

interface IBrandCollapseProps extends ICollapseProps {
  audience: AUDIENCE_ID;
}

const BrandCollapse = ( { handleCollapseChange, expandIconStyles, audience }: IBrandCollapseProps ) => {
  const dispatch = useDispatch();

  const { Panel } = Collapse;

  const activeTab = audience;
  const selectedBuyerTypesSelector = useSelector( selectBuyerTypesByAudienceId( activeTab ) );
  const selectedBuyerTypes = selectedBuyerTypesSelector || [];
  const marsBrandsWizardSelector = useSelector( selectWizardMarsBrandsByTabName( activeTab ) );
  const marsBrandsWizard = marsBrandsWizardSelector || [];
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );
  const marsManufacturer = useSelector( selectMarsManufacturer );
  const marsBrandsNamesSelector = useSelector( selectMarsBrandNames );
  const marsBrandsNames = marsBrandsNamesSelector || [];
  const campaignsList = useSelector( selectMarketingCampaignsByAudience( activeTab ) );
  const availableBrandsSelector = useSelector( selectMarsBrandsByCategoryId( selectedCategory ) );
  const availableBrands = availableBrandsSelector || [];
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const marsBrands = useSelector( selectMarsBrands );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const brandsListSelector = useSelector( selectCategorizedMarsBrands );
  const brandsList = brandsListSelector || [];

  const [showAllMap, setShowAllMap] = useState<{ [key: string]: boolean }>( {} );
  const [buyerTypeTagValue, setBuyerTypeTagValue] = useState<string[] | string>();
  const [brandsForReduction, setBrandsForReduction] = useState<IBrandInterface[]>( [] );
  const [retry, setRetry] = useState( false );

  const scrollRef = useRef<HTMLDivElement | null>( null );

  const [loadMarsBrands, { loading: isMarsBrandsLoading }] = useGetBrandsLazyQuery( options() );

  const loadMarsBrandsInitiator = () => {
    loadMarsBrands()
      .then( ( { data, error } ) => {
        if ( !!data ) {
          dispatch( setMarsBrands( data.categorizedBrands ) );
        }

        if ( !!error ) {
          dispatch( setMarsBrandsWizard( { error: true, activeTab } ) );
        }
      } )
      .catch( ( reject ) => {
        dispatch( setMarsBrandsWizard( { error: true, activeTab } ) );
        console.error(`Loading data error: ${reject}`); //eslint-disable-line
      } );
  };

  const isLoading = marsBrandsWizard.isLoading || brandsList.length < 1;
  const selectedCategoryName = brandsListSelector?.find( ( category ) => selectedCategory === category.categoryId )?.categoryName || '';
  const ALL_BRANDS_CAPTION = `${selectedCategoryName} All Brands`;
  const isMultipleBrands = availableBrands.length > 1;
  const isAllBrandsSelected = availableBrands.length === selectedBrands.length;
  const isAllTypesSelected = selectedBuyerTypes.length === Object.values( BuyerType ).length;

  const goNextStep = () =>
    dispatch(
      setMarsBrandsWizard( {
        isFinished: true,
        isExpand:   false,
        tag:        [
          {
            name: TAG_VALUES.BRAND,
            value:
							isAllBrandsSelected && isMultipleBrands
							  ? [ALL_BRANDS_CAPTION]
							  : getSortedBrandList( selectedBrands, availableBrands ).map( ( brand ) => marsBrandsNames[brand] ),
          },
          { name: TAG_VALUES.BUYER_TYPE, value: buyerTypeTagValue },
        ],
        activeTab,
      } ),
    );

  const handleCategoryClick = ( id: number ) => {
    if ( selectedCategory ) {
      setShowAllMap( ( prevState ) => ( {
        ...prevState,
        [selectedCategory]: false,
      } ) );
    }

    if ( selectedCategory !== id ) {
      dispatch( setMarsBrandsWizard( { activeTab, isFinished: false } ) );
      dispatch( setAudienceParameters( [activeTab, {
        category:           id,
        manufacturer:       marsManufacturer,
        brands:             [],
        buyerTypes:         Object.values( BuyerType ),
        firstParty:         undefined,
        thirdParty:         undefined,
        engagementStatus:   undefined,
        marketingCampaigns: undefined,
        d2c:                undefined,
      },
      ]
      ) );
      dispatch( resetOriginWizard( { activeTab } ) );
      dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    }
  };

  const handleClickAllBrands = () => {
    const availableBrandsList = availableBrands.map( ( brand ) => brand.brandId );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, {
      brands:             !isAllBrandsSelected ? availableBrandsList.sort() : [],
      firstParty:         undefined,
      thirdParty:         undefined,
      engagementStatus:   undefined,
      marketingCampaigns: undefined,
      d2c:                undefined,
    }] ) );
    dispatch( resetOriginWizard( { activeTab } ) );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
  };

  const handleBrandButtonClick = ( name: number ) => {
    let selectedBrandsList: number[];
    if ( selectedBrands.includes( name ) ) {
      selectedBrandsList = selectedBrands.filter( ( item ) => item !== name );
    } else {
      selectedBrandsList = [...selectedBrands, name].sort();
    }
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, {
      brands:             selectedBrandsList,
      firstParty:         undefined,
      thirdParty:         undefined,
      engagementStatus:   undefined,
      marketingCampaigns: undefined,
      d2c:                undefined,
    }] ) );
    dispatch( resetOriginWizard( { activeTab } ) );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
  };

  const handleBrandNextClick = () => {
    goNextStep();
    dispatch( setSourceWizard( { activeTab, isExpand: false } ) );
    dispatch( setOriginWizard( { isExpand: true, isVisible: true, error: false, activeTab } ) );
  };

  const handleFinishWizardClick = () => {
    goNextStep();
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( resetOriginWizard( { activeTab } ) );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true, isMars: true } ) );
    dispatch( setAudienceParameters( [activeTab, {
      firstParty:         undefined,
      thirdParty:         undefined,
      marketingCampaigns: undefined,
      engagementStatus:   undefined,
      d2c:                undefined,
    }] ) );
  };

  const handleShowAllClick = () => {
    if ( selectedCategory ) {
      setShowAllMap( ( prevState ) => ( {
        ...prevState,
        [selectedCategory]: true,
      } ) );
    }
  };

  const handleClickAllTypes = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setOriginWizard( { activeTab, isVisible: false } ) );
    dispatch( setAdditionalParametersWizard( { activeTab, isVisible: false } ) );

    isAllTypesSelected
      ? dispatch( setAudienceParameters( [activeTab, { buyerTypes: [] }] ) )
      : dispatch( setAudienceParameters( [activeTab, { buyerTypes: Object.values( BuyerType ) }] ) );
  };

  const handleBuyerTypeButtonClick = ( name: BuyerType ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setOriginWizard( { activeTab, isVisible: false } ) );
    dispatch( setAdditionalParametersWizard( { activeTab, isVisible: false } ) );

    if ( selectedBuyerTypes?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: selectedBuyerTypes.filter( ( buyerType ) => buyerType !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { buyerTypes: [...selectedBuyerTypes, name] }] ) );
  };

  useEffect( () => {
    const valueToSet = isAllTypesSelected
      ? ALL_BUYER_TYPES
      : Object.values( BuyerType ).filter( ( bt ) => selectedBuyerTypes.includes( bt ) ).map( ( bt ) => buyerTypeToTagName[bt] );
    if ( selectedBuyerTypes !== valueToSet && !isEmpty( valueToSet ) ) {
      setBuyerTypeTagValue( valueToSet );
    }
  }, [selectedBuyerTypes] );

  useEffect( () => {
    if ( selectedBuyerTypes?.length === 0 ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: Object.values( BuyerType ) }] ) );
    }
  }, [] );

  useEffect( () => {
    const valueToSet = isAllTypesSelected
      ? ALL_BUYER_TYPES
      : Object.values( BuyerType ).filter( ( bt ) => selectedBuyerTypes.includes( bt ) ).map( ( bt ) => buyerTypeToTagName[bt] );
    if ( selectedBuyerTypes !== valueToSet && !isEmpty( valueToSet ) ) {
      setBuyerTypeTagValue( valueToSet );
    }
  }, [selectedBuyerTypes] );

  useEffect( () => {
    if ( isEmpty( marsBrands ) ) {
      loadMarsBrandsInitiator();
    }
  }, [] );

  useEffect( () => {
    if ( !marsBrandsWizard.error ) {
      loadMarsBrandsInitiator();
    }
  }, [marsBrandsWizard.error] );

  useEffect( () => {
    if ( retry ) {
      dispatch( setMarsBrandsWizard( { error: false, activeTab } ) );
      setRetry( false );
      loadMarsBrandsInitiator();
    }
  }, [retry] );

  useEffect( () => {
    if ( !isLoading || !!selectedBrands || !!selectedBuyerTypes || !!selectedCategory ) {
      handleScrollIntoView( scrollRef );
    }
  }, [isLoading, selectedBrands, selectedBuyerTypes, selectedCategory] );

  useEffect( () => {
    dispatch( setMarsBrandsWizard( { isLoading: isMarsBrandsLoading, activeTab } ) );
  }, [isMarsBrandsLoading] );

  useEffect( () => {
    const shouldShowAll = showAllMap[selectedCategory || 0] || false;
    setBrandsForReduction(
      availableBrands.slice( 0, shouldShowAll ? availableBrands.length : 10 )
    );
  }, [selectedCategory, showAllMap] );

  useEffect( () => {
    if ( marsBrandsWizard.isLoading === false && brandsList.length > 0 && !selectedCategory ) {
      dispatch( setAudienceParameters( [activeTab, { category: brandsList[0]?.categoryId, manufacturer: marsManufacturer }] ) );
    }
  }, [marsBrandsWizard.isLoading, brandsList.length] );

  useEffect( () => {
    if ( !campaignsList ) return;

    const hasD2C = ( campaignsList.yes && campaignsList.yes.length > 0 ) || campaignsList.yes !== null;
    const hasMarketingCampaigns = ( campaignsList.no && campaignsList.no.length > 0 ) || campaignsList.no !== null;
    const isSelectedOnlyD2C = campaignsList.yes && selectedBrands.length === campaignsList.yes.length;
    dispatch(
      setMarsBrandsWizard( {
        hasD2C,
        hasMarketingCampaigns,
        isSelectedOnlyD2C,
        activeTab,
      } ),
    );
  }, [campaignsList] );

  useEffect( () => {
    if ( !marsBrandsWizard.isFinished && buyerTypeTagValue ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [marsBrandsWizard.isFinished, buyerTypeTagValue] );

  useEffect( () => {
    if ( hasAudiencesToCompare && isEqualComparisons ) {
      Object.entries( audiencesToCompare ).filter( ( [key, audience] ) => !!audience ).map( ( [key, audience] ) => {
        const isMarsBrandsSource = [18, 32].includes( audience.audienceParameters?.manufacturer || 0 );

        if ( isMarsBrandsSource ) {
          dispatch(
            setMarsBrandsWizard( {
              isFinished: true,
              tag:        [
                {
                  name: TAG_VALUES.BRAND,
                  value:
										isAllBrandsSelected && isMultipleBrands
										  ? [ALL_BRANDS_CAPTION]
										  : getSortedBrandList( selectedBrands, availableBrands )
										    .map( ( brand ) => marsBrandsNames[brand] ),
                },
                {
                  name:  TAG_VALUES.BUYER_TYPE,
                  value: isAllTypesSelected
                    ? ALL_BUYER_TYPES
                    : Object.values( BuyerType )
                      .filter( ( bt ) => selectedBuyerTypes.includes( bt ) ).map( ( bt ) => buyerTypeToTagName[bt] ),
                },
              ],
              activeTab,
            } )
          );

          if ( !!audience.audienceParameters?.firstParty || !!audience.audienceParameters?.thirdParty ) {
            dispatch( setOriginWizard( { isVisible: true, activeTab: key } ) );
          } else {
            dispatch( setSpecificTab( { activeTab, isFinished: true, isMars: true } ) );
          }
        }
      } );
    }
  }, [] );

  return marsBrandsWizard.error
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          setRetry( true );
        }}
      />
    )
    : isLoading
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          defaultActiveKey={WIZARD_NAME.MARS_BRANDS}
          activeKey={marsBrandsWizard.isExpand ? WIZARD_NAME.MARS_BRANDS : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setMarsBrandsWizard, marsBrandsWizard )}
          expandIcon={() =>
            marsBrandsWizard.isExpand
              ? (
                <MinusOutlined style={expandIconStyles} />
              )
              : (
                <PlusOutlined style={expandIconStyles} />
              )
          }
        >
          <Panel
            key={WIZARD_NAME.MARS_BRANDS}
            header={
              <CollapseHeader
                step={WIZARD_NAME.MARS_BRANDS}
                heading='Step 02: Brand'
                caption='Select a brand to proceed with'
              />
            }
            style={{ padding: '0.75rem 0.5rem 0.75rem', gap: 8 }}
          >
            <Space className='blade-buttons' wrap>
              {brandsList.map( ( brand: ICategorizedBrandInterface ) => (
                <Button
                  key={brand.categoryName}
                  type={selectedCategory === brand.categoryId ? 'primary' : 'default'}
                  style={{
                    overflow:      'hidden',
                    whiteSpace:    'nowrap',
                    textOverflow:  'ellipsis',
                    textTransform: 'capitalize',
                    borderColor:   brand.categoryId === selectedCategory ? BUTTON_BORDER_COLOR : '',
                  }}
                  onClick={() => handleCategoryClick( brand.categoryId )}
                >
                  {brand.categoryName}
                </Button>
              ) )}
            </Space>
            {!!selectedCategory && (
              <>
                <div style={{ paddingTop: 16 }}>
                  <TitleWithCaption caption='Select Brand' captionFontSize={16} />
                </div>
                {isMultipleBrands && (
                  <Space className='blade-buttons' style={{ display: 'block' }}>
                    <Button
                      key='All Brands'
                      type={isAllBrandsSelected ? 'primary' : 'default'}
                      style={{ marginTop: 16, width: '100%' }}
                      onClick={handleClickAllBrands}
                    >
                All Brands
                    </Button>
                  </Space>
                )}
                <Space className='blade-buttons' wrap style={{ paddingTop: 16 }}>
                  {brandsForReduction?.map( ( brand: IBrandInterface ) => {
                    const { brandId, brandName } = brand;
                    return (
                      <Button
                        key={brandId}
                        style={{
                          textTransform: 'capitalize',
                          color:         selectedBrands.includes( brandId ) ? BUTTON_BORDER_COLOR : '',
                          borderColor:   selectedBrands.includes( brandId ) ? BUTTON_BORDER_COLOR : '',
                        }}
                        icon={selectedBrands.includes( brandId ) ? <CheckOutlined /> : ''}
                        onClick={() => handleBrandButtonClick( brandId )}
                      >
                        {brandName}
                      </Button>
                    );
                  } )}
                  {!showAllMap[selectedCategory]
              && brandsForReduction.length >= 10
              && availableBrands.length !== 10 && (
                    <div className='link-button'>
                      <Button type='link' onClick={handleShowAllClick}>
                    Show All
                      </Button>
                    </div>
                  )}
                </Space>
                <div ref={scrollRef} >

                  {selectedBrands.length > 0 && (
                    <>
                      <Divider style={{ margin: '1rem 0 1rem' }} />
                      <div style={{ paddingBottom: 16 }}>
                        <span className='buyer_type-info'>Buyer Type</span>
                        <Popover content={tooltipContent( BUYER_TYPE_DESCRIPTION )} trigger='hover' placement='bottom'>
                          <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', paddingLeft: 8, cursor: 'pointer' }} />
                        </Popover>
                      </div>
                      <Space className='blade-buttons'>
                        <Button
                          key='All Buyer Types'
                          type={isAllTypesSelected ? 'primary' : 'default'}
                          style={{ width: 621 }}
                          onClick={handleClickAllTypes}
                        >
                  All Buyer Types
                        </Button>
                      </Space>
                      <Space className='blade-buttons' wrap style={{ paddingTop: 16, width: '-webkit-fill-available' }}>
                        {Object.values( BuyerType ).map( ( buyerType ) => {
                          const isSelected = selectedBuyerTypes.includes( buyerType );

                          return (
                            <Button
                              key={buyerType}
                              style={{
                                maxWidth:      '620px',
                                overflow:      'hidden',
                                whiteSpace:    'nowrap',
                                textOverflow:  'ellipsis',
                                textTransform: 'capitalize',
                                color:         isSelected ? BUTTON_BORDER_COLOR : '',
                                borderColor:   isSelected ? BUTTON_BORDER_COLOR : '',
                              }}
                              icon={isSelected ? <CheckOutlined /> : ''}
                              onClick={() => handleBuyerTypeButtonClick( buyerType )}
                            >
                              {buyerTypeToTagName[buyerType]}
                            </Button>
                          );
                        } )}
                      </Space>
                      {selectedBuyerTypes.length > 0 && (
                        <>
                          <Divider style={{ margin: '1rem 0 1rem' }} />
                          <Space className='next-button'>
                            <Button onClick={handleBrandNextClick}>Next - Origin</Button>
                            <Button onClick={handleFinishWizardClick}>Finish Wizard</Button>
                          </Space>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </Panel>
        </Collapse>
      );
};

export default BrandCollapse;
