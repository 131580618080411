/* eslint-disable max-len */
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Flex } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';

import {
  selectAudienceComparisonCount,
  selectAudienceComparisonExclusive,
  selectAudienceComparisonHoveredId,
  selectAudienceComparisonOnly,
  selectAudienceComparisonOverlap,
  selectChosenAudience,
} from '../../../../store/audienceComparison/selectors';
import { setChosenAudience, setHoveredId } from '../../../../store/audienceComparison/actions';

import './VennDiagramSvg.scss';
import { IAudienceComparisonItem } from '../../../../store/audienceComparison/typings';

export const VennDiagramSvg = () => {
  const dispatch = useDispatch();

  const hoverId = useSelector( selectAudienceComparisonHoveredId );
  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const chosenAudience = useSelector( selectChosenAudience );
  const audienceComparisonOnly = useSelector( selectAudienceComparisonOnly );
  const audienceComparisonExclusive = useSelector( selectAudienceComparisonExclusive );
  const audienceComparisonOverlap = useSelector( selectAudienceComparisonOverlap );

  const hoverRef = useRef( null );
  const isHover = useHoverDirty( hoverRef );

  const getIsActive = ( segment: string | undefined ) => hoverId === segment || chosenAudience?.segmentId === segment ? 'active' : '';

  const getSegment = ( id: string ) => {
    const [type, ...positions] = id.split( '-' );
    let segment: IAudienceComparisonItem | undefined;

    if ( type === 'only' ) {
      segment = audienceComparisonOnly.find( ( item ) => isEqual( item.position, positions ) );
    }

    if ( type === 'exclusive' ) {
      segment = audienceComparisonExclusive.find( ( item ) => isEqual( item.position, positions ) );
    }

    if ( type === 'overlap' ) {
      segment = audienceComparisonOverlap.find( ( item ) => isEqual( item.position, positions ) );
    }

    return segment ? segment : null;
  };

  const onClickEvent = ( e: any ) => {
    const segment = getSegment( e.target?.dataset.segment );
    const id = e.target.id;

    if ( !id ) return;

    dispatch( setChosenAudience( segment?.segmentId === chosenAudience?.segmentId ? null : segment ) );
  };

  const onMouseOverEvent = ( e: any ) => {
    const segment = getSegment( e.target.dataset.segment || '' ) || null;

    if ( segment !== null ) {
      dispatch( setHoveredId( segment.segmentId ) );
    }
  };

  useEffect( () => {
    if ( !isHover ) {
      dispatch( setHoveredId( null ) );
    }
  }, [isHover] );

  return (
    <>
      <Flex className={cn( 'venn-diagram', { 'clicked': !!chosenAudience?.segmentId } )}>
        <div className={cn( 'venn-diagram-svg-container', { 'single-comparison': audienceComparisonCount === 2 } )}>
          <svg
            width='381'
            height={audienceComparisonCount === 2 ? 237 : 372}
            viewBox={`0 0 381 ${audienceComparisonCount === 2 ? 237 : 372}`}
            fill='none'
            onClick={( e ) => onClickEvent( e )}
            onMouseOver={onMouseOverEvent}
            ref={hoverRef}
          >
            <path
              id='only-audienceA'
              className={cn( 'only-audienceA', getIsActive( getSegment( 'only-audienceA' )?.segmentId ) )}
              d='M6.5 120.914C6.5 135.939 9.45935 150.817 15.2092 164.699C20.9591 178.58 29.3868 191.193 40.0111 201.817C50.6355 212.442 63.2484 220.87 77.1298 226.619C91.0112 232.369 105.889 235.329 120.914 235.329C135.939 235.329 150.817 232.369 164.699 226.619C178.58 220.87 191.193 212.442 201.817 201.817C212.442 191.193 220.869 178.58 226.619 164.699C232.369 150.817 235.329 135.939 235.329 120.914C235.329 90.5698 223.274 61.468 201.817 40.0111C180.361 18.5543 151.259 6.5 120.914 6.5C90.5697 6.5 61.468 18.5543 40.0111 40.0111C18.5543 61.468 6.5 90.5698 6.5 120.914Z'
              fill='#009EEB'
              fillOpacity='0.3'
              stroke='#009EEB'
              data-segment={'only-audienceA'}
            />
            <path
              id='only-audienceA-transparent'
              className='only-audienceA-transparent'
              d='M2 120.915C2 136.531 5.07576 151.994 11.0518 166.422C17.0278 180.849 25.7871 193.958 36.8294 205.001C47.8716 216.043 60.9807 224.802 75.4081 230.778C89.8356 236.754 105.299 239.83 120.915 239.83C136.531 239.83 151.994 236.754 166.422 230.778C180.849 224.802 193.958 216.043 205.001 205.001C216.043 193.958 224.802 180.849 230.778 166.422C236.754 151.994 239.83 136.531 239.83 120.915C239.83 89.3768 227.301 59.1302 205.001 36.8294C182.7 14.5285 152.453 2 120.915 2C89.3767 2 59.1303 14.5285 36.8294 36.8294C14.5285 59.1302 2 89.3768 2 120.915ZM15.6873 164.502C9.9634 150.683 7.01741 135.872 7.01741 120.915C7.01741 90.7075 19.0173 61.7371 40.3772 40.3772C61.7371 19.0173 90.7074 7.01741 120.915 7.01741C151.122 7.01741 180.093 19.0173 201.453 40.3772C222.813 61.7371 234.813 90.7075 234.813 120.915C234.813 135.872 231.866 150.683 226.143 164.502C220.419 178.32 212.029 190.876 201.453 201.453C190.876 212.029 178.32 220.419 164.502 226.143C150.683 231.867 135.872 234.813 120.915 234.813C105.958 234.813 91.1469 231.867 77.3282 226.143C63.5095 220.419 50.9536 212.029 40.3772 201.453C29.8008 190.876 21.4112 178.32 15.6873 164.502Z'
              fill='#FF0000'
              stroke='#FF0000'
              data-segment={'only-audienceA'}
            />
            <path
              className={cn( 'only-audienceB', getIsActive( getSegment( 'only-audienceB' )?.segmentId ) )}
              id='only-audienceB'
              d='M146.004 120.914C146.004 135.939 148.963 150.817 154.713 164.699C160.463 178.58 168.891 191.193 179.515 201.817C190.139 212.442 202.752 220.87 216.634 226.619C230.515 232.369 245.393 235.329 260.418 235.329C275.443 235.329 290.321 232.369 304.203 226.619C318.084 220.87 330.697 212.442 341.321 201.817C351.946 191.193 360.373 178.58 366.123 164.699C371.873 150.817 374.833 135.939 374.833 120.914C374.833 90.5698 362.778 61.468 341.321 40.0111C319.864 18.5543 290.763 6.5 260.418 6.5C230.074 6.5 200.972 18.5543 179.515 40.0111C158.058 61.468 146.004 90.5698 146.004 120.914Z'
              fill='#22B279'
              fillOpacity='0.3'
              stroke='#22B279'
              data-segment={'only-audienceB'}
            />
            <path
              id='only-audienceB-transparent'
              className='only-audienceB-transparent'
              d='M141 120.915C141 136.531 144.076 151.994 150.052 166.422C156.028 180.849 164.787 193.958 175.829 205.001C186.872 216.043 199.981 224.802 214.408 230.778C228.836 236.754 244.299 239.83 259.915 239.83C275.531 239.83 290.994 236.754 305.422 230.778C319.849 224.802 332.958 216.043 344.001 205.001C355.043 193.958 363.802 180.849 369.778 166.422C375.754 151.994 378.83 136.531 378.83 120.915C378.83 89.3768 366.301 59.1302 344.001 36.8294C321.7 14.5285 291.453 2 259.915 2C228.377 2 198.13 14.5285 175.829 36.8294C153.528 59.1302 141 89.3768 141 120.915ZM154.687 164.502C148.963 150.683 146.017 135.872 146.017 120.915C146.017 90.7075 158.017 61.7371 179.377 40.3772C200.737 19.0173 229.707 7.01741 259.915 7.01741C290.122 7.01741 319.093 19.0173 340.453 40.3772C361.813 61.7371 373.813 90.7075 373.813 120.915C373.813 135.872 370.866 150.683 365.143 164.502C359.419 178.32 351.029 190.876 340.453 201.453C329.876 212.029 317.32 220.419 303.502 226.143C289.683 231.867 274.872 234.813 259.915 234.813C244.958 234.813 230.147 231.867 216.328 226.143C202.51 220.419 189.954 212.029 179.377 201.453C168.801 190.876 160.411 178.32 154.687 164.502Z'
              fill='#FF0000'
              stroke='#FF0000'
              data-segment={'only-audienceB'}
            />
            <path
              className={cn( 'overlap-audienceA-audienceB', getIsActive( getSegment( 'overlap-audienceA-audienceB' )?.segmentId ) )}
              id='overlap-audienceA-audienceB'
              d='M180.322 39.9293C183.598 36.6893 187.052 33.664 190.66 30.8628C194.268 33.664 197.721 36.6893 200.998 39.9293C222.655 61.3424 234.82 90.3825 234.82 120.66C234.82 135.657 231.879 150.766 226.078 164.614C220.27 178.477 212.012 191.195 201.297 201.789C198.037 205.012 194.318 208.158 190.661 210.987C187.032 208.167 183.472 205.021 180.203 201.789C169.475 191.182 160.723 178.46 154.922 164.614C149.126 150.778 146.5 135.672 146.5 120.66C146.5 90.3825 158.665 61.3424 180.322 39.9293Z'
              stroke='black'
              data-segment={'overlap-audienceA-audienceB'}
            />

            {audienceComparisonCount === 2 && (
              <>
                <path
                  className={cn( 'exclusive-audienceA', getIsActive( getSegment( 'exclusive-audienceA' )?.segmentId ) )}
                  id='exclusive-audienceA'
                  d='M16.595 164.125C10.9206 150.425 8 135.742 8 120.914C8 90.9676 19.8963 62.2473 41.0718 41.0718C62.2468 19.8968 91.0525 8 121 8C145.737 8 168.805 15.5355 188.269 30.261C184.803 33.0312 181.419 36.1665 178.293 39.2929C156.741 60.8443 144.5 90.524 144.5 121C144.5 136.101 147.805 151.451 153.576 165.383C159.355 179.334 168.13 192.044 178.793 202.707C181.899 205.813 184.95 208.744 188.37 211.488C180.783 217.226 173.027 221.546 164.125 225.234C150.425 230.908 135.742 233.829 120.914 233.829C106.086 233.829 91.4032 230.908 77.7038 225.234C64.0044 219.559 51.5569 211.242 41.0718 200.757C30.5867 190.272 22.2695 177.824 16.595 164.125Z'
                  fill='#009EEB'
                  strokeWidth='2'
                  data-segment={'exclusive-audienceA'}
                />
                <path
                  className={cn( 'exclusive-audienceB', getIsActive( getSegment( 'exclusive-audienceB' )?.segmentId ) )}
                  id='exclusive-audienceB'
                  d='M203.207 39.2929C200.071 36.1573 196.563 33.0239 193.083 30.2598C212.557 15.5372 236.012 8 260.752 8C290.695 8 319.115 19.929 340.293 41.1071C361.468 62.2821 373.35 91.0525 373.35 121C373.35 135.833 370.649 149.922 364.976 163.617C359.297 177.328 351.269 189.816 340.793 200.293C330.301 210.785 317.653 219.485 303.962 225.156C290.26 230.832 275.577 233.829 260.752 233.829C245.911 233.829 231.571 231.246 217.883 225.576C208.977 221.887 200.757 217.344 193.132 211.589C196.673 208.76 200.434 205.33 203.557 202.207L203.563 202.201L203.568 202.196C214.302 191.109 222.149 179.325 227.924 165.383C233.698 151.443 236.8 136.093 236.8 121C236.8 90.5285 224.762 60.8475 203.207 39.2929Z'
                  fill='#22B279'
                  strokeWidth='2'
                  data-segment={'exclusive-audienceB'}
                />
              </>
            )}

            {audienceComparisonCount === 3 && (
              <>
                <path
                  className={cn( 'exclusive-audienceA', getIsActive( getSegment( 'exclusive-audienceA' )?.segmentId ) )}
                  id='exclusive-audienceA'
                  d='M144.5 121L144.5 121.012C144.597 129.181 145.306 136.52 146.919 144.426C132.409 150.165 119.523 159.063 108.293 170.293C93.1192 185.467 82.4004 204.603 77.5118 225.159C77.4927 225.151 77.4736 225.143 77.4544 225.135C77.2752 225.06 77.0884 224.981 76.8827 224.896C63.1908 219.225 51.5628 211.248 41.0718 200.757C30.5867 190.272 22.2695 177.824 16.595 164.125C10.9206 150.425 8 135.742 8 120.914C8 90.9676 19.8963 62.2473 41.0718 41.0718C62.2473 19.8963 90.9675 8 120.914 8C145.652 8 168.755 15.5071 188.219 30.2306C184.838 32.9295 181.913 35.6724 178.793 38.7929C157.246 60.3397 144.5 90.5175 144.5 121Z'
                  fill='#009EEB'
                  strokeWidth='2'
                  data-segment={'exclusive-audienceA'}
                />
                <path
                  className={cn( 'exclusive-audienceB', getIsActive( getSegment( 'exclusive-audienceB' )?.segmentId ) )}
                  id='exclusive-audienceB'
                  d='M203.207 39.2929C200.075 36.1607 196.591 33.0016 193.112 30.2302C212.569 15.5058 235.454 7.98 260.586 7.98C290.532 7.98 319.252 19.8959 340.428 41.0718C361.604 62.2473 373.5 90.9676 373.5 120.914C373.5 135.742 370.579 150.425 364.905 164.125C359.23 177.824 350.913 190.272 340.428 200.757C329.941 211.243 317.815 219.402 304.117 225.076C303.708 225.246 303.414 225.362 303.117 225.476C298.254 204.787 287.307 185.043 272.057 169.793C260.989 158.725 248.546 150.706 234.344 144.715C235.986 136.55 236.8 129.296 236.8 121C236.8 90.5268 224.677 60.8471 203.208 39.2943L203.207 39.2929Z'
                  fill='#22B279'
                  strokeWidth='2'
                  data-segment={'exclusive-audienceB'}
                />
                <path
                  className={cn( 'only-audienceC', getIsActive( getSegment( 'only-audienceC' )?.segmentId ) )}
                  id='only-audienceC'
                  d='M75.8652 251.914C75.8652 266.939 78.8246 281.817 84.5744 295.699C90.3243 309.58 98.752 322.193 109.376 332.817C120.001 343.442 132.614 351.87 146.495 357.619C160.376 363.369 175.254 366.329 190.279 366.329C205.305 366.329 220.183 363.369 234.064 357.619C247.945 351.87 260.558 343.442 271.183 332.817C281.807 322.193 290.235 309.58 295.985 295.699C301.734 281.817 304.694 266.939 304.694 251.914C304.694 221.57 292.639 192.468 271.183 171.011C249.726 149.554 220.624 137.5 190.279 137.5C159.935 137.5 130.833 149.554 109.376 171.011C87.9195 192.468 75.8652 221.57 75.8652 251.914Z'
                  fill='#7A36BE'
                  fillOpacity='0.3'
                  stroke='#7A36BE'
                  data-segment={'only-audienceC'}
                />
                <path
                  id='only-audienceC-transparent'
                  className='only-audienceC-transparent'
                  d='M71 250.915C71 266.531 74.0758 281.994 80.0518 296.422C86.0278 310.849 94.7871 323.958 105.829 335.001C116.872 346.043 129.981 354.802 144.408 360.778C158.836 366.754 174.299 369.83 189.915 369.83C205.531 369.83 220.994 366.754 235.422 360.778C249.849 354.802 262.958 346.043 274.001 335.001C285.043 323.958 293.802 310.849 299.778 296.422C305.754 281.994 308.83 266.531 308.83 250.915C308.83 219.377 296.301 189.13 274.001 166.829C251.7 144.528 221.453 132 189.915 132C158.377 132 128.13 144.528 105.829 166.829C83.5285 189.13 71 219.377 71 250.915ZM84.6873 294.502C78.9634 280.683 76.0174 265.872 76.0174 250.915C76.0174 220.707 88.0173 191.737 109.377 170.377C130.737 149.017 159.707 137.017 189.915 137.017C220.122 137.017 249.093 149.017 270.453 170.377C291.813 191.737 303.813 220.707 303.813 250.915C303.813 265.872 300.866 280.683 295.143 294.502C289.419 308.32 281.029 320.876 270.453 331.453C259.876 342.029 247.32 350.419 233.502 356.143C219.683 361.867 204.872 364.813 189.915 364.813C174.958 364.813 160.147 361.867 146.328 356.143C132.51 350.419 119.954 342.029 109.377 331.453C98.8008 320.876 90.4112 308.32 84.6873 294.502Z'
                  fill='#FF0000' stroke='#FF0000'
                  data-segment={'only-audienceC'}
                />
                <path
                  className={cn( 'exclusive-audienceC', getIsActive( getSegment( 'exclusive-audienceC' )?.segmentId ) )}
                  id='exclusive-audienceC'
                  d='M300.971 229.497C302.585 237.088 303.199 244.122 303.199 252.111C303.199 266.939 300.278 281.622 294.603 295.321C288.929 309.02 280.612 321.468 270.127 331.953C259.642 342.438 247.194 350.755 233.495 356.43C219.795 362.104 205.112 365.025 190.284 365.025C175.456 365.025 160.773 362.104 147.074 356.43C133.374 350.755 120.927 342.438 110.442 331.953C99.9567 321.468 91.6395 309.02 85.965 295.321C80.2906 281.622 77.37 266.939 77.37 252.111C77.37 244.027 77.9937 236.836 79.6451 229.154C92.7478 234.157 106.974 236.8 121 236.8C136.094 236.8 151.443 233.673 165.381 227.924L165.392 227.92L165.403 227.915C174.709 223.814 182.778 219.313 190.656 213.48C198.54 219.391 206.9 224.106 216.117 227.924C230.057 233.698 245.407 236.8 260.5 236.8C274.269 236.8 288.085 234.318 300.971 229.497Z'
                  data-segment={'exclusive-audienceC'}
                />
                <path
                  className={cn( 'overlap-audienceA-audienceC', getIsActive( getSegment( 'overlap-audienceA-audienceC' )?.segmentId ) )}
                  id='overlap-audienceA-audienceC'
                  d='M109.645 171.696L109.654 171.688L109.662 171.68C131.022 149.896 160.207 137.924 190.552 137.924C204.738 137.924 219.147 140.851 232.035 145.932C230.638 152.007 228.947 157.851 226.575 163.62C220.822 177.613 212.409 190.597 201.79 201.296C191.173 211.993 178.503 220.281 164.615 226.077C150.732 231.871 135.519 235 120.5 235C106.451 235 92.3014 231.993 79.1666 226.884C83.9842 206.133 94.0602 186.864 109.645 171.696Z'
                  stroke='black'
                  data-segment={'overlap-audienceA-audienceC'}
                />
                <path
                  className={cn( 'overlap-audienceB-audienceC', getIsActive( getSegment( 'overlap-audienceB-audienceC' )?.segmentId ) )}
                  id='overlap-audienceB-audienceC'
                  d='M216.839 226.077L216.83 226.073L216.821 226.07C203.009 220.626 190.04 211.706 179.41 200.996C168.788 190.294 160.376 177.491 154.625 163.501C152.22 157.651 150.611 151.83 149.215 145.663C162.013 140.786 175.637 138 189.56 138C219.917 138 249.344 149.598 270.79 171.204C286.045 186.573 296.665 206.322 301.465 227.182C288.657 232.136 274.427 234.8 260.62 234.8C245.594 234.8 230.717 231.869 216.839 226.077Z'
                  stroke='black'
                  data-segment={'overlap-audienceB-audienceC'}
                />
                <path
                  className={cn( 'overlap-audienceA-audienceB-audienceC', getIsActive( getSegment( 'overlap-audienceA-audienceB-audienceC' )?.segmentId ) )}
                  id='overlap-audienceA-audienceB-audienceC'
                  d='M190.975 137.85C205.16 137.85 219.159 140.846 232.039 145.924C230.62 152.261 229.058 158.108 226.576 164.118C220.738 178.254 211.993 190.974 201.192 201.808C197.913 205.097 194.317 208.157 190.65 211.031C187.002 208.163 183.502 205.102 180.218 201.808C169.422 190.979 160.764 178.126 154.924 163.984C152.47 158.04 150.601 151.909 149.165 145.654C162.38 140.544 176.539 137.85 190.975 137.85Z'
                  stroke='black'
                  data-segment={'overlap-audienceA-audienceB-audienceC'}
                />
              </>
            )}
          </svg>
          <div className='audienceA-text venn-text'>
            <span>Benchmark</span>
            <span>Audience</span>
          </div>
          <div className='audienceB-text venn-text'>
            <span>Comparison</span>
            <span>Audience</span>
          </div>
          {audienceComparisonCount === 3 && (
            <div className='audienceC-text venn-text'>
              <span>2nd Comparison</span>
              <span>Audience</span>
            </div>
          )}
        </div>
      </Flex>
      <Col className='hint'>
        {!chosenAudience?.segmentId && (
          <><InfoCircleOutlined /> <span>Click on the point of interest or select an item from the list</span></>
        )}
      </Col>
    </>
  );
};
