import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Radio, RadioChangeEvent, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleWithCaption from '../../../../custom/TitleWithCaption/TitleWithCaption';
import { setSpecificTab } from '../../../../store/tabSlice';
import {
  setOriginWizard,
  setAdditionalParametersWizard,
  resetAdditionalParametersWizard,
  setSourceWizard,
  setMarsBrandsWizard,
  collapseAllWizards,
} from '../../../../store/wizardSlice';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import { D2C_WEBSITE_DATA, MARKETING_CAMPAIGNS } from '../AdditionalParametersCollapse/const';
import CollapseHeader from '../CollapseHeader';
import { ICollapseProps } from '../types';
import { selectWizardMarsBrandsByTabName, selectWizardOriginByTabName } from '../../WizardTabs/selectors';
import { EPartyData, EPartyDataToName } from './const';
import {
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectCategoryByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
  selectOriginByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { AUDIENCE_ID, options, stepErrorDescription } from '../const';
import { selectFirstPartyDataBrandNames, setAllCampaignsList } from '../../../../store/campaignSlice';
import { selectMarsBrandsByCategoryId } from '../../../../store/brandSlice';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import { useBrandMarketingCampaignsLazyQuery } from '../../../../apollo/graphql-types';
import sortBy from 'lodash/sortBy';
import { handleScrollIntoView } from '../utils';

const { Panel } = Collapse;

interface IOriginCollapseProps extends ICollapseProps {
  audience: AUDIENCE_ID;
}

const OriginCollapse = ( { handleCollapseChange, expandIconStyles, audience }: IOriginCollapseProps ) => {
  const dispatch = useDispatch();

  const activeTab = audience;
  const originWizard = useSelector( selectWizardOriginByTabName( activeTab ) );
  const marsBrandsWizard = useSelector( selectWizardMarsBrandsByTabName( activeTab ) );
  const isOnlyThirdParty = !marsBrandsWizard.hasD2C && !marsBrandsWizard.hasMarketingCampaigns;
  const selectedOrigin = useSelector( selectOriginByAudienceId( activeTab ) );
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const campaignsBrandNames = useSelector( selectFirstPartyDataBrandNames( activeTab ) );

  const availableBrandsSelector = useSelector( selectMarsBrandsByCategoryId( selectedCategory ) );
  const availableBrands = availableBrandsSelector || [];

  const [retry, setRetry] = useState( false );

  const scrollRef = useRef<HTMLDivElement | null>( null );

  const isLoading = originWizard.isLoading;

  const [loadCampaigns, { loading: isCampaignsLoading }] = useBrandMarketingCampaignsLazyQuery( options() );

  const loadCampaignsInitiator = () => {
    loadCampaigns( {
      variables: {
        brands: sortBy( availableBrands
          .filter( ( brand ) => selectedBrands.includes( brand.brandId ) )
          .map( ( brand ) => ( { id: brand.brandId, name: brand.brandName } ) ), 'id' ),
      },
    } )
      .then( ( { data, error } ) => {
        if ( !!data ) {
          dispatch( setAllCampaignsList( { activeTab, campaings: data.brandMarketingCampaigns } ) );
        }

        if ( !!error ) {
          dispatch( setOriginWizard( { error: true, activeTab } ) );
        }
      } )
      .catch( ( reject ) => {
        dispatch( setOriginWizard( { error: true, activeTab } ) );
        console.error(`Loading data error: ${reject}`); //eslint-disable-line
      } );
  };

  // When we change value in 'Origin' Button Group
  const handleRadioGroupChange = ( e: RadioChangeEvent ) => {
    handleScrollIntoView( scrollRef );

    dispatch(
      setOriginWizard( {
        isFinished: false,
        tag:        [],
        activeTab,
      } ),
    );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    const origin = e.target.value === EPartyData.firstParty
      ? {
        firstParty: true,
        thirdParty: undefined,
      }
      : {
        firstParty: undefined,
        thirdParty: true,
      };

    dispatch( setAudienceParameters( [activeTab, origin] ) );
  };

  // When we click 'Finish Wizard' after 'First-Party Data' choice
  const handleFinishWizardClick = ( parameter: EPartyData ) => {
    dispatch(
      setOriginWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
        tag:
					parameter === EPartyData.firstParty
					  ? [{ name: TAG_VALUES.ORIGIN, value: EPartyDataToName.FIRST_PARTY_DATA }]
					  : [{ name: TAG_VALUES.ORIGIN, value: EPartyDataToName.THIRD_PARTY_DATA }],
      } ),
    );

    const origin = parameter === EPartyData.firstParty
      ? {
        firstParty: true,
        thirdParty: undefined,
      }
      : {
        firstParty: undefined,
        thirdParty: true,
      };

    dispatch( setAudienceParameters( [activeTab, {
      ...origin,
      marketingCampaigns: undefined,
      engagementStatus:   undefined,
      d2c:                undefined,
    }] ) );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true, isMars: true } ) );
  };

  // When we click 'Next - Additional Parameters' after 'First-Party Data' choice
  const handleAdditionalParametersClick = () => {
    dispatch(
      setOriginWizard( {
        activeTab,
        tag:
					selectedOrigin === EPartyData.firstParty
					  ? [{ name: TAG_VALUES.ORIGIN, value: EPartyDataToName.FIRST_PARTY_DATA }]
					  : [{ name: TAG_VALUES.ORIGIN, value: EPartyDataToName.THIRD_PARTY_DATA }],
        isFinished: true,
        isExpand:   false,
      } ),
    );
    dispatch( setAdditionalParametersWizard( { isExpand: true, isVisible: true, activeTab } ) );

    if ( marsBrandsWizard.hasMarketingCampaigns && !marsBrandsWizard.hasD2C ) {
      dispatch( setAdditionalParametersWizard( { value: MARKETING_CAMPAIGNS, activeTab } ) );
    }
    dispatch( setSourceWizard( { activeTab, isExpand: false } ) );
    dispatch( setMarsBrandsWizard( { activeTab, isExpand: false } ) );
  };

  useEffect( () => {
    if ( !originWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [originWizard.isFinished] );

  useEffect( () => {
    if ( isOnlyThirdParty || selectedOrigin !== EPartyData.none ) {
      handleScrollIntoView( scrollRef );
    }
  }, [selectedOrigin, isOnlyThirdParty] );

  useEffect( () => {
    dispatch( setOriginWizard( { isLoading: isCampaignsLoading, activeTab } ) );
  }, [isCampaignsLoading] );

  useEffect( () => {
    loadCampaignsInitiator();
    handleScrollIntoView( scrollRef );
  }, [] );

  useEffect( () => {
    if ( !isLoading ) {
      handleScrollIntoView( scrollRef );
    }
  }, [isLoading] );

  useEffect( () => {
    if ( retry ) {
      dispatch( setOriginWizard( { error: false, activeTab } ) );
      setRetry( false );
      loadCampaignsInitiator();
    }
  }, [retry] );

  useEffect( () => {
    if ( !originWizard.error ) {
      loadCampaignsInitiator();
    }
  }, [originWizard.error] );

  useEffect( () => {
    if ( hasAudiencesToCompare && isEqualComparisons ) {
      Object.entries( audiencesToCompare ).filter( ( [key, audience] ) => !!audience ).map( ( [key, audience] ) => {
        const isMarsBrandsSource = [18, 32].includes( audience.audienceParameters?.manufacturer || 0 );

        if ( isMarsBrandsSource ) {
          dispatch(
            setOriginWizard( {
              activeTab,
              isFinished: true,
              tag:
								selectedOrigin === EPartyData.firstParty
								  ? [{ name: TAG_VALUES.ORIGIN, value: EPartyDataToName.FIRST_PARTY_DATA }]
								  : [{ name: TAG_VALUES.ORIGIN, value: EPartyDataToName.THIRD_PARTY_DATA }],
            } ),
          );

          if ( !audience.audienceParameters?.marketingCampaigns && !audience.audienceParameters?.d2c ) {
            dispatch( setSpecificTab( { activeTab: key, isFinished: true } ) );
          } else {
            if ( !!audience.audienceParameters?.marketingCampaigns ) {
              dispatch( setAdditionalParametersWizard( { value: MARKETING_CAMPAIGNS, isVisible: true, activeTab: key } ) );
            }
            if ( !!audience.audienceParameters?.d2c ) {
              dispatch( setAdditionalParametersWizard( { value: D2C_WEBSITE_DATA, isVisible: true, activeTab: key } ) );
            }
          }
        }
      } );
    }
  }, [] );

  return originWizard.error
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          setRetry( true );
        }}
      />
    )
    :isLoading
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          defaultActiveKey={WIZARD_NAME.ORIGIN}
          activeKey={originWizard.isExpand ? WIZARD_NAME.ORIGIN : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setOriginWizard, originWizard )}
          expandIcon={() =>
            originWizard.isExpand ? <MinusOutlined style={expandIconStyles} /> : <PlusOutlined style={expandIconStyles} />
          }
        >
          <Panel
            key={WIZARD_NAME.ORIGIN}
            header={
              <CollapseHeader
                step={WIZARD_NAME.ORIGIN}
                heading={`Step 03: ${STEP_NAMES.ORIGIN}`}
                caption='Select an origin for a selected brand'
              />
            }
            style={{ padding: '0.75rem 0.5rem 0.75rem' }}
          >
            <>
              {isOnlyThirdParty
                ? (
                  <TitleWithCaption caption={`Origin: ${EPartyDataToName.THIRD_PARTY_DATA}`} captionFontSize={16} />
                )
                : (
                  <>
                    <Radio.Group
                      options={[
                        { label: EPartyDataToName.FIRST_PARTY_DATA, value: EPartyData.firstParty },
                        { label: EPartyDataToName.THIRD_PARTY_DATA, value: EPartyData.thirdParty },
                      ]}
                      className='two-wide-button-group'
                      optionType='button'
                      buttonStyle='solid'
                      value={selectedOrigin}
                      style={{ width: '100%' }}
                      onChange={handleRadioGroupChange}
                    />
                    {selectedBrands.length > 1 && selectedBrands.length !== campaignsBrandNames.split( ', ' ).length && (
                      <div className='note-caption'>
                        <TitleWithCaption
                          caption={`*First-Party Data is available only for ${campaignsBrandNames}`}
                          captionFontSize={12}
                        />
                      </div>
                    )}

                  </>
                )
              }

              <div ref={scrollRef}>
                {selectedOrigin === EPartyData.firstParty && (
                  <>
                    <Divider style={{ margin: '1rem 0 1rem' }} />
                    <Space className='next-button'>
                      <Button onClick={handleAdditionalParametersClick}>Next - Additional Parameters</Button>
                      <Button onClick={() => handleFinishWizardClick( EPartyData.firstParty )}>Finish Wizard</Button>
                    </Space>
                  </>
                )}
                {( selectedOrigin === EPartyData.thirdParty || isOnlyThirdParty ) && (
                  <>
                    <Divider style={{ margin: '1rem 0 1rem' }} />
                    <Space className='next-button'>
                      <Button onClick={() => handleFinishWizardClick( EPartyData.thirdParty )}>Finish Wizard</Button>
                    </Space>
                  </>
                )}
              </div>
            </>
          </Panel>
        </Collapse>
      );
};

export default OriginCollapse;
