import { isEqual } from 'lodash';
import { draw } from 'patternomaly';

const getOrCreateTooltip = ( chart: any ) => {
  let tooltipEl = chart.canvas.parentNode.querySelector( '.tooltip-element' );

  if ( !tooltipEl ) {
    tooltipEl = document.createElement( 'div' );
    tooltipEl.classList.add( 'tooltip-element' );

    const table = document.createElement( 'table' );
    table.style.margin = '0px';

    tooltipEl.appendChild( table );
    chart.canvas.parentNode.appendChild( tooltipEl );

  }

  return tooltipEl;
};

export const externalTooltipHandler = ( context: any ) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip( chart );

  if ( tooltip.opacity === 0 ) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if ( tooltip.body ) {
    const bodyLines = tooltip.body.map( ( b: any ) => b.lines );
    const attributeCount = tooltip.dataPoints[0].raw.attributeCount;
    const benchmarkValue = tooltip.dataPoints[0].raw.benchmarkValue;
    const divergentRatio = tooltip.dataPoints[0].raw.divergentRatio;

    const tableBody = document.createElement( 'tbody' );
    bodyLines.forEach( ( body: any, i: any ) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement( 'canvas' );
      const context = span.getContext( '2d' );

      if ( context != null ) {
        span.height = 10;
        span.width = 10;

        if ( !!colors.borderColor ) {
          context.fillStyle = draw( 'diagonal-right-left', colors.borderColor, 'rgb(255, 255, 255)', 8 );
        } else {
          context.fillStyle = colors.backgroundColor;
        }

        context.fillRect( 0, 0, 10, 10 );
        span.style.marginRight = '10px';
      }

      const tr = document.createElement( 'tr' );
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const td = document.createElement( 'td' );
      td.style.borderWidth = '0';

      const generateText = () => {
        if ( attributeCount === 0 ) {
          return `Index: ${body}%. Comparison value is not present`;
        }

        if ( +benchmarkValue === 0 && body !== 0 ) {
          return `Index: ${body}%. Benchmark value is not present`;
        }

        if ( isEqual( [-2, 2], divergentRatio ) ) {
          return 'Index: 0%';
        }

        return `Index: ${body}%`;
      };

      const text = document.createTextNode( generateText() );

      td.appendChild( span );
      td.appendChild( text );
      tr.appendChild( td );
      tableBody.appendChild( tr );
    } );

    const tableRoot = tooltipEl.querySelector( 'table' );

    while ( tableRoot.firstChild ) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild( tableBody );
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const toooltipLeft = tooltip.caretX + tooltipEl.scrollWidth/2 - 5 > chart.canvas.scrollWidth
    ? chart.canvas.scrollWidth - tooltipEl.scrollWidth + 'px'
    : positionX + tooltip.caretX - tooltipEl.scrollWidth/2 + 'px';

  tooltipEl.style.width = 300;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = toooltipLeft;
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '12px';
};
