import React from 'react';
import './AudienceComparisonEmpty.scss';
import EmptyVenn from './EmptyVenn';
import { Col, Flex, Layout, Row, Spin, message } from 'antd';
import TitleWithCaption from '../../custom/TitleWithCaption/TitleWithCaption';
import { DasboardCard } from './DashboardCard/DashboardCard';
import { options } from '../Blade/collapse/const';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty.svg';
import { ErrorRetryCircle } from '../Error/ErrorRetryCircle';
import { useListAudienceTemplatesQuery } from '../../apollo/graphql-types';

const AudienceComparisonEmpty = () => {
  const { data, loading, error, refetch } = useListAudienceTemplatesQuery( options() );

  const [messageApi, contextHolder] = message.useMessage();

  const errorMessage = ( content: string = 'Something went wrong. Please retry' ) => {
    messageApi.open( {
      type: 'error',
      content,
    } );
  };

  const successMessage = ( content: string ) => {
    messageApi.open( {
      type:    'success',
      content,
    } );
  };

  return (
    <>
      <Layout className='audience-comparison-empty'>
        <TitleWithCaption
          headingLevel={1}
          heading='Audience Comparison'
          caption='Compare any Audience to a Benchmark'
          captionProps={{ className: 'caption' }}
        />
        <EmptyVenn />
      </Layout>

      <Layout className='templates'>
        {contextHolder}
        <div className='title'>
          <TitleWithCaption headingLevel={4} heading='Templates' />
        </div>
        {loading
          ? 	(
            <Flex vertical align='center' justify='center' className='audience-comparison-spinner'>
              <Spin />
            </Flex>
          )
          : error
            ? (
              <ErrorRetryCircle
                title='Error'
                description={'Something went wrong. Couldn\'t load the data. Please try again.'}
                onClick={() => refetch()}
              />
            )
            : <Row gutter={[16, 16]}>
              {
                !data?.listAudienceTemplates?.length
                  ? (
                    <Flex className='empty'>
                      <EmptyIcon />
                      <p className='text'>No Templates Yet</p>
                    </Flex>
                  )
                  : data?.listAudienceTemplates?.map( ( template: any, index: number ) =>
                    <Col span={8} key={`${template.name}-${index}`}>
                      <DasboardCard
                        template={template}
                        successMessage={successMessage}
                        errorMessage={errorMessage}
                      />
                    </Col>
                  )}
            </Row>
        }
      </Layout>
    </>
  );
};

export default AudienceComparisonEmpty;
