import { Space } from 'antd';
import React from 'react';
import TitleWithCaption from '../../../custom/TitleWithCaption/TitleWithCaption';
import { useSelector } from 'react-redux';
import '../../../custom/CustomTag/CustomTag.scss';
import CustomTag from '../../../custom/CustomTag/CustomTag';
import { ICollapseHeaderProps } from '../types';
import { selectTabsActiveTab, selectWizardStepByTabAndStepName } from '../WizardTabs/selectors';

const CollapseHeader: React.FC<ICollapseHeaderProps> = ( { step, heading, caption } ) => {
  const activeTab = useSelector( selectTabsActiveTab );
  const currentStep = useSelector( selectWizardStepByTabAndStepName( activeTab, step ) );
  const isVisible = currentStep.isFinished && !currentStep.isExpand && currentStep.tag?.length !== 0;

  return (
    <>
      <TitleWithCaption heading={heading} caption={caption} captionFontSize={12} />
      <Space className='collapse-tag__wrapper' wrap style={{ maxWidth: 640, display: 'flex', gap: 0 }}>
        {currentStep.tag?.map( ( tag: any ) => (
          <CustomTag isVisible={isVisible} tagName={tag?.name} tagValue={tag?.value} key={tag?.name} />
        ) )}
      </Space>
    </>
  );
};

export default CollapseHeader;
