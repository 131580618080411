import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AudienceComparisonEmpty from '../components/AudienceComparisonEmpty/AudienceComparisonEmpty';
import FilledAudienceComparison from '../components/AudienceComparison/FilledAudienceComparison';
import SharedLayout from './SharedLayout';
import Blade from '../components/Blade/bladeCommon/Blade';
import { setWizardIsOpen } from '../store/wizardSlice';
import { selectIsWizardOpen } from '../components/Blade/WizardTabs/selectors';
import {
  selectAppliedAudiencesToCompare,
  selectAudienceComparisonCount,
  selectAudienceComparisonIsLoading,
  selectHasAudiencesToCompare,
} from '../store/audienceComparison/selectors';
import { setAudiencesToCompare } from '../store/audienceComparison/actions';

const AudienceComparison = () => {
  const [isVisible, setIsVisible] = useState( true );
  const [currentStep, setCurrentStep] = useState( 0 );

  const dispatch = useDispatch();

  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const isWizardOpen = useSelector( selectIsWizardOpen );
  const isAudienceComparisonLoading = useSelector( selectAudienceComparisonIsLoading );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );

  const handleClose = () => {
    dispatch( setWizardIsOpen( false ) );
    if ( hasAudiencesToCompare ) {
      dispatch( setAudiencesToCompare( appliedAudienceToCompare ) );
    }
  };

  const handleNext = () => {
    setCurrentStep( currentStep + 1 );
  };

  const handlePrev = () => {
    setCurrentStep( currentStep - 1 );
  };


  return isVisible
    ? (
      <>
        <SharedLayout />
        {audienceComparisonCount || isAudienceComparisonLoading ? <FilledAudienceComparison /> : <AudienceComparisonEmpty />}
        <Blade
          open={isWizardOpen}
          currentStep={currentStep}
          handlePrev={handlePrev}
          handleNext={handleNext}
          handleClose={handleClose}
        />
      </>
    )
    : (
      <></>
    );
};

export default AudienceComparison;
