import { createSlice } from '@reduxjs/toolkit';
import { AUDIENCE_ID } from '../components/Blade/collapse/const';
import { RootState } from './store';
import { flatten, uniqBy } from 'lodash';
import { D2C } from '../apollo/graphql-types';

export interface ICampaignSliceInterface {
  id: number;
  name: string;
  isEmail?: boolean;
  brandName: string;
}

export interface ICampaignStructure {
  yes?: ICampaignSliceInterface[] | null;
  no?: ICampaignSliceInterface[] | null;
}

export type TCampaignSliceState = Record<AUDIENCE_ID, ICampaignStructure>;

const initialState: TCampaignSliceState = {} as TCampaignSliceState;

export const campaignSlice = createSlice( {
  name:     'campaign',
  initialState,
  reducers: {
    setAllCampaignsList: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      const campaings: D2C = action.payload.campaings;
      state[activeTab] = campaings;
    },
    resetAllMarketingCampaign: ( state ) => {
      state = initialState;

      return state;
    },
    resetMarketingCampaignByAudienceId: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload;
      delete state[activeTab];

      return state;
    },
  },
} );

export const selectMarketingCampaigns = ( audienceId: AUDIENCE_ID ) => ( state: RootState ) =>
  state.campaigns[audienceId]?.no;
export const selectMarketingCampaignsByAudience = ( audienceId: AUDIENCE_ID ) => ( state: RootState ) =>
  state?.campaigns[audienceId];

export const selectFirstPartyDataBrandNames = ( audienceId: AUDIENCE_ID ) => ( state: RootState ) => {
  const d2c = state.campaigns[audienceId]?.yes || [];
  const campaings = state.campaigns[audienceId]?.no || [];

  return uniqBy( flatten( [...d2c, ...campaings] ), 'brandName' ).map( ( campaign ) => campaign.brandName ).join( ', ' );
};

export const selectAllMarketingCampaigns = ( state: RootState ) =>
  state.campaigns;

export const {
  setAllCampaignsList,
  resetAllMarketingCampaign,
  resetMarketingCampaignByAudienceId,
} = campaignSlice.actions;
export default campaignSlice.reducer;
