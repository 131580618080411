import { configureStore } from '@reduxjs/toolkit';
import wizardReducer from './wizardSlice';
import brandReducer from './brandSlice';
import campaignReducer from './campaignSlice';
import tabsReducer from './tabSlice';
import { audienceComparison } from './audienceComparison/audienceComparisonSlice';

export const store = configureStore( {
  reducer: {
    wizard:    wizardReducer,
    brands:    brandReducer,
    campaigns: campaignReducer,
    tabs:      tabsReducer,
    audienceComparison,
  },
} );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
