import React from 'react';
import { IBrandInterface } from '../../../store/brandSlice';
import { audienceClient } from '../../../apollo/apollo';

export const MARS_BRANDS = 'Mars Brands';
export const PREBUILT_AUDIENCES = 'Prebuilt Audiences';
export const COMPETITORS = 'Competitors';

export const options = () => ( {
  client:      audienceClient,
  context: {
    credentials: 'include',
  },
} );

export const BUTTON_BORDER_COLOR = 'rgba(0, 0, 135, 1)';

export const ALL_BUYER_TYPES = 'All Buyer Types';
export const BUYER_TYPE_DESCRIPTION = [
  { name: 'Heavy', description: 'Relevant HH Spend in Top 20%' },
  { name: 'Medium', description: 'Relevant HH Spend in Middle 30%' },
  { name: 'Light', description: 'Relevant HH Spend in Lowest 50%' },
  { name: 'Lapsed', description: 'No purchase in the last 6 months' },
  { name: 'Non-Buyer', description: 'HH purchased within the category but not the selected brands' },
];


export enum AUDIENCE_ID {
  BENCHMARK = 'AudienceA',
  COMPARISON = 'AudienceB',
  SECOND_COMPARISON = 'AudienceC',
}

export const AUDIENCE_TO_AUDIENCE_ID: Record<string, AUDIENCE_ID> = {
  'audienceA': AUDIENCE_ID.BENCHMARK,
  'audienceB': AUDIENCE_ID.COMPARISON,
  'audienceC': AUDIENCE_ID.SECOND_COMPARISON,
};

export const TAB_HEADERS = {
  [AUDIENCE_ID.BENCHMARK]:         'Benchmark Audience',
  [AUDIENCE_ID.COMPARISON]:        'Comparison Audience',
  [AUDIENCE_ID.SECOND_COMPARISON]: '2nd Comparison Audience',
};

export const tooltipContent = ( data: { name: string; description: string }[] ) => (
  <div className='buyer-type-tooltip__d2c'>
    {data.map( ( item ) => (
      <div key={item.name}>
        <span className='buyer-type-name__tooltip'>{item.name}:</span>
        <span className='buyer-type-description__tooltip'>{item.description}</span>
      </div>
    ) )}
  </div>
);

export const stepErrorDescription = <>
  Data for the next step couldn\'t be loaded. Please retry or contact
  <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
 if the issue persists.
</>;

export const getSortedBrandList = ( selectedBrands: number[], availableBrands: any ) => selectedBrands.slice().sort( ( a, b ) => {
  const brandA = availableBrands.find( ( brand: IBrandInterface ) => brand.brandId === a );
  const brandB = availableBrands.find( ( brand: IBrandInterface ) => brand.brandId === b );

  return brandA?.id - brandB?.id;
} );
