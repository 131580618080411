import React, { useState } from 'react';
import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { resetAudienceComparison, setAudienceComparisonIsAborting } from '../../store/audienceComparison/actions';
import { resetWizard } from '../../store/wizardSlice';
import { resetTabs } from '../../store/tabSlice';
import { resetAllMarketingCampaign } from '../../store/campaignSlice';
import { selectAudienceComparisonCount, selectAudienceComparisonIsLoading } from '../../store/audienceComparison/selectors';

export const AudienceComparisonBackButton = () => {
  const dispatch = useDispatch();
  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const isAudienceComparisonLoading = useSelector( selectAudienceComparisonIsLoading );
  const [showModal, setShowModal] = useState( false );
  const { confirm } = Modal;

  const resetState = () => {
    dispatch( resetAudienceComparison() );
    dispatch( resetWizard() );
    dispatch( resetTabs() );
    dispatch( resetAllMarketingCampaign() );
  };

  const handleCancelClick = () => {
    confirm( config ).destroy(); // eslint-disable-line
  };

  const handleOkClick = () => {
    dispatch( setAudienceComparisonIsAborting( true ) );
  };

  const config = {
    title:    'Are you sure you want to go back?',
    icon:     <ExclamationCircleFilled />,
    content:  'Your selection for Audience Comparison Parameters will be lost.',
    centered: true,
    onOk:     handleOkClick,
    onCancel: handleCancelClick,
    open:     showModal,
  };

  const onBackClick = () => {
    if ( isAudienceComparisonLoading ) {
      setShowModal( true );

      confirm( config );

      return;
    }

    resetState();
  };

  if ( !audienceComparisonCount && !isAudienceComparisonLoading ) return null;

  return (
    <Button
      className='icon-back'
      size='large'
      type='text'
      icon={<ArrowLeftOutlined />}
      onClick={onBackClick}
    />
  );
};
